import React, { useState } from 'react';
import { Typography, Stack } from '@mui/material';
import DraggablePopup from '@/components/popups/DraggablePopup';
import { FILTERS_LABELS, allowedExtensions } from '../utils';
import { FileDropzone } from './FileDropzone';
import { DropzoneActions } from './DropzoneActions';
import { FileInfo } from './FileInfo';
import { AutocompleteValue } from '@/components/inputs/AutocompleteValue/AutocompleteValue';
import { useImportStore } from '../store';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';

interface FileUploadModalProps {
  open: boolean;
  onClose: () => void;
}

const FileUploadModal = ({ open, onClose }: FileUploadModalProps) => {
  const { importTypes, uploadFile, curretUser, isUploading } = useImportStore();
  const [selectedImportType, setSelectedImportType] = useState<{ value?: string; label?: string }>(
    {}
  );
  const [file, setFile] = useState<File | null>(null);

  const [error, setError] = useState('');

  console.log(curretUser);

  const handleDrop = (acceptedFiles: File[]) => {
    const uploadedFile = acceptedFiles[0];
    const fileExtension = uploadedFile.name.split('.').pop()?.toLowerCase() ?? '';

    if (!allowedExtensions.includes(`.${fileExtension}`)) {
      setError(`Недопустимое расширение файла: ${fileExtension}`);
      setFile(null);
      return;
    }

    setFile(uploadedFile);
    setError('');
  };

  const handleUpload = async () => {
    if (!file || !selectedImportType.value) return;
    try {
      await uploadFile(file, selectedImportType.value, curretUser || 'unknown');
      handleClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Загрузка прервана из-за ошибки');
    }
  };

  const handleClose = () => {
    onClose();
    setFile(null);
    setError('');
  };
  const handleChange = (newValues: SelectFilterOptType) => setSelectedImportType(newValues);

  return (
    <DraggablePopup
      isOpen={open}
      onClose={onClose}
    >
      <Stack p={1}>
        <Typography
          variant='h6'
          component='h2'
          textAlign={'center'}
          mb={3}
        >
          Загрузка файла
        </Typography>

        <MultiSelect
          label={FILTERS_LABELS.importTypeUpload}
          options={importTypes}
          onChange={handleChange}
          value={selectedImportType}
          withoutBullAnswerReset
          onlyInListArgument
          isOnlySingleValue
          sx={{ minWidth: 200 }}
          disabled={isUploading}
        />

        <FileDropzone
          onDrop={handleDrop}
          error={error}
          isUploading={isUploading}
        >
          <Typography>доступные расширения файла: {allowedExtensions.join(', ')}</Typography>
        </FileDropzone>

        <FileInfo
          file={file}
          error={error}
        />

        <DropzoneActions
          onCancel={handleClose}
          onSave={handleUpload}
          saveDisabled={!file || !Object.keys(selectedImportType).length || isUploading}
        />
      </Stack>
    </DraggablePopup>
  );
};

export default FileUploadModal;
