import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  debounce,
} from '@mui/material';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';

import { Controller, useForm } from 'react-hook-form';
import { ZOOM_FOR_SEARCH } from '../utils/CallingMapUtils';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { useEffect, useState } from 'react';
import {
  getIncomingCallDates,
  getIncomingCallRequestsPerDay,
  getInvaidTaskByPhoneNumberOrTaskId,
  getTaskByAdressCallMap,
  getTaskByIdCallMap,
  getTaskByNumberCallMap,
  getTaskByTaskIdUniqSearch,
  getTaskInvalidTasksByAdressCallMap,
} from '@/services/CallingMapService/CallingMapService';
import { useCallingMapState } from '../store';
import shallow from 'zustand/shallow';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { DataAddressItem, FilterConfig } from '../types/types';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { useIncomingClaim } from './CallingMapIncomingTasksTab/store';
import { getMonthAndYear } from './CallingMapIncomingTasks/utils';
import { useKeyPress } from '@/hooks/usePressKey';

interface LegendProps {
  handelCloseSettings: () => void;
  show: boolean;
  mapAPI: any;
  isTableSearch: boolean;
  isIncommingTaskSearch: boolean;
}
interface FormData {
  adress: string;
  id: string;
  contactPhone: string;
}
interface AddressOption {
  value: string;
  label: string;
  coordinats: number[];
}

const SearchByAdressIdNumber = ({
  handelCloseSettings,
  show,
  mapAPI,
  isTableSearch,
  isIncommingTaskSearch,
}: LegendProps) => {
  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
  const [addressOptionCoordinats, setaddressOptionCoordinats] = useState<number[]>([]);

  const {
    selectedMonth,
    pageSizeIncomingTasks,
    currentPageIncomingTasks,
    setAvailableDates,
    setFilterIncommingTasks,
    setIncomingClaimRows,
    setIncomingClaimRowsLoading,
    setIncomingClaimCount,
  } = useIncomingClaim(
    (state) => ({
      filterIncommingTasks: state.filterIncommingTasks,
      selectedMonth: state.selectedMonth,
      selectedDate: state.selectedDate,
      pageSizeIncomingTasks: state.pageSize,
      currentPageIncomingTasks: state.currentPage,
      setFilterIncommingTasks: state.setFilterIncommingTasks,
      setAvailableDates: state.setAvailableDates,
      setIncomingClaimRows: state.setIncomingClaimRows,
      setIncomingClaimRowsLoading: state.setIncomingClaimRowsLoading,
      setIncomingClaimCount: state.setIncomingClaimCount,
    }),
    shallow
  );
  const {
    setGeoPoints,
    setDefaultValue,
    setGeoLess,
    setGeoLessCount,
    setCurrentPage,
    setPageSize,
    setIsLoadingTable,
    isUniqSearch,
  } = useCallingMapState(
    (state) => ({
      setGeoPoints: state.setGeoPoints,
      setMapZoom: state.setMapZoom,
      setMapCenterCoordinats: state.setMapCenterCoordinats,
      setDefaultValue: state.setDefaultValue,
      setGeoLess: state.setGeoLess,
      setGeoLessCount: state.setGeoLessCount,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
      setIsLoadingTable: state.setIsLoadingTable,
      isUniqSearch: state.isUniqSearch,
    }),
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      adress: '',
      id: '',
      contactPhone: '',
    },
  });

  const { fetchCatch, addActionLog } = useActionLog();

 const onSubmit = async (formData: FormData) => {
    const { id, contactPhone } = formData;
    try {
      if (isIncommingTaskSearch) {
        setIncomingClaimCount('pending');
        setIncomingClaimRowsLoading(true);
        await handleIncommingTasksSearch(formData);
      } else if (isUniqSearch) {
        if (!id) {
          throw new Error('Укажите ID заявки для уникального поиска');
        }
        await handleUniqSearchById(id);
      } else if (id) {
        setIsLoadingTable(true);
        await handleIdSearch(id);
      } else if (contactPhone) {
        setIsLoadingTable(true);
        await handlePhoneSearch(contactPhone);
      } else {
        throw new Error('Введите корректные данные для поиска');
      }
    } catch (error) {
      fetchCatch(error);
    } finally {
      setIsLoadingTable(false);
      setAddressOptions([]);
      reset();
      setIncomingClaimRowsLoading(false);
    }
  };

  const handleIdSearch = async (id: string) => {
    if (!isTableSearch) {
      const { data } = await getTaskByIdCallMap(id);
      if (data.length) {
        setaddressOptionCoordinats([data[0]?.longitudeX, data[0]?.latitudeY]);
        setGeoPoints(data);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      }
    } else {
      const { data } = await getInvaidTaskByPhoneNumberOrTaskId({
        filterItemList: [{ attribute: 'taskId', value: id, filterArg: 0 }],
      });
      if (data?.length) {
        setGeoLess(data);
        setGeoLessCount(data?.length);
        setCurrentPage(0);
        setPageSize(5);
      } else {
        syncErrorCatch('не найдено');
      }
    }
  };

  const handlePhoneSearch = async (phone: string) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    if (!isTableSearch) {
      const { data } = await getTaskByNumberCallMap(cleanedPhone);
      if (data.length) {
        setGeoPoints(data);
        setaddressOptionCoordinats([data[0]?.longitudeX, data[0]?.latitudeY]);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      } else {
        syncErrorCatch('Заявка не найдена');
      }
    } else {
      const { data } = await getInvaidTaskByPhoneNumberOrTaskId({
        filterItemList: [{ attribute: 'phoneNumber', value: cleanedPhone, filterArg: 0 }],
      });
      if (data?.length) {
        setGeoLess(data);
        setGeoLessCount(data?.length);
        setCurrentPage(0);
        setPageSize(100);
      } else {
        syncErrorCatch('не найдено');
      }
    }
  };
  const handleUniqSearchById = async (id: string) => {
    const { data } = await getTaskByTaskIdUniqSearch({
      filterItemList: [{ attribute: 'taskId', value: id, filterArg: 0 }],
    });
    if (data?.length) {
      setGeoLess(data);
      setGeoLessCount(data?.length);
      setCurrentPage(0);
      setPageSize(5);
      addActionLog(ActionLogType.SUCCESS, 'Успешно');
    } else {
      syncErrorCatch('Заявка не найдена');
    }
  };


  const handleIncommingTasksSearch = async (formData: FormData) => {
    const { id, contactPhone, adress } = formData || {};
    const newFilter: FilterConfig = { filterItemList: [] };
    if (id) {
      newFilter.filterItemList.push({ attribute: 'taskId', value: id, filterArg: 0 });
    }
    if (contactPhone) {
      const cleanedPhone = contactPhone.replace(/\D/g, '');
      newFilter.filterItemList.push({
        attribute: 'phoneNumber',
        value: cleanedPhone,
        filterArg: 0,
      });
    }
    if (adress) {
      newFilter.filterItemList.push({ attribute: 'address', value: adress, filterArg: 0 });
    }

    setFilterIncommingTasks(newFilter);
    const { month, year } = getMonthAndYear(selectedMonth);
    const { data } = await getIncomingCallDates(month, year, newFilter);
    setAvailableDates(data);

    const { data: tableDate } = await getIncomingCallRequestsPerDay(
      pageSizeIncomingTasks * currentPageIncomingTasks,
      pageSizeIncomingTasks,
      newFilter // Передаем актуальный фильтр
    );
    setIncomingClaimCount(tableDate.length);
    setIncomingClaimRows(tableDate);
  };

  useEffect(() => {
    if (addressOptionCoordinats.length) {
      mapAPI?.setCenter(addressOptionCoordinats, 17, {
        checkZoomRange: true,
      });
    }
  }, [addressOptionCoordinats]);

  const handleAdressOptions = (term: string) => {
    if (isIncommingTaskSearch) {
      return;
    }
    if (!isTableSearch) {
      getTaskByAdressCallMap(term).then(({ data }) => {
        const uniqueAddresses: { [address: string]: boolean } = {};
        const uniqueData: DataAddressItem[] = [];
        data.forEach((item) => {
          if (!uniqueAddresses[item.address] && item.latitudeY && item.longitudeX) {
            uniqueAddresses[item.address] = true;
            uniqueData.push(item);
          }
        });
        setAddressOptions(
          uniqueData.map((el) => ({
            value: el.id,
            label: el.address,
            coordinats: [el?.longitudeX ?? 0, el?.latitudeY ?? 0],
          }))
        );
      });
    } else {
      if (term) {
        setIsLoadingTable(true);
        getTaskInvalidTasksByAdressCallMap(term)
          .then(({ data }) => {
            setGeoLess(data);
            setGeoLessCount(data?.length);
            setCurrentPage(0);
            setPageSize(100);
          })
          .catch((error) => fetchCatch(error, 'непридвиденая ошибка'))
          .finally(() => setIsLoadingTable(false));
      }
    }
  };

  const getAdressDebounce = debounce(handleAdressOptions, 1000);

  return (
    <Stack style={{ display: show ? 'block' : 'none' }}>
      <Divider />
      <Stack>
        <CloseFullscreenIcon
          onClick={handelCloseSettings}
          style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
        />
      </Stack>
      <Stack p={1}>
        <Grid
          container
          xs={12}
          spacing={3}
        >
          <Grid
            item
            xs={6}
          >
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                  fullWidth
                  placeholder='ID заявки'
                  label={'Поиск по ID заявки'}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                  variant='standard'
                  onFocus={() => reset({ adress: '', contactPhone: '', id: value })}
                />
              )}
              name='id'
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Controller
              control={control}
              rules={{
                // eslint-disable-next-line no-useless-escape
                pattern: /^((8|\+7)[\- ]?)(\(?\d{3}\)?[\- ]?)(\d{3}[\- ]?)(\d{2}[\- ]?)(\d{2})$/,
              }}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                  placeholder='+79998887766'
                  label={'Поиск по номеру'}
                  disabled={isUniqSearch}
                  onBlur={onBlur}
                  onChange={(e) => onChange(formatPhoneNumberMask(e.target.value))}
                  value={value}
                  fullWidth
                  variant='standard'
                  error={errors?.contactPhone?.type === 'pattern'}
                  onFocus={() => reset({ adress: '', id: '', contactPhone: value })}
                />
              )}
              name='contactPhone'
            />
            {errors.contactPhone && (
              <Typography sx={{ width: '100%' }}>Введите номер в формате +79998887766</Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          mt={3}
        >
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Autocomplete
                options={addressOptions}
                fullWidth
                value={value}
                freeSolo
                inputValue={value}
                disabled={isUniqSearch}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                onInputChange={(event, newInputValue) => {
                  getAdressDebounce(newInputValue);
                  onChange(newInputValue);
                  const coordinats = addressOptions.find(
                    (el) => el.label === newInputValue
                  )?.coordinats;
                  if (coordinats?.length) {
                    mapAPI?.setCenter(coordinats, ZOOM_FOR_SEARCH, {
                      checkZoomRange: true,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    onFocus={() => reset({ id: '', contactPhone: '' })}
                    {...params}
                    label='Адресс заявки'
                    variant='standard'
                  />
                )}
              />
            )}
            name='adress'
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={3}
          justifyContent={'end'}
          display={'flex'}
        >
          <FilterButtons
            onSearchClick={() => handleSubmit(onSubmit)()}
            onCloseClick={() => {
              setAddressOptions([]);
              reset();
              setDefaultValue();
              setFilterIncommingTasks({ filterItemList: [] });
            }}
          />
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SearchByAdressIdNumber;
