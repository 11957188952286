import { mediaBreakpointDown } from '@/styles/mixins';
import { Stack, styled, Grid } from '@mui/material';

export const FilterConteinerMain = styled(Grid)({
  flexDirection: 'row',
  alignItems: 'center',
  [mediaBreakpointDown('md')]: {
    flexDirection: 'column',
    marginTop: 5,
  },
});
