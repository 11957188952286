import { FilterArgEnum, SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { useImportStore } from './store';
import { IFilterCombo, useStoredFilters } from '@/components/button/SaveButton/store';
import shallow from 'zustand/shallow';

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Stack, Tooltip, Typography, IconButton, Button } from '@mui/material';
import moment from 'moment';
import { color } from '@/styles/mixins';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CachedIcon from '@mui/icons-material/Cached';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  ImportFile,
  ImportStatus,
  ImportStatusLine,
} from '@/services/ImportService/ImportServiceDto';

export const FILTERS_LABELS = {
  importType: 'Тип импорта',
  importTypeUpload: 'Тип импорта',
};

export const MULTIPLE_ARGS = [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST];

export const IMPORT_SAVE_FILTER_KEY = 'ImportBrowse';

export const allowedExtensions = ['.xls', '.xlsx', '.xlsm', '.xlsb', '.xlt'];

export const useImportTableColumnsMain = () => {
  const importTypesCatalog = useImportStore((state) => state.importTypesCatalog);

  const columnsTableAll: GridColDef[] = [
    {
      field: 'importType',
      headerName: 'Тип импорта',
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>) => {
        return params?.value ? importTypesCatalog[params.value] : '-';
      },
    },
    {
      field: 'loadedBy',
      headerName: 'Загружено кем',
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.value ? params.value : '-';
      },
    },
    {
      field: 'loadDate',
      headerName: 'Дата/Время',
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.value ? moment(params.value).format('DD.MM.YYYY') : '-';
      },
    },
    {
      field: 'status',
      headerName: 'Статус Загрузки',
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridRenderCellParams<string>) => {
        const isFaild = params.row.status === ImportStatus.FAILED;
        return params?.value ? (
          <span style={{ color: isFaild ? color('muiError') : color('trueBlack') }}>
            {statusNames[params.value]}
          </span>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'srings',
      headerName: 'Загруженые Строки',
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      renderCell: (params: GridRenderCellParams<ImportFile>) => {
        const isFaild = params.row.status === ImportStatus.FAILED;
        const validLines = params.row.validLines;
        const totalLines = params.row.totalLines;
        return (
          <Stack flexDirection={'row'}>
            <Typography color={isFaild ? color('muiError') : color('trueBlack')}>
              {validLines}
            </Typography>
            /<Typography>{totalLines}</Typography>
          </Stack>
        );
      },
    },
  ];
  return columnsTableAll;
};

export const useImportTableColumns = (
  editingRowId: string | null,
  handleDeleteRow: (id: string) => void,
  // handleSaveRow: (updatedRow: any) => void,
  fetchedData: any[]
) => {
  const dynamicColumns: GridColDef[] = Object.keys(fetchedData[0] || {})
    .filter((key) => !excludeKeys.includes(key))
    .map((key) => ({
      field: key,
      headerName: key.replace(/([A-Z])/g, ' $1').toUpperCase(),
      sortable: false,
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      editable: true,
      renderCell: (params: GridRenderCellParams<string>) => {
        return params.value ? params.value : '-';
      },
    }));

  const staticColumns: GridColDef[] = [
    {
      field: 'deleteAction',
      headerName: '',
      sortable: false,
      width: 50,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      editable: false,
      renderCell: (params) =>
        params.row.status === ImportStatusLine.INVALID ||
        params.row.status === ImportStatusLine.CONFLICT ? (
          <Tooltip title={'удалить'}>
            <IconButton
              sx={{ color: color('grayNav') }}
              onClick={() => handleDeleteRow(params.row.id)}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        ) : null,
    },
    {
      field: 'status',
      headerName: 'Статус',
      sortable: false,
      flex: 1,
      minWidth: 100,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      editable: false,
      renderCell: (params: GridRenderCellParams<string>) => {
        const status = (params?.value as keyof typeof statusNamesLines) ?? 'DEFAULT';
        return params?.value ? (
          <Tooltip title={statusNamesLines[status]}>
            <span>{statusIcons[status]}</span>
          </Tooltip>
        ) : null;
      },
    },
  ];

  const statusAndMessageSaveBtnColumns: GridColDef[] = [
    {
      field: 'validationMessage',
      headerName: 'Сообщение',
      sortable: false,
      flex: 1,
      minWidth: 350,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      editable: false,
    },
    {
      field: 'saveAction',
      headerName: '',
      sortable: false,
      width: 150,
      headerAlign: 'center',
      disableColumnMenu: true,
      align: 'center',
      editable: false,
      renderCell: (params) =>
        params.row.status === ImportStatusLine.CONFLICT ? (
          <Tooltip
            title={
              'При нажатии кнопки «ЗАМЕНИТЬ» будут импортированы новые значения указаных полей'
            }
          >
            <Button>Заменить</Button>
          </Tooltip>
        ) : null,
    },
  ];

  return [...staticColumns, ...dynamicColumns, ...statusAndMessageSaveBtnColumns];
};

export const statusNames: Record<string, string> = {
  IN_PROGRESS: 'В процессе',
  FAILED: 'Не успешно',
  COMPLETE: 'Успешно',
  DEFAULT: 'Неизвестно',
  RESTARTED: 'Переустоновка',
};
export const statusNamesLines: Record<string, string> = {
  IN_PROGRESS: 'В процессе',
  INVALID: 'Не успешно',
  VALID: 'Успешно',
  DEFAULT: 'Неизвестно',
  CONFLICT: 'конфлик',
};
export const statusIcons: Record<string, JSX.Element> = {
  IN_PROGRESS: <CachedIcon htmlColor={color('grayNav')} />,
  INVALID: <CloseIcon htmlColor={color('muiError')} />,
  VALID: <CheckIcon htmlColor={color('muiSuccess')} />,
  CONFLICT: <WarningAmberIcon htmlColor={color('yellow')} />,
  DEFAULT: <QuestionMarkIcon />,
};

const excludeKeys = ['id', '_id', 'status', 'validationMessage', 'sentToKafka', 'parentId'];
