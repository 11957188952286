import { Button, Stack, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { WarrningWrapper } from './WarrningMessage.styled';

interface WarrningMessageProps {
  warrnigString: string | null;
  handleNo: () => void;
}
const WarrningMessage = ({ warrnigString, handleNo }: WarrningMessageProps) => {
  return (
    <WarrningWrapper>
      <ErrorOutlineIcon />
      <Typography>{warrnigString?.toUpperCase()}</Typography>
      <Stack direction={'row'}>
        <Button>да</Button>
        <Button onClick={handleNo}>нет</Button>
      </Stack>
    </WarrningWrapper>
  );
};

export default WarrningMessage;
