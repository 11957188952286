import { Typography } from '@mui/material';

interface FileInfoProps {
  file: File | null;
  error: string;
}

export const FileInfo = ({ file, error }: FileInfoProps) => (
  <>
    {error && (
      <Typography
        color='error'
        sx={{ mt: 1 }}
      >
        {error}
      </Typography>
    )}
    {file && !error && <Typography sx={{ mt: 1 }}>Выбранный файл: {file.name}</Typography>}
  </>
);
