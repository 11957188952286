import { color } from '@/styles/mixins';
import { Stack, styled } from '@mui/material';

export const WarrningWrapper = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  display: 'inline-flex',
  gap: 2,
  border: 1,
  borderStyle: 'solid',
  borderColor: color('gray'),
  padding: 5,
  borderRadius: 5,
  width: 'fit-content',
  marginBottom: 10,
});
