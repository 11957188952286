import { TaskCCAbonent } from '@/dto/taskmap/Dto';
import { ApiResponse } from '@/types/api';
import { SubscriberParamResponse, SubscriberResponse } from './dto/SubscribersServiceResponseDto';
import { SubscriberParam } from '@/services/SubscribersService/dto/SubscribersServiceRequestDto';
import { YodaRestClient } from '../YodaRestService/YodaRestService';

export async function editSub(
  id = '',
  data: SubscriberParam | TaskCCAbonent[]
): ApiResponse<SubscriberParamResponse> {
  const url = `/rest/abonent${id ? `/${id}` : ''}`;
  return YodaRestClient.put(url, data);
}

export async function addSub(data: SubscriberParam): ApiResponse<SubscriberResponse[]> {
  const url = `/rest/abonent`;
  return YodaRestClient.post(url, data);
}

export async function getSub(id: string): ApiResponse<SubscriberResponse> {
  const url = `/rest/abonent/${id}`;
  return YodaRestClient.get(url);
}

export async function getSubList(taskId: string): ApiResponse<SubscriberResponse[]> {
  const url = `/rest/abonent/task/${taskId}`;
  return YodaRestClient.get(url);
}

export async function deleteSub(id: string): ApiResponse<any> {
  const url = `/rest/abonent/${id}`;
  return YodaRestClient.delete(url);
}
export async function changeAbonentActiveStatus(body: any): ApiResponse<any> {
  const url = `/rest/abonent/active`;
  return YodaRestClient.patch(url, body);
}
