import React from 'react';
import { AccessEnum } from '@/app/auth/roles';
import { useAccess } from '@/hooks/useAccess';

import { useMetaTitle } from '@/hooks/useMetaTitle';
import ImportMainScreen from '@/components/features/import/ImportMainScreen/ImportMainScreen';

const ImportPage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.ImportPage],
  });
  useMetaTitle('Импорт');
  return <>{hasAccess && <ImportMainScreen />}</>;
};

export default ImportPage;
