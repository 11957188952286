import { Stack } from '@mui/material';
import ImportBrowseFilter from '../ImportBrowseFilter/ImportBrowseFilter';
import AddFileButton from '../ImportUploader/AddFileButton';
import { ImportTableAll } from '../ImportTableAll/ImportTableAll';
import { useImportStore } from '../store';
import { useEffect } from 'react';

const ImportMainScreen = () => {
  const { getCurrentUser } = useImportStore();

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <Stack p={2}>
      <ImportBrowseFilter />
      <AddFileButton />
      <ImportTableAll />
    </Stack>
  );
};

export default ImportMainScreen;
