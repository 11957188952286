import { ApiResponse } from '@/types/api';
import { YodaRestClient } from './YodaRestService/YodaRestService';

export async function downloadVRU(
  reportTemplate: string,
  vruInputId: string,
  meterChangeId?: string | null
): ApiResponse<any> {
  const url = `/rest/report/adpu-vru`;
  return YodaRestClient.post(
    url,
    { vruInputId, reportTemplate, meterChangeId },
    { responseType: 'blob' }
  );
}

export async function downloadPresent(
  reportTemplate: string,
  presentEqInfoId: string
): ApiResponse<any> {
  const url = `/rest/report/adpu-present`;
  return YodaRestClient.post(url, { presentEqInfoId, reportTemplate }, { responseType: 'blob' });
}

export async function download(reportTemplate: string, objectId: string): ApiResponse<any> {
  const url = `/rest/report/adpu`;
  return YodaRestClient.post(url, { objectId, reportTemplate }, { responseType: 'blob' });
}

export async function downloadTKO(reportTemplate: string, objectId: string): ApiResponse<any> {
  const url = `/rest/report/act-tko`;
  return YodaRestClient.post(url, { objectId, reportTemplate }, { responseType: 'blob' });
}

export async function getAdpuArray(): ApiResponse<string[]> {
  const url = `/rest/report/adpu/types`;
  return YodaRestClient.get(url);
}

export async function getAdpuVruArray(): ApiResponse<string[]> {
  const url = `/rest/report/adpu-vru/types`;
  return YodaRestClient.get(url);
}

export async function getAdpuPresentArray(): ApiResponse<string[]> {
  const url = `/rest/report/adpu-present/types`;
  return YodaRestClient.get(url);
}

export async function getTKOArray(): ApiResponse<string[]> {
  const url = `/rest/report/templates?code=tkoActs`;
  return YodaRestClient.get(url);
}
