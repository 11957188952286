import { color } from '@/styles/mixins';
import {
  CALL_TYPES,
  ExecuterSchedule,
  ExecuterType,
  Filter,
  IgeoPointCallingMap,
  ObjectOfFilterParams,
} from '../types/types';
import moment from 'moment';
import { CellWithTooltip } from '@/components/features/tasksBrowse/constants';
import { geojsonToWKT } from '@terraformer/wkt';
import { syncErrorCatch } from '@/hooks/ActionLogHook';
import { useCallingMapState } from '@/components/maps/callingMap/store';
import { findAllInstallationCallMap } from '@/services/CallingMapService/CallingMapService';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const turf = window?.turf;
export const ONE_LOAD_POINTS_LIMIT = 20; // лимит для одного полигона
export const POLIGON_ROWS = 4;
export const POLIGON_COLS = 4;
export const POINTS_LIMIT = 5000;
export const MAX_ZOOM = 19;
export const MIN_ZOOM = 16;
export const ZOOM_FOR_SEARCH = 17;
export const MOSCOW_CENTER_COORDINATES = [37.57, 55.75];

export function formatPhoneNumber(phoneNumber: string | null | undefined, showNumber = false) {
  // Проверка наличия 11 цифр в номере телефона
  if (!phoneNumber) return 'Нет номера телефона';
  if (phoneNumber.length !== 11 || !/^\d+$/.test(phoneNumber)) {
    // return 'Некорректный номер телефона';
    return phoneNumber;
  }

  // Показывать только последние четыре цифры после семерки
  if (showNumber) {
    // Полный формат номера
    const formattedNumber = `+7 ${phoneNumber.substring(1, 4)} ${phoneNumber.substring(
      4,
      7
    )} ${phoneNumber.substring(7, 9)} ${phoneNumber.substring(9)}`;
    return formattedNumber;
  } else {
    // Формат только последних четырех цифр
    const formattedNumber = `+7 ${phoneNumber.substring(1, 4)} XXX ${phoneNumber.substring(
      7,
      9
    )} ${phoneNumber.substring(9)}`;
    return formattedNumber;
  }
}

export const checkName = (user: ExecuterType | null, сrew = ''): string => {
  const firstName = user?.firstName ?? '';
  const middleName = user?.middleName ?? '';
  const lastName = user?.lastName ?? '';
  const userId = user?.userId ?? 'нет id';
  const сrewText = сrew ?? '';
  // сheck if all three fields are empty
  if (!firstName && !middleName && !lastName) {
    return userId;
  }
  return `${lastName} ${firstName} ${middleName} ${сrewText}`.trim();
};

export const renderColor = (taskType: string | null) => {
  if (taskType === 'AGREEMENT') return color('agreeToChange');
  if (taskType === 'ADD_NUMBER') return color('newCall');
  if (taskType === 'REFUSAL') return color('nothingTodoWithTheAddress');
  if (taskType === 'CALLBACK') return color('callBackCall');
  if (taskType === 'WRONG_ADDRESS') return color('refuseToChange');
  if (taskType === 'MISSED_CALL') return color('missedCall');
  return color('newCall');
};
export const renderBorderColor = (callType: string) => {
  if (callType === 'REFUSAL') return color('trueBlack');
  if (callType === 'MISSED_CALL') return color('missedCall');
  if (callType === 'CALLBACK') return color('callBackCall');
  if (callType === 'AGREEMENT') return color('agreeToChange');
  if (callType === 'WRONG_ADDRESS') return color('refuseToChange');
  if (callType === 'ADD_NUMBER') return color('addNumber');
  return color('newCall');
};

export function isNumberInRange(number: number, serchByIdOrNumber = false) {
  if (serchByIdOrNumber) {
    return true;
  }
  return number >= MIN_ZOOM && number <= MAX_ZOOM;
}

export function convertToCamelCase(str: string) {
  const words = str.split('_');
  const camelCaseWords = words.map((word: string, index) => {
    if (index === 0) {
      return word.toLowerCase(); // Первое слово в нижнем регистре
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  const camelCaseStr = camelCaseWords.join('');

  return camelCaseStr;
}

export const makeFilter = (deboundceBounds: number[][], limit: number | undefined) => {
  const filter: Filter[] = [];
  const {
    callCenterRequestType,
    callPeriod,
    requestPeriod,
    installationCallStatus,
    montagePlaceType,
    phase,
    taskType,
    tkoPeriodDefiner,
    phoneNumber,
    addressTitle,
    termTaskId,
  } = useCallingMapState.getState();
  const objectOfFilterParams = {
    addressTitle,
    phoneNumber,
    callCenterRequestType,
    callPeriod,
    requestPeriod,
    installationCallStatus,
    montagePlaceType,
    phase,
    taskType,
    taskId: termTaskId,
    tkoPeriod: tkoPeriodDefiner,
  } as ObjectOfFilterParams;

  Object.keys(objectOfFilterParams).forEach((key) => {
    const param = objectOfFilterParams[key];
    const value = param?.value;

    // Обработка строковых значений
    if (typeof value === 'string' && value.length) {
      if (key === 'phoneNumber') {
        filter.push({
          attribute: key,
          value: value.replace(/\D/g, ''),
          filterArg: param.argValue,
        });
      } else {
        filter.push({
          attribute: key,
          value: [value],
          filterArg: param.argValue,
        });
      }
    }
    // Обработка массивов значений
    else if (Array.isArray(value) && value.length > 0) {
      // Обработка массивов значений
      filter.push({
        attribute: key,
        value: value.map((data: any) =>
          key === 'callPeriod' || key === 'requestPeriod'
            ? moment(data).format('YYYY-MM-DD')
            : data.value.toString()
        ),
        filterArg: param.argValue,
      });
    }
    // Обработка объектов значений (не массивов)
    else if (value && typeof value === 'object' && 'value' in value) {
      filter.push({
        attribute: key,
        value: key === 'montagePlaceType' ? [value.value.toString()] : value.value?.toString(),
        filterArg: param.argValue,
      });
    }
  });

  return {
    geoBounds: {
      minX: (deboundceBounds.length && deboundceBounds[0][0]) || 10.126152343750006,
      minY: (deboundceBounds.length && deboundceBounds[0][1]) || 49.905538708070694,
      maxX: (deboundceBounds.length && deboundceBounds[1][0]) || 65.01384765625001,
      maxY: (deboundceBounds.length && deboundceBounds[1][1]) || 60.83064616113029,
    },
    limit: limit,
    filterItemList: filter,
  };
};

export const fetchData = async (
  apiCall: any,
  dataSetter: (data: any) => void,
  loadingSetter: (isLoading: boolean) => void,
  data: any,
  catchErrorSetter: (erro: string, obj: any) => void,
  taskId: string | null
) => {
  if (data.length) return;
  loadingSetter(true);
  try {
    const { data } = await apiCall(taskId);
    dataSetter(data);
    if (data.length) loadingSetter(false);
  } catch (error) {
    catchErrorSetter('Ошибка загрузки', {});
  } finally {
    loadingSetter(false);
  }
};

export const compareUsers = (a: ExecuterType, b: ExecuterType) => {
  //  сортируем по полю selected в порядке убывания
  const selectedComparison =
    (b.selected !== undefined ? Number(b.selected) : 0) -
    (a.selected !== undefined ? Number(a.selected) : 0);

  if (selectedComparison !== 0) {
    return selectedComparison;
  }

  //  сортируем по полю lastName в алфавитном порядке
  return (a?.lastName || '').localeCompare(b?.lastName || '');
};

export const handleExecutorSchedule = (
  executorsArray: ExecuterType[],
  taskData: ExecuterSchedule[]
): ExecuterType[] => {
  return executorsArray.map((user) => {
    // Ищем соответствующую запись во втором массиве по userId
    const taskCount = taskData.find((task) => task.executorId === user.userId);
    // Если такая запись найдена, возвращаем объект с обновленными данными
    if (taskCount) {
      return {
        ...user,
        workloadDays: taskCount.workloadDays,
      };
    } else {
      // Если запись не найдена, возвращаем исходный объект без изменений
      return { ...user, workloadDays: undefined };
    }
  });
};

export function divideBounds(bounds: number[][]) {
  if (bounds.length) {
    const latStep = (bounds[1][1] - bounds[0][1]) / POLIGON_ROWS;
    const lngStep = (bounds[1][0] - bounds[0][0]) / POLIGON_COLS;

    const result = [];
    for (let i = 0; i < POLIGON_ROWS; i++) {
      for (let j = 0; j < POLIGON_COLS; j++) {
        const topLeft = [bounds[0][0] + j * lngStep, bounds[0][1] + i * latStep];
        const bottomRight = [bounds[0][0] + (j + 1) * lngStep, bounds[0][1] + (i + 1) * latStep];
        result.push([
          topLeft,
          [bottomRight[0], topLeft[1]],
          bottomRight,
          [topLeft[0], bottomRight[1]],
          topLeft,
        ]);
      }
    }
    return result;
  }
  return [];
}

export const isPointInBounds = (point: IgeoPointCallingMap, bounds: number[][]) => {
  const [longitude, latitude] = [point.longitudeX, point.latitudeY];
  const [southWest, northEast] = bounds;

  const isLongitudeInRange = longitude >= southWest[0] && longitude <= northEast[0];
  const isLatitudeInRange = latitude >= southWest[1] && latitude <= northEast[1];
  return isLongitudeInRange && isLatitudeInRange;
};

export function createGeoJSONRectangle(pointA: any, pointC: any) {
  // Extract coordinates of the corner points
  // const [latA, lonA] = pointA;
  // const [latC, lonC] = pointC;

  // Extract coordinates of the corner points
  const [lonA, latA] = pointA;
  const [lonC, latC] = pointC;

  // Calculate other corners
  // const pointB = [lonC, latA]; // Top left corner
  // const pointD = [lonA, latC]; // Bottom right corner

  // Create the GeoJSON object
  const geoJSON = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [lonA, latA], // Point A (top right corner)
          [lonC, latA], // Point B (top left corner)
          [lonC, latC], // Point C (bottom left corner)
          [lonA, latC], // Point D (bottom right corner)
          [lonA, latA], // Close the loop by returning to Point A
        ],
      ],
    },
    properties: {},
  };

  return geoJSON;
}

export function splitGeoJSONByGrid(geoJSONPolygon: any, cellSide: number) {
  // Import Turf.js library

  // Calculate the bounding box of the polygon
  const boundingBox = turf.bbox(geoJSONPolygon);
  let size = 200;
  if (cellSide <= 16) {
    size = 2;
  }
  if (cellSide <= 12) {
    size = 5;
  }
  if (cellSide <= 10) {
    size = 10;
  }
  if (cellSide <= 8) {
    size = 50;
  }
  if (cellSide <= 7) {
    size = 100;
  }
  if (cellSide <= 5) {
    size = 300;
  }
  const count = 3;
  const width = boundingBox[2] - boundingBox[0];
  const height = boundingBox[3] - boundingBox[1];
  const cellWidth = width / count;
  const cellHeight = height / count;

  // Create a grid within the bounding box
  const grid = turf.squareGrid(boundingBox, size, {
    // units: 'degrees', // Adjust units as needed
    units: 'kilometers', // Adjust units as needed
  });

  // Array to hold the split polygons
  const splitPolygons: any = [];

  // Intersect the original polygon with each grid cell
  grid.features.forEach((gridCell: any) => {
    // Intersect the polygon with the grid cell
    const clipped = turf.intersect(geoJSONPolygon, gridCell);
    if (clipped) {
      splitPolygons.push(clipped);
    }
  });

  // Return the array of split polygons
  return splitPolygons;
}

export function addNewGeoPonits(
  oldPoints: IgeoPointCallingMap[],
  newPoints: IgeoPointCallingMap[],
  zoomState: string
) {
  if (zoomState === 'SAME' || zoomState === 'IN') {
    // const result = [];
    newPoints.forEach((newObj) => {
      // Проверяем, есть ли объект с таким же id в oldArray
      const existingObj = oldPoints.find((oldObj) => oldObj.taskId === newObj.taskId);
      // Если объекта с таким id нет в oldArray, добавляем его
      if (!existingObj) {
        oldPoints.push(newObj);
      }
    });
    return oldPoints;
  } else {
    return newPoints;
  }
}

export const applyFilterAndFetchData = ({ bounds, accuracy, setIsInProgress, polygon }: any) => {
  const formatedFilter = makeFilter(bounds, accuracy);
  setIsInProgress(true);
  return findAllInstallationCallMap({ formatedFilter, polygon });
};

export const handleCombinedData = (
  combinedData: IgeoPointCallingMap[],
  setGeoPoints: (data: IgeoPointCallingMap[]) => void,
  existingGeoPoints: IgeoPointCallingMap[],
  zoomState: string
) => {
  if (existingGeoPoints?.length >= POINTS_LIMIT) {
    syncErrorCatch('Превышен лимит точек на карте! Кэш точек очищен');
    setGeoPoints(combinedData);
  } else {
    const combinExistingGeoPointsAndNewPoints = addNewGeoPonits(
      existingGeoPoints,
      combinedData,
      zoomState
    );
    setGeoPoints(combinExistingGeoPointsAndNewPoints);
  }
};

export const countMapKey = {
  amCount: 'Первая половина дня',
  pmCount: 'Вторая половина дня',
  anyCount: 'В любое время',
};

export const statusText: Record<string, string> = {
  AGREEMENT: 'Согласие',
  NO_RESULT: 'Нет результата',
  WRONG_ADDRESS: 'Неверный адресс',
  CALLBACK: 'Перезвонить',
  MISSED_CALL: 'Недозвон',
  REFUSAL: 'Отказ',
  ADD_NUMBER: 'Добавлен номер',
};

export const columns: any = [
  {
    field: 'orderNumber',
    headerName: '№',
    width: 10,
  },
  {
    field: 'taskId',
    headerName: 'ID',
    width: 290,
    renderCell: (params: any) => <CellWithTooltip params={params} />,
  },
  {
    field: 'accountingNumber',
    headerName: 'Лицевой счет',
    width: 150,
    renderCell: (params: any) => <CellWithTooltip params={params} />,
  },
  {
    field: 'addressTitle',
    headerName: 'Адресс',
    width: 450,
    renderCell: (params: any) => <CellWithTooltip params={params} />,
  },
  {
    field: 'installationCallGeneralStatus',
    headerName: 'результат звонка',
    renderCell: (params: any) => <CellWithTooltip params={params} />,

    width: 150,
    valueGetter: (row: any) => {
      return statusText[row.value] ?? 'Новая';
    },
  },
  {
    field: 'outOfBounds',
    headerName: 'Вне зоны покрытия',
    width: 160,

    valueGetter: (row: any) => {
      return row.value ? 'Да' : 'Нет';
    },
  },
];

export function getPointsFromCluster(cb: (ids: string[]) => void): any {
  return (e: any) => {
    const cluster = e.get('target');
    const geoObjects = cluster?.getGeoObjects && cluster?.getGeoObjects();
    const pointsInfo = geoObjects?.map((geoObject: any) => geoObject.properties.get('point'));
    const ids = pointsInfo?.map((g: IgeoPointCallingMap) => g.taskId);
    if (ids?.length) cb(ids);
  };
}

export const makePolygon = (bounds: number[][]) => {
  if (!bounds?.length) return;
  const bottomLeft = turf.point([bounds[0][0], bounds[0][1]]); // Replace lon1 and lat1 with your bottom-left point's coordinates

  // Coordinates of the top-right point
  const topRight = turf.point([bounds[1][0], bounds[1][1]]); // Replace lon2 and lat2 with your top-right point's coordinates

  // Calculate the other two corners
  const bottomRight = turf.point([
    topRight.geometry.coordinates[0],
    bottomLeft.geometry.coordinates[1],
  ]);
  const topLeft = turf.point([
    bottomLeft.geometry.coordinates[0],
    topRight.geometry.coordinates[1],
  ]);

  // Construct the square
  const square = turf.polygon([
    [
      turf.getCoord(bottomLeft),
      turf.getCoord(bottomRight),
      turf.getCoord(topRight),
      turf.getCoord(topLeft),
      turf.getCoord(bottomLeft),
    ],
  ]);
  return geojsonToWKT(square?.geometry);
};

export const TKO_FIELDS = ['executor', 'tkoEnumId', 'commentTKO'];

export enum ErrorTextPopupEnum {
  TKO_REQUIRED = 'Перед сохранением необходимо выбрать ТКО',
  DEFAULT = 'Выберите результат звонка и заполните обязательные поля для того, чтобы сохранитьновые данны',
}
export const TKO_REQUIRED_RESULTS = [
  CALL_TYPES.AGREEMENT,
  CALL_TYPES.REFUSAL,
  CALL_TYPES.WRONG_ADDRESS,
];

export const getUniqueGeoLess = (geoLess: IgeoPointCallingMap[]) => {
  return geoLess
    .filter((el, index, self) => index === self.findIndex((item) => item.taskId === el.taskId))
    .map((el, index) => ({ ...el, orderNumber: index + 1 }));
};
