import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';
import { ApiResponse } from '@/types/api';

const ID_CALLING_MAP_STATISTIC = 'db1b8102-b6a3-f28d-b2c8-54ebd37432a4';

export async function GetCurrentGroup(): ApiResponse<any> {
  const url = '/rest/user/current/group';
  return YodaRestClient.get(url);
}

export async function GetStatisticCallingMapExecutors(contractId: string): ApiResponse<any[]> {
  const url = `/rest/group/${contractId}/child-embedded`;
  return YodaRestClient.get(url);
}

export async function GetStatisticCallingMapOperators(contractId: string): ApiResponse<any[]> {
  const url = `/rest/group/${contractId}/role?role-name=ContractorOperatorRole `;
  return YodaRestClient.get(url);
}

export async function GetStatisticCallingMapResponsible(): ApiResponse<any[]> {
  const url = `/rest/group/${ID_CALLING_MAP_STATISTIC}/child `;
  return YodaRestClient.get(url);
}
