import { SupplyBillFilterParam } from '@/dto/SupplyBillServiceDto';
import { useKeyPress } from '@/hooks/usePressKey';
import React from 'react';
import { QueryField, processFilterParams, processQueryFields } from '../SupplyBillBrowse/utils';
import {
  SUPPLY_STATISTIC_FILTER_KEY,
  defaultArgInput,
  storeHouseStatisticData,
  useSupplyStatisticFormOptions,
} from './utils';
import { BoxShadow } from '@/components/ui/BoxShadow';
import DynamicInput from '@/components/inputs/DynamicFilter';
import { IQureFilter } from '@/components/statistics/StatisticWithDateForRP/types';
import { DataTypeConvert } from '@/components/inputs/DynamicFilter/utils';
import usePressEnterButton from '@/hooks/usePressEnterButton';

export interface SupplyStatisticFormProps {
  updateData: (filt?: SupplyBillFilterParam, withReset?: boolean) => void;
  filterParamsToSend: IQureFilter[];
  setFilterParamsToSend: (result: IQureFilter[]) => void;
}

const SupplyStatisticForm = ({
  updateData,
  filterParamsToSend,
  setFilterParamsToSend,
}: SupplyStatisticFormProps) => {
  const {
    billTypesOptions,
    storehousesOptions,
    puTypesOptions,
    osTypesOptions,
    responsablesOptions,
  } = useSupplyStatisticFormOptions();

  const getTaskBrowseCatalogList = (key: string): { label: string; value: string }[] => {
    switch (key) {
      case 'contractorIdList':
        return responsablesOptions;
      case 'supplyIdList':
        return storehousesOptions;
      case 'networkElementTypeIdList':
        return osTypesOptions ?? [];
      case 'puTypeIdList':
        return puTypesOptions ?? [];
      case 'billTypeList':
        return billTypesOptions ?? [];
      default:
        return [];
    }
  };

  const callBackFn = (
    queryFields: Record<string, QueryField>,
    filterSetting: DataTypeConvert[]
  ) => {
    const result = processQueryFields(queryFields, filterSetting);
    setFilterParamsToSend(result);
  };

  const onSubmit = () => {
    const filterValues = processFilterParams(filterParamsToSend);
    updateData(filterValues, true);
  };
  useKeyPress('Enter', onSubmit);

  return (
    <BoxShadow>
      <DynamicInput
        defaultArgInput={defaultArgInput}
        data={storeHouseStatisticData}
        callBackFn={callBackFn}
        saveFilterKey={SUPPLY_STATISTIC_FILTER_KEY}
        searchFn={onSubmit}
        getCatalogList={getTaskBrowseCatalogList}
        disabledSearch={true}
      />
    </BoxShadow>
  );
};

export default SupplyStatisticForm;
