import { Autocomplete, Grid, IconButton, LinearProgress, Modal, TextField } from '@mui/material';
import React, { useEffect, useState, memo } from 'react';
import {
  SupplyBillBrowseDto,
  SupplyNetworkElement,
  SupplyNetworkElementItem,
} from '@/dto/taskmap/Dto';
import { SelectFilterOptType } from '../../filter/MultySelectFilter';
import useSupplyStore, { initialOsList } from '../SupplyStore';
import AddIcon from '@mui/icons-material/Add';
import { SupplyConstant } from '../SupplyConstant';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { SupplyBillComponentProps } from '../StampComponent/StampComponent';
import { useAllNetworkElementTypes } from '@/hooks/useQuery/useAllNetworkElementTypes';
import {
  useStorehouseLS,
  useStorehouseWorkerAccess,
  getSavePuListId,
  osDupErrorMap,
  convertLSErrorObjectToMap,
  useMeterTypes,
  toObject,
  generateNextId,
  TextFieldChangeEvent,
  replaceNonNums,
  updateRowNums,
  convertIntoFilter,
  OSExistErrorMap,
  SupplyPUItemConfirmState,
} from '../SupplyPUComponent/utils';

import {
  generateScanId,
  generateRowNum,
  isNumberExist,
  generateOsExcelFile,
  checkOsErrors,
} from './utils';
import { playAudioByKey } from '@/utils/heplers';
import { SupplyBillApiCheckNetworkElementExists } from '@/services/SupplyBillService/SupplyBillService';
import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import {
  ButtonStyled,
  IconButtonStyled,
  SupplyButtonsWrapper,
  Wrapper,
} from '@/components/storehouse/SupplyPUComponent/SupplyPUComponent.styled';
import AlertRemove from '../Alert/AlertRemove';
import SupplyClear from '../SupplyClear/SupplyClear';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AlertError from '../Alert/AlertError';
import { SupplyPUModalInner } from '../PURemoveScanner/PURemoveScanner.styled';
import { SupplyBillBrowseLinearProgressWrapper } from '../SupplyBillBrowse/SupplyBillBrowse.styled';
import SupplyOsVirtualizedTable from './SupplyOSVirtualizedTable';
import SupplyOsModalHeader from './SupplyOsModalHeader';
import OSRemoveScanner from '../OSRemoveScanner/OSRemoveScanner';
import shallow from 'zustand/shallow';
import { checkNonConfirmedItems } from '../SupplyButtonPanel/utils';
import {
  useAllPuCompleteness,
  useAllPuOwner,
  useAllEquipmentState,
  useAllResponsables,
  useAllPuDefectCause,
  useAllOsDefectCause,
} from '@/hooks/useQuery/useAllSupplyCatalogs';
import {
  SavePuItem,
  SaveOsItem,
} from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { isReturnBill } from '../utils';

type SupplyOSComponentProps = SupplyBillComponentProps;

export type SelectedOs = SupplyNetworkElement & {
  typeScanField: SupplyNetworkElementScanField;
  doubleNumber?: boolean;
  parentTypeName: string;
};

const SupplyOSComponent = (props: SupplyOSComponentProps) => {
  const {
    osList,
    setOSList,
    supplyOsTypes,
    setSupplyOsTypes,
    billNumber,
    setReturnNewItemsMode,
    m15FormNumber,
    billDate,
    contractorId,
    billType,
  } = useSupplyStore(
    (state) => ({
      osList: state.osList,
      setOSList: state.setOSList,
      supplyOsTypes: state.supplyOsTypes,
      setSupplyOsTypes: state.setSupplyOsTypes,
      billNumber: state.billNumber,
      setReturnNewItemsMode: state.setReturnNewItemsMode,
      m15FormNumber: state.m15FormNumber,
      billDate: state.billDate,
      contractorId: state.contractorId,
      billType: state.billType,
    }),
    shallow
  );

  const returnBill = isReturnBill();
  const relocationGetBill = billType === SupplyConstant.RELOCATION_TYPE_GET;
  const relocationPostBill =
    billType === SupplyConstant.DRAFT_TYPE_RELOCATION_POST ||
    billType === SupplyConstant.RELOCATION_TYPE_POST;

  const billWithConfirm = returnBill || relocationGetBill;
  const { nonEditableBill } = props;

  const { getValue, setValue } = useStorehouseLS();
  const workerAccess = useStorehouseWorkerAccess();

  const { puCompletenessOptions, isFetching: isLoadingCompleteness } =
    useAllPuCompleteness(returnBill);
  const { equipmentStateOptions, isFetching: isLoadingState } = useAllEquipmentState(returnBill);
  const { puOwnerOptions, isFetching: isLoadingOwner } = useAllPuOwner(returnBill);
  const { puDefectOptions, isFetching: isLoadingPuDefects } = useAllPuDefectCause(returnBill);
  const { osDefectOptions, isFetching: isLoadingOsDefects } = useAllOsDefectCause(returnBill);

  const lsKey = getSavePuListId(props.tabType, props?.supplyBillDto);

  const { responsablesOptions } = useAllResponsables();

  const [selectedMeterTypesMap, setSelectedMeterTypesMap] = useState<
    Map<number, SelectFilterOptType | null>
  >(new Map());
  const [osTypes, setOsTypes] = useState<SelectFilterOptType[]>([]);
  const [isScanningMode, setIsScanningMode] = useState<boolean>(false);
  const [osDupFieldError, setOsDupFieldError] = useState<osDupErrorMap>(new Map());
  const [osExistsFieldError, setOsExistsFieldError] = useState<OSExistErrorMap>(new Map());
  const [editedRow, setEditedRow] = useState<SupplyNetworkElementItem>();
  const [selectedOS, setSelectedOS] = useState<SelectedOs>();
  const [selectedOSDeclaredCount, setSelectedOSDeclaredCount] = useState<string>('');
  const [osListItems, setOsListItems] = useState<SupplyNetworkElementItem[]>([]);
  const [rowToRemove, setRowToRemove] = useState<number>();
  const [disabledField, setDisabledField] = useState<boolean>(false);

  const [alertText, setAlertText] = useState<string>('');
  const handleClose = () => setAlertText('');

  const handleRemoveConfirmClose = () => setRowToRemove(undefined);

  const { fetchCatch, addActionLog } = useActionLog();

  const [loadingCheckExist, setLoadingCheckExist] = useState(false);

  const removeScanRow = (rowId: number) => {
    if (!selectedOS) return;
    const maxId = Math.max(...osListItems.map((o) => o.tmpId));
    if ((osListItems.length > 1 && rowId !== maxId) || returnBill) {
      const arr = [...osListItems];
      const scanIndex = arr.indexOf(arr.find((it) => it.tmpId === rowId)!);
      if (scanIndex !== -1) {
        arr.splice(scanIndex, 1);
        setOsListItems(updateRowNums(arr));
        osDupFieldError?.get(selectedOS.tmpId)?.delete(rowId);
        setOsDupFieldError(new Map(osDupFieldError));
        osExistsFieldError?.get(selectedOS.tmpId)?.delete(rowId);
        setOsExistsFieldError(new Map(osExistsFieldError));
        checkDuplicateAndMatchRegex(arr);
      }
    }
  };

  const confirmScanRow = (rowId: number) => {
    if (!selectedOS) return;
    const arr = [...osListItems];
    const scanIndex = arr.indexOf(arr.find((it) => it.tmpId === rowId)!);
    if (scanIndex !== -1) {
      arr[scanIndex].confirmed = SupplyPUItemConfirmState.bothNumbers;
      setOsListItems(arr);
    }
  };

  const confirmAllRows = () => {
    if (!selectedOS) return;
    const arr = osListItems.map((os) => ({
      ...os,
      confirmed: SupplyPUItemConfirmState.bothNumbers,
    }));
    setOsListItems(arr);
  };

  const confirmScanedNumber = (scanedNumber: string) => {
    if (!selectedOS) return;
    const arr = [...osListItems];
    const scanIndex = arr.indexOf(arr.find((it) => scanedNumber === it.imei)!);
    if (scanIndex !== -1) {
      if (arr[scanIndex].confirmed !== SupplyPUItemConfirmState.bothNumbers) {
        arr[scanIndex].confirmed = SupplyPUItemConfirmState.bothNumbers;
      }
    } else {
      if (relocationGetBill) {
        showMessage(`Номер ${scanedNumber} отсутствует в накладной`);
      } else {
        const rowScanId = generateScanId(selectedOS, arr);
        const rowNumId = generateRowNum(selectedOS, arr);
        arr.push({
          tmpId: rowScanId,
          id: null,
          rowNum: rowNumId,
          typeId: selectedOS.typeId ?? arr[scanIndex].typeId,
          typeName: selectedOS.typeName ?? arr[scanIndex].typeName,
          imei: scanedNumber,
          mac: '',
          returnStorehouseAdded: true,
        });
      }
    }
    setOsListItems(arr);
  };

  const removeErroredScanRows = (allIds: number[], removeIds: number[]) => {
    if (!selectedOS) return;
    const arr = [...osListItems];
    const filteredPUs = arr.filter((pu) => !removeIds.includes(pu.tmpId));
    const updatedRowNumsList = updateRowNums(filteredPUs);
    setOsListItems(updatedRowNumsList);
    deleteOsListItemsErrors(allIds);

    checkDuplicateAndMatchRegex(filteredPUs);
  };

  const { networkElementTypesResponse, isLoading } = useAllNetworkElementTypes();
  const showRemoveConfirm = (rowId: number) => setRowToRemove(rowId);

  const removeRow = () => {
    if (rowToRemove) {
      const arr = [...osList];
      const index = arr.indexOf(arr.find((it) => it.tmpId === rowToRemove)!);
      if (index !== -1) {
        arr.splice(index, 1);
        updateOSList(arr);
        setSelectedMeterTypesMap(selectedMeterTypesMap.set(rowToRemove, null));
        osExistsFieldError?.delete(rowToRemove);
        setOsExistsFieldError(new Map(osExistsFieldError));

        osDupFieldError?.delete(rowToRemove);
        setOsDupFieldError(new Map(osDupFieldError));
      }
    }
    setRowToRemove(undefined);
  };

  React.useEffect(() => {
    if (networkElementTypesResponse) {
      networkElementTypesResponse?.map((meterType) => {
        setSupplyOsTypes(new Map(supplyOsTypes.set(meterType?.id, meterType)));
      });
      setOsTypes(convertIntoFilter(networkElementTypesResponse));
      try {
        if (
          props.supplyBillDto?.networkElementList?.length ||
          props.supplyBillDto?.networkElementItemList?.length
        ) {
          initOsList(convertIntoFilter(networkElementTypesResponse));
        } else {
          initLsOsList();
        }
      } catch (err) {
        syncErrorCatch('Ошибка инициализации сетевого оборудования', {
          message: err instanceof Error ? err.message : '',
        });
      }
    }
  }, [networkElementTypesResponse, props.supplyBillDto]);

  const setErrorsFromLS = () => {
    const dupErrorsLS = getValue()?.[lsKey]?.osErrors?.dup;
    if (dupErrorsLS) {
      const dupMap = convertLSErrorObjectToMap<osDupErrorMap>(dupErrorsLS);
      if (dupMap?.size) {
        setOsDupFieldError(dupMap);
      }
    }

    const existErrorsLS = getValue()?.[lsKey]?.osErrors?.exist;
    if (existErrorsLS) {
      const existMap = convertLSErrorObjectToMap<OSExistErrorMap>(existErrorsLS);
      if (existMap?.size) {
        setOsExistsFieldError(existMap);
      }
    }
  };

  useEffect(() => {
    if (editedRow) {
      checkDuplicateAndMatchRegex(osListItems);
    }
  }, [editedRow]);

  useEffect(() => {
    if (!isScanningMode) {
      setReturnNewItemsMode(false);
      return;
    }
    const tmpMap = checkErrorMap(osListItems);
    setOsDupFieldError(new Map(tmpMap));
  }, [isScanningMode]);

  const { selectedMeterTypes } = useMeterTypes(selectedMeterTypesMap, osList, osTypes);

  //   Сохранение временных данных в LocalStorage на случай перезагрузки страницы
  React.useEffect(() => {
    if (!networkElementTypesResponse || nonEditableBill || props.withoutLsSave) return;

    const saveOsListToLS = (newOsList: SupplyNetworkElement[]) => {
      setValue(
        {
          os: newOsList,
          osErrors: {
            dup: toObject(osDupFieldError),
            exist: toObject(osExistsFieldError),
          },
        },
        lsKey
      );
    };

    if (selectedOS) {
      const nonSelectedOs = osList.filter((pu) => selectedOS.tmpId !== pu.tmpId);
      nonSelectedOs.push({
        ...selectedOS,
        networkElementItems: osListItems?.filter((osItem) => !osItem.backendItem),
      });

      saveOsListToLS(nonSelectedOs);
    } else {
      const unSavedOsList = osList.map((os) => {
        if (os.backendItem) {
          return {
            ...os,
            networkElementItems: os?.networkElementItems?.filter((osItem) => !osItem.backendItem),
          };
        } else {
          return os;
        }
      });
      saveOsListToLS(unSavedOsList);
    }
  }, [networkElementTypesResponse, osListItems, osList, osDupFieldError, osExistsFieldError]);

  const initOsList = (meterTypes: SelectFilterOptType[]) => {
    const supplyBill = { ...props.supplyBillDto } as SupplyBillBrowseDto | undefined;
    const osListFromLS = getValue()?.[lsKey]?.os;

    let tmp: SupplyNetworkElement[];

    if (!returnBill) {
      tmp = supplyBill!.networkElementList?.map((elem, index) => {
        const osItemsFromLS =
          osListFromLS
            ?.find((os) => os.typeId === elem.typeId)
            ?.networkElementItems?.filter(
              (it) => (it.mac && it.mac.length > 0) || (it.imei && it.imei.length > 0)
            ) ?? [];
        const mergedCount = (elem.networkElementItems.length + osItemsFromLS.length).toString();

        return {
          ...elem,
          tmpId: index + 1,
          declaredCount: mergedCount,
          networkElementItems: [
            ...elem.networkElementItems.map((el, ind) => ({
              ...el,
              tmpId: ind + 1,
              rowNum: ind + 1,
              typeId: elem.typeId,
              backendItem: true,
            })),
            ...(osItemsFromLS.map((el, index) => ({
              ...el,
              tmpId: elem.networkElementItems.length + index + 1,
            })) ?? []),
          ],
          backendItem: true,
        };
      }) as SupplyNetworkElement[];
    } else {
      tmp = [
        {
          tmpId: 1,
          backendItem: true,
          declaredCount: supplyBill?.networkElementItemList
            ? supplyBill?.networkElementItemList.length.toString()
            : '',
          networkElementItems: supplyBill?.networkElementItemList
            ? supplyBill?.networkElementItemList.map((el, index) => ({
                ...el,
                tmpId: index + 1,
                rowNum: index + 1,
                backendItem: true,
              }))
            : [],
          addedCount: '',
        },
      ];
    }

    const unsavedPusFromLS = osListFromLS
      ?.filter((unsavedOs) => !tmp?.find((backendOs) => backendOs.typeId === unsavedOs.typeId))
      ?.map((unsavedOs, index) => ({
        ...unsavedOs,
        tmpId: tmp.length + index + 1,
      }));

    const getSelectedMeterTypes = (
      values: SupplyNetworkElement[] | undefined,
      fromLocalStorage: boolean
    ): [number, SelectFilterOptType | null][] => {
      return (
        values?.map((os, index) => [
          fromLocalStorage ? generateNextId(tmp) + index : index + 1,
          meterTypes.find((meterType) => meterType.value === os.typeId) ?? null,
        ]) ?? []
      );
    };
    const tmpForMap = getSelectedMeterTypes(supplyBill?.networkElementList, false);
    const tmpForMapFromLS = getSelectedMeterTypes(unsavedPusFromLS, true);
    const mergedMeterTypes = new Map([...tmpForMap, ...tmpForMapFromLS]);

    setSelectedMeterTypesMap(mergedMeterTypes);
    setOSList([...tmp, ...(unsavedPusFromLS ?? [])]);
    setErrorsFromLS();
  };

  const initLsOsList = () => {
    const tmpFromLS =
      getValue()?.[lsKey]?.os?.map((el, index) => ({
        ...el,
        networkElementItems: el.networkElementItems?.filter(
          (os) => (os.mac && os.mac.length > 0) || (os.imei && os.imei.length > 0)
        ),
      })) ?? initialOsList;
    const tmpForMapFromLS: Map<number, SelectFilterOptType | null> = new Map(
      getValue()?.[lsKey]?.os?.map((elem, index) => [
        elem.tmpId,
        convertIntoFilter(networkElementTypesResponse)?.find(
          (meterType: SelectFilterOptType) => meterType.value === elem.typeId
        ) ?? null,
      ]) ?? []
    );

    setErrorsFromLS();
    setSelectedMeterTypesMap(tmpForMapFromLS);
    setOSList(tmpFromLS);
  };

  const deleteOsListItemsErrors = (errorRowIds: number[]) => {
    if (!selectedOS) return;
    errorRowIds.forEach((rowId) => {
      osDupFieldError?.get(selectedOS.tmpId)?.delete(rowId);
      osExistsFieldError?.get(selectedOS.tmpId)?.delete(rowId);
    });

    setOsDupFieldError(new Map(osDupFieldError));
    setOsExistsFieldError(new Map(osExistsFieldError));
  };

  const resetState = () => {
    const allUnsavedItemIds = osListItems.filter((el) => !el.savedItem).map((el) => el.tmpId);
    const allSavedItems = osListItems.filter((el) => el.savedItem);
    deleteOsListItemsErrors(allUnsavedItemIds);
    checkDuplicateAndMatchRegex(allSavedItems);
    setSelectedOS(undefined);
    setOsListItems([]);
    setIsScanningMode(false);
    setDisabledField(false);
    setSelectedOSDeclaredCount('');
  };

  const saveOsItems = () => {
    const addedItemsCount = billWithConfirm ? osListItems.length : osListItems.length - 1;

    if (addedItemsCount > parseInt(selectedOSDeclaredCount)) {
      showMessage('Добавлено больше чем заявлено', true);
    } else if (addedItemsCount < parseInt(selectedOSDeclaredCount)) {
      showMessage('Добавлено меньше чем заявлено', true);
    } else {
      updateOsItems();
      resetState();
    }
  };

  const removeOsByScanning = (itemsToRemove: SupplyNetworkElementItem[], typeId: number) => {
    const osListByMeterType = osList.filter((it) => it.typeId === typeId);
    const supplyMeterType = supplyOsTypes.get(typeId);
    const deletedItems: SupplyNetworkElementItem[] = [];
    if (supplyMeterType) {
      osListByMeterType.forEach((os) => {
        const networkElementItems = [...os.networkElementItems];
        itemsToRemove.forEach((item) => {
          let osItem;

          if (supplyMeterType.typeScanField === SupplyNetworkElementScanField.IMEI && item.imei) {
            osItem = networkElementItems.find((it) => it.imei && it.imei === item.imei);
          } else if (
            supplyMeterType.typeScanField === SupplyNetworkElementScanField.MAC &&
            item.mac
          ) {
            osItem = networkElementItems.find((it) => it.mac && it.mac === item.mac);
          } else if (
            supplyMeterType.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI &&
            item.mac?.length &&
            item.imei?.length
          ) {
            osItem = networkElementItems.find(
              (it) => it.mac && it.mac === item.mac && it.imei && it.imei === item.imei
            );
          }
          if (osItem) {
            const index = networkElementItems.indexOf(osItem);
            if (index !== -1) {
              deletedItems.push(networkElementItems[index]);
              networkElementItems.splice(index, 1);
              os.declaredCount = (
                os.declaredCount ? parseInt(os.declaredCount) - 1 : ''
              ).toString();
            }
          }
        });
        os.networkElementItems = [...updateRowNums(networkElementItems)];
        setOSList([...osList]);
      });
    }
    deletedItems.length
      ? addActionLog(ActionLogType.SUCCESS, 'Данные успешно удалены')
      : addActionLog(ActionLogType.ERROR, 'Сетевое оборудование с введенными данными не найдено');
  };

  const checkDuplicateAndMatchRegex = (listOsItem: SupplyNetworkElementItem[]) => {
    if (!selectedOS) return;
    if (editedRow) {
      const val = editedRow.mac ? editedRow.mac : editedRow.imei;
      if (selectedOS?.typeRegex && val && val?.length > 0) {
        const regExp = new RegExp(selectedOS?.typeRegex);
        if (regExp.test(val)) {
          checkDuplicate(editedRow, listOsItem, osList);
        } else {
          const dupPuList = osDupFieldError.get(selectedOS.tmpId);
          if (dupPuList) {
            dupPuList.set(editedRow.tmpId, true);
            osDupFieldError.set(selectedOS.tmpId, dupPuList);

            setOsDupFieldError(new Map(osDupFieldError));
          } else {
            const newDupMap = new Map();
            newDupMap.set(editedRow.tmpId, true);
            osDupFieldError.set(selectedOS.tmpId, newDupMap);
            setOsDupFieldError(new Map(osDupFieldError));
          }
          showMessage(`Номер ${val} не подходит под формат`);
        }
      } else {
        checkDuplicate(editedRow, listOsItem, osList);
      }
      setEditedRow(undefined);
    } else {
      const tmpMap = checkErrorMap(listOsItem);
      setOsDupFieldError(new Map(tmpMap));
    }
  };

  const checkDuplicate = (
    os: SupplyNetworkElementItem,
    listOsItem: SupplyNetworkElementItem[],
    osList: SupplyNetworkElement[]
  ) => {
    const typeScanField = !returnBill
      ? selectedOS?.typeScanField
      : supplyOsTypes.get(os.typeId)?.typeScanField;
    if (!typeScanField) return;
    if (!selectedOS) return;
    const notSelectedOs = osList.filter((os) => os.tmpId !== selectedOS.tmpId);

    const dupItems = isNumberExist(os, listOsItem, typeScanField, notSelectedOs).map((item) => ({
      ...item,
      osTmpId: item.osTmpId ?? selectedOS.tmpId,
    }));

    if (dupItems.length > 1) {
      dupItems
        .filter((item) => item.osTmpId === selectedOS.tmpId)
        .forEach((it) => {
          const dupPuList = osDupFieldError.get(selectedOS.tmpId);
          if (dupPuList) {
            dupPuList.set(it.tmpId, true);
            osDupFieldError.set(selectedOS.tmpId, dupPuList);
          } else {
            const newDupMap = new Map();
            newDupMap.set(it.tmpId, true);
            osDupFieldError.set(selectedOS.tmpId, newDupMap);
          }
        });
    } else {
      const dupPuList = osDupFieldError.get(selectedOS.tmpId);
      if (dupPuList) {
        dupPuList.set(os.tmpId, false);
        osDupFieldError.set(selectedOS.tmpId, dupPuList);
      }
    }
    const tmpMap = checkErrorMap(listOsItem);
    setOsDupFieldError(new Map(tmpMap));
    if (dupItems.length > 1) {
      showMessage(
        `Дубль! Вы уже отсканировали прибор с номером ${os?.mac}${os?.imei ? `/${os?.imei}` : ''}`
      );
    }
  };

  const checkErrorMap = (itemsList: SupplyNetworkElementItem[]) => {
    const tmpMap = new Map(osDupFieldError) as osDupErrorMap;
    if (!selectedOS) return tmpMap;
    const currentDups = tmpMap.get(selectedOS.tmpId);
    if (!currentDups) return tmpMap;
    currentDups.forEach((value, key) => {
      if (value) {
        const item = itemsList.find((it) => it.tmpId === key);
        if (!item) return;
        const typeScanField = !returnBill
          ? selectedOS?.typeScanField
          : supplyOsTypes.get(item?.typeId)?.typeScanField;
        if (!typeScanField) return;
        if (item) {
          const errDupItems = isNumberExist(item, itemsList, typeScanField, osList);
          currentDups.set(item.tmpId, errDupItems.length > 1);
        }
      }
    });
    tmpMap.set(selectedOS.tmpId, currentDups);
    return tmpMap;
  };

  const showMessage = (text: string, alert?: boolean) => {
    alert ? setAlertText(text) : syncErrorCatch(text);
    playAudioByKey('errorAudio');
  };

  const updateOsItems = (forcedOsListItems?: SupplyNetworkElementItem[]) => {
    if (selectedOS) {
      let resultList = (forcedOsListItems ?? osListItems).filter(
        (it) => (it.mac && it.mac.length > 0) || (it.imei && it.imei.length > 0)
      );
      if (returnBill) {
        resultList = forcedOsListItems ?? osListItems;
      }
      const newPUList = osList.map((obj) => {
        if (obj.tmpId === selectedOS.tmpId) {
          return {
            ...obj,
            networkElementItems: resultList,
            declaredCount: selectedOSDeclaredCount,
          };
        }
        return obj;
      });
      updateOSList(newPUList);
    }
  };

  const onEditCommit = (item: SaveOsItem | SavePuItem) => commitNewRow(item);
  const onEditStart = (item?: SupplyNetworkElementItem) => {
    if (!selectedOS) return;
    if (returnBill) {
      return addNewRow(selectedOS.typeId ?? -1, selectedOS.typeName ?? '');
    } else {
      if (item) return addNewRowIfNeeded(item.tmpId, selectedOS);
    }
  };

  const commitNewRow = (params: SaveOsItem | SavePuItem) => {
    const item = osListItems.find((it) => it.rowNum === params.rowNum)!;
    const typeId =
      selectedOS?.typeId === -1 && 'typeId' in params ? params?.typeId : selectedOS?.typeId;

    const supplyMeterType = supplyOsTypes.get(typeId ?? -1);

    const updateReturnItem = () => {
      if (returnBill) {
        if (params.taskNumber && typeof params.taskNumber === 'object') {
          item.taskNumber = params.taskNumber.taskNumberValue;
        }
        item.manufactureYear = params.manufactureYear;
        item.ownerId = params.ownerId;
        item.state = params.state;
        item.defectCause = params.defectCause;
        item.completeness = params.completeness;
        item.completenessComment = params.completenessComment;
        item.supplyComment = params.supplyComment;
        item.address = params.address;
        item.generalComment = params.generalComment;
        if ('typeName' in params) {
          item.typeName = params.typeName;
        }
        if ('typeId' in params) {
          item.typeId = params.typeId;
        }

        item.fullName = params.fullName;
      }
    };

    if (supplyMeterType && 'imei' in params) {
      const meterTypeScanField = supplyMeterType.typeScanField;
      if (meterTypeScanField === SupplyNetworkElementScanField.MAC) {
        item.mac = params.mac;
      } else if (meterTypeScanField === SupplyNetworkElementScanField.IMEI) {
        item.imei = params.imei;
      } else if (meterTypeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI) {
        item.mac = params.mac;
        item.imei = params.imei;
      }
    } else {
      item.mac = params.mac;
      if ('imei' in params) {
        if (params.imei) {
          item.imei = params.imei;
        }
      }
    }
    returnBill && updateReturnItem();

    setEditedRow(item);
    if (item?.typeId > 0 && ((item?.mac?.length ?? 0) > 0 || (item?.imei?.length ?? 0) > 0)) {
      if (returnBill) {
        updateListItems();
      }
      checkOsItemExists(item);
    }
  };

  const updateListItems = () => {
    checkDuplicateAndMatchRegex(osListItems);
    const arr = [...osListItems];
    setOsListItems(arr);
  };

  const checkOsItemExists = (item: SupplyNetworkElementItem) => {
    setLoadingCheckExist(true);
    SupplyBillApiCheckNetworkElementExists({
      mac: item.mac || null,
      imei: item.imei || null,
      typeId: item.typeId,
      billTypeId: billType,
    })
      .then(({ data }) => {
        if (!selectedOS) return;
        let errMap = new Map();
        if (
          data?.billNumber &&
          data?.billNumber?.length > 0 &&
          billNumber !== data?.billNumber &&
          !relocationPostBill &&
          !relocationGetBill
        ) {
          const osListErrors = new Map();
          osListErrors.set(item.tmpId, { billName: data.billNumber, item: item });
          if (osExistsFieldError?.has(selectedOS.tmpId)) {
            osExistsFieldError
              ?.get(selectedOS.tmpId)
              ?.set(item.tmpId, { billName: data.billNumber, item: item });
            errMap = new Map(osExistsFieldError);
          } else {
            osExistsFieldError.set(selectedOS.tmpId, osListErrors);
            errMap = new Map(osExistsFieldError);
          }

          showMessage(
            `Прибор с номером ${item?.imei}${
              item?.mac ? `/${item?.mac}` : ''
            } уже существует в архиве, заявка ${data.billNumber}`
          );
        } else {
          osExistsFieldError?.get(selectedOS.tmpId)?.delete(item.tmpId);
          errMap = new Map(osExistsFieldError);
        }
        setOsExistsFieldError(errMap);
      })
      .catch((err) => fetchCatch(err, 'Ошибка во время проверки дублей'))
      .finally(() => setLoadingCheckExist(false));
  };

  const addNewRowIfNeeded = (rowId: number, selectedOs?: SupplyNetworkElement) => {
    if (!selectedOs) return;
    if (
      !osListItems.find(
        (it) =>
          (!it.mac || it.mac.length == 0) && (!it.imei || it.imei.length == 0) && rowId !== it.tmpId
      )
    ) {
      addNewRow(selectedOs.typeId ?? -1, selectedOs.typeName ?? '');
    }
  };

  const addNewRow = (typeId: number, typeName: string) => {
    const rowScanId = generateScanId(selectedOS!, osListItems);
    const rowNumId = generateRowNum(selectedOS!, osListItems);
    const newOsList = osListItems.concat({
      tmpId: rowScanId,
      id: null,
      rowNum: rowNumId,
      typeId: typeId,
      typeName: typeName,
      imei: '',
      mac: '',
      returnStorehouseAdded: workerAccess ? true : false,
    });

    setOsListItems(newOsList);
  };

  const openScaningMode = (os: SupplyNetworkElement, disabled = false) => {
    const selectedMeterType = supplyOsTypes.get(os?.typeId ?? -1);
    const selectedOsScanField =
      selectedMeterType?.typeScanField || SupplyNetworkElementScanField.MAC;
    const selectedOsName = selectedMeterType?.name || '';
    const selectedOsDoubleNum =
      selectedMeterType?.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI;
    const parentTypeName =
      (selectedMeterType?.parentId
        ? supplyOsTypes.get(selectedMeterType?.parentId)?.name
        : selectedMeterType?.name) ?? '';
    setSelectedOS({
      ...os,
      typeScanField: selectedOsScanField,
      typeName: selectedOsName,
      doubleNumber: selectedOsDoubleNum,
      parentTypeName,
    });
    setSelectedOSDeclaredCount(os!.declaredCount.toString());
    initScanningOsItems(os, disabled);
    setIsScanningMode(true);
    setDisabledField(disabled);
  };

  const initScanningOsItems = (os: SupplyNetworkElement, viewMode?: boolean) => {
    const rowScanId = generateScanId(os, osListItems);
    const rowNumId = generateRowNum(os, osListItems);

    const tmpArr: SupplyNetworkElementItem[] = os!.networkElementItems.map((elem) => ({
      ...elem,
      savedItem: true,
    }));
    const withoutAddingRow =
      viewMode ||
      (billWithConfirm && workerAccess && os.networkElementItems.length) ||
      props.accessProject;

    const viewModeTmpArr = withoutAddingRow
      ? tmpArr
      : tmpArr.concat({
          tmpId: rowScanId,
          id: null,
          rowNum: rowNumId,
          typeId: os.typeId,
          typeName: os.typeName,
          imei: '',
          mac: '',
          savedItem: false,
          returnStorehouseAdded: !!workerAccess,
        } as SupplyNetworkElementItem);

    setOsListItems(viewModeTmpArr);
  };

  const setDeclareCount = (data: SupplyNetworkElement, val: any) => {
    const newPUList = osList.map((obj) => {
      if (obj.tmpId === data.tmpId) {
        return { ...obj, declaredCount: val };
      }
      return obj;
    });
    updateOSList(newPUList);
  };

  const setSelectedMeterType = (data: SupplyNetworkElement, val: SelectFilterOptType | null) => {
    let typeId = 0;
    let typeName = '';
    let typeRegex = '';
    if (val) {
      const supplyMeterType = supplyOsTypes.get(val.value);
      const meterType = osTypes.find((it) => it.value === val.value);
      typeId = Number(meterType?.value);
      typeName = meterType!.label;
      typeRegex = supplyMeterType?.numRegexExpr ?? '';
      setSelectedMeterTypesMap(selectedMeterTypesMap.set(data.tmpId, meterType!));
    } else {
      setSelectedMeterTypesMap(selectedMeterTypesMap.set(data.tmpId, null));
    }

    const newOsList = osList.map((obj) => {
      if (obj.tmpId === data.tmpId) {
        return {
          ...obj,
          typeId,
          typeName,
          typeRegex,
        };
      }
      return obj;
    });
    updateOSList(newOsList);
  };

  const updateOSList = (list: SupplyNetworkElement[]) => {
    setOSList(list);
  };

  const startScaning = (os: SupplyNetworkElement) => {
    openScaningMode(os);
    playAudioByKey('info');
  };

  const clearOsList = () => {
    const allUnsavedData = { ...getValue() };
    delete allUnsavedData[lsKey].osErrors;
    delete allUnsavedData[lsKey].os;
    setValue(allUnsavedData, lsKey);

    setOSList(initialOsList);
    setSelectedMeterTypesMap(new Map());
    setOsDupFieldError(new Map());
    setOsExistsFieldError(new Map());
  };

  const disabledOrDraftCheck =
    disabledField ||
    nonEditableBill ||
    (billType !== SupplyConstant.RETURNED_TYPE &&
      billType !== SupplyConstant.DRAFT_TYPE_RETURN &&
      !workerAccess);

  const handleChangeDeclaredCount = (data: SupplyNetworkElement, event: TextFieldChangeEvent) => {
    const onlyNums = replaceNonNums(event?.target?.value);
    setDeclareCount(data, onlyNums);
  };

  const disableRemoveScanner =
    disabledOrDraftCheck || !selectedMeterTypes.length || relocationGetBill;
  const disableScan = disabledOrDraftCheck && !props.accessProject;
  const disableSupplyClear = disabledOrDraftCheck || !osList.length || relocationGetBill;

  const disableAddIcon = disabledOrDraftCheck || relocationGetBill;

  const currentComponent = (
    <form>
      {osList &&
        osList.map((val) => {
          const osListError = checkOsErrors(osDupFieldError, osExistsFieldError, val?.tmpId);
          const hasNonConfirmedItems =
            billWithConfirm && workerAccess && !checkNonConfirmedItems(val);

          return (
            <Wrapper
              key={val.tmpId}
              unSavedItem={!val.backendItem}
              errorPus={osListError}
              allConfirmed={hasNonConfirmedItems}
            >
              <Grid
                key={val.tmpId}
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
                wrap='nowrap'
                marginBottom={'10px'}
              >
                {!returnBill && (
                  <Autocomplete
                    disablePortal
                    disabled={nonEditableBill || val?.networkElementItems?.length > 0}
                    value={selectedMeterTypesMap.get(val.tmpId) ?? null}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    options={osTypes}
                    noOptionsText={'Данных типов сетевого оборудования не найдено'}
                    sx={{ width: 200 }}
                    onChange={(e, value) => setSelectedMeterType(val, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Тип'
                        variant='standard'
                      />
                    )}
                  />
                )}
                <TextField
                  label='Заявленное количество'
                  disabled={nonEditableBill || relocationGetBill || props.accessProject}
                  InputLabelProps={{ shrink: true }}
                  value={val.declaredCount}
                  variant='standard'
                  sx={{ marginLeft: 3, width: 150 }}
                  onChange={(event) => handleChangeDeclaredCount(val, event)}
                />
                <ButtonStyled
                  variant='contained'
                  disabled={(!returnBill && !selectedMeterTypesMap.get(val.tmpId)) || disableScan}
                  onClick={() => startScaning(val)}
                >
                  Сканировать
                </ButtonStyled>
                <ButtonStyled
                  variant='contained'
                  onClick={() => openScaningMode(val, true)}
                >
                  Просмотр
                </ButtonStyled>
                <TextField
                  label='Уже добавлено'
                  InputLabelProps={{ shrink: true }}
                  variant='standard'
                  disabled
                  value={(val?.networkElementItems?.length || '')?.toString()}
                  style={{ marginLeft: '30px', width: '150px' }}
                />
                {!returnBill && (
                  <IconButton
                    color='error'
                    disabled={disabledOrDraftCheck || relocationGetBill}
                    onClick={() => showRemoveConfirm(val.tmpId)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                )}

                <IconButton
                  color='primary'
                  onClick={() => {
                    const selectedMeterType = supplyOsTypes.get(val?.typeId ?? -1);
                    const selectedOsScanField =
                      selectedMeterType?.typeScanField ===
                      SupplyNetworkElementScanField.MAC_AND_IMEI;
                    const contractor = responsablesOptions?.find(
                      (it) => it.value === contractorId
                    )?.label;
                    generateOsExcelFile(
                      val?.networkElementItems,
                      selectedMeterTypesMap.get(val.tmpId)?.label,
                      selectedOsScanField,
                      m15FormNumber,
                      billDate,
                      contractor
                    );
                  }}
                  disabled={!selectedMeterTypesMap.get(val.tmpId)}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Grid>
            </Wrapper>
          );
        })}
      <AlertRemove
        open={!!rowToRemove}
        handleClose={handleRemoveConfirmClose}
        handleSuccess={removeRow}
      />
      {!returnBill && (
        <SupplyButtonsWrapper>
          <IconButtonStyled
            disabled={disableAddIcon}
            onClick={() =>
              updateOSList(
                osList.concat({
                  tmpId: generateNextId(osList),
                  typeId: -1,
                  typeName: '',
                  networkElementItems: [],
                  declaredCount: '',
                  addedCount: '',
                })
              )
            }
          >
            <AddIcon />
          </IconButtonStyled>

          <OSRemoveScanner
            removeItems={removeOsByScanning}
            meterTypes={selectedMeterTypes}
            disabled={disableRemoveScanner}
            supplyMeterTypes={supplyOsTypes}
          />
          <SupplyClear
            buttonText='Очистить всё сетевое оборудование из накладной'
            modalText='Вы подтвержаете очистку всего сетевого оборудования в данной накладной?'
            onSuccess={clearOsList}
            disabled={disableSupplyClear}
          />
        </SupplyButtonsWrapper>
      )}
    </form>
  );

  const loading = returnBill
    ? isLoadingCompleteness ||
      isLoadingState ||
      isLoadingOwner ||
      isLoading ||
      isLoadingPuDefects ||
      isLoadingOsDefects
    : isLoading;

  const backendOptionsReady = returnBill
    ? puCompletenessOptions.length > 0 &&
      equipmentStateOptions.length > 0 &&
      puOwnerOptions.length > 0 &&
      puDefectOptions.length > 0 &&
      osDefectOptions.length > 0
    : osTypes && osTypes.length > 0;

  return (
    <div style={{ width: '100%', padding: '20px', marginBottom: '20px' }}>
      <SupplyBillBrowseLinearProgressWrapper>
        {loading && <LinearProgress />}
      </SupplyBillBrowseLinearProgressWrapper>

      {backendOptionsReady && currentComponent}
      <Modal
        open={isScanningMode}
        onClose={resetState}
        disableEscapeKeyDown
      >
        <SupplyPUModalInner>
          <SupplyOsModalHeader
            osListItems={osListItems}
            osDupFieldError={osDupFieldError}
            osExistsFieldError={osExistsFieldError}
            disabledOrDraftCheck={disableScan}
            selectedOs={selectedOS}
            returnBill={returnBill}
            selectedOsDeclaredCount={selectedOSDeclaredCount}
            saveOsItems={saveOsItems}
            closeScanning={resetState}
            setSelectedOsDeclaredCount={setSelectedOSDeclaredCount}
            removeErroredScanRows={removeErroredScanRows}
            confirmAllRows={confirmAllRows}
            viewMode={disabledField}
            tabType={props.tabType}
            relocationGetBill={relocationGetBill}
            billWithConfirm={billWithConfirm}
            setOsListItems={setOsListItems}
            accessProject={props.accessProject}
            loadingCheckExist={loadingCheckExist}
          />

          <SupplyOsVirtualizedTable
            osListItems={osListItems}
            osDupFieldError={osDupFieldError}
            osExistsFieldError={osExistsFieldError}
            disabledOrDraftCheck={disabledOrDraftCheck}
            selectedOs={selectedOS}
            returnBill={returnBill}
            billWithConfirm={billWithConfirm}
            relocationGetBill={relocationGetBill}
            removeScanRow={removeScanRow}
            handleSave={onEditCommit}
            onEditStart={onEditStart}
            showMessage={showMessage}
            confirmScanRow={confirmScanRow}
            confirmScanedNumber={confirmScanedNumber}
            viewMode={disabledField}
            tabType={props.tabType}
            setOsListItems={setOsListItems}
            accessProject={props.accessProject}
            loadingCheckExist={loadingCheckExist}
          />
        </SupplyPUModalInner>
      </Modal>
      <AlertError
        alertText={alertText}
        open={!!alertText}
        handleClose={handleClose}
      />
    </div>
  );
};

export default memo(SupplyOSComponent);
