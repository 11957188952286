import { ApiResponse } from '@/types/api';
import { SMROdpuStatisticsTable } from './dto/OdpuStatisticServiceResponseDto';
import { SMROdpuStatisticsFilter } from './dto/OdpuStatisticServiceRequestDto';
import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';

export async function GetSMROdpuStatisticsTableByFilter(
  filter: SMROdpuStatisticsFilter
): ApiResponse<SMROdpuStatisticsTable> {
  const url = '/rest/statistics/smr/odpu';
  return YodaRestClient.post(url, filter);
}
