import { GeoTransStation, PpoFilterRequest } from './dto';
import { GeoTaskInBounds } from '@/dto/taskmap/Dto';
import { SubscriberResponse } from '@/dto/taskmap/task/TaskResultDto';
import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';
import { ApiResponse } from '@/types/api';

export const findAllByFilter = (
  filter: PpoFilterRequest,
  isNeedDetails?: boolean
): ApiResponse<GeoTaskInBounds[]> => {
  const url = `/rest/geo/ppo`;
  return YodaRestClient.post(url, { ...filter, isNeedDetails: isNeedDetails ?? false });
};
export const getReportEnergyservice = (tpId: string): ApiResponse<GeoTaskInBounds[]> => {
  const url = `/rest/report/stolby/energyservice/${tpId}`;
  return YodaRestClient.get(url);
};

export const findAllDeliveryPoint = (taskId: string): ApiResponse<GeoTransStation> => {
  const url = `/rest/geo/ppo/${taskId}`;
  return YodaRestClient.get(url);
};

interface EditAddressData {
  region?: string | null;
  areaName?: string | null;
  locality?: string | null;
  street?: string | null;
  house?: string | null;
  apartment?: string | null;
}
export const editeAddress = (
  subId: string,
  data: EditAddressData
): ApiResponse<SubscriberResponse> => {
  const url = `/rest/subscriber/${subId}/modified-address`;
  return YodaRestClient.put(url, data);
};
