import { styled, keyframes } from '@mui/material';
import { color } from '@/styles/mixins';

const blinkAnimation = keyframes`
  0% { border-color: ${color('aBlue')}; }
  50% { border-color: ${color('gray3')}; }
  100% { border-color: ${color('aBlue')}; }
`;

export const ImportModalWrapper = styled('div')({
  marginTop: 10,
});

interface DropzoneProps {
  error: boolean;
  isUploading: boolean;
}

export const DropzoneWrapper = styled('div')<DropzoneProps>(({ error, isUploading }) => ({
  border: '2px dashed',
  borderColor: error ? color('trueRed') : color('gray3'),
  marginTop: 30,
  textAlign: 'center',
  height: 250,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  opacity: isUploading ? 0.5 : 1,
  cursor: isUploading ? 'wait' : 'pointer',
  animation: isUploading ? `${blinkAnimation} 1s infinite` : 'none',
}));
