import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';
import { AdpuRequest } from '@/components/features/taskEditor/adpu/api/dto/AdpuDtoRequest';
import { ApiResponse } from '@/types/api';
import { AdpuResponse } from './dto/AdpuDtoResponse';

export async function getAdpuById(adpuId: string): ApiResponse<any> {
  const url = `/rest/adpu/${adpuId}`;
  return YodaRestClient.get(url);
}

export async function getAdpuByTaskId(taskId: string): ApiResponse<any> {
  const url = `/rest/adpu/task/${taskId}`;
  return YodaRestClient.get(url);
}

export async function getAdpuOrder(adpuOrderId: string): ApiResponse<any> {
  const url = `/rest/adpu/adpu-order/${adpuOrderId}`;
  return YodaRestClient.get(url);
}

export async function createAdpu(data: AdpuRequest): ApiResponse<AdpuResponse> {
  const url = `/rest/adpu`;
  return YodaRestClient.post(url, data);
}

export async function updateAdpu(adpuId: string, data: AdpuRequest): ApiResponse<AdpuResponse> {
  const url = `/rest/adpu/${adpuId}`;
  return YodaRestClient.put(url, data);
}
