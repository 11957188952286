import {
  Pagination,
  Select,
  MenuItem,
  Box,
  Typography,
  TablePagination,
  Stack,
} from '@mui/material';
import { ImportPagination } from './Pagination.styled';

// Кастомный компонент пагинации
const CustomPagination = ({
  page,
  pageSize,
  totalElements,
  onPageChange,
  onPageSizeChange,
  rowsPerPageOptions = [5, 10, 25, 50, 100],
}: {
  page: number;
  pageSize: number;
  totalElements: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  rowsPerPageOptions?: number[];
}) => {
  return (
    <ImportPagination
      align='right'
      labelDisplayedRows={({ from, to }) => (
        <>
          {from} - {to} из {totalElements}
        </>
      )}
      count={totalElements}
      page={page}
      rowsPerPage={pageSize}
      onRowsPerPageChange={(newPageSize) => onPageSizeChange(Number(newPageSize.target.value))}
      onPageChange={(ev, newPage) => onPageChange(newPage)}
      rowsPerPageOptions={rowsPerPageOptions}
      labelRowsPerPage={'Импоротов на странице:'}
    />
    // </Stack>
  );
};

export default CustomPagination;
