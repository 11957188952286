import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import {
  Autocomplete,
  CircularProgress,
  debounce,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateRange } from '@/components/inputs/DateRange';
import React, { useEffect, useMemo, useState } from 'react';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { useCatalog } from '@/hooks/CatalogHook';
import { taskMapToArray } from '@/components/features/tasksBrowse/utils';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { defaultList } from '@/components/inputs/ArgumentSelectNew';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { useCallingMapState } from '../store';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import shallow from 'zustand/shallow';

import { useSaveFilterButton } from '../../../filter/FilterButtons/utils';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import moment from 'moment';
import { isNumberInRange, ZOOM_FOR_SEARCH } from '../utils/CallingMapUtils';
import { useCallingMapTabCount } from '@/components/maps/callingMap/components/CallingMapTab/store';
import SearchByAdressIdNumber from '@/components/maps/callingMap/components/SearchByAdressIdNumber';
import { useIncomingClaim } from '@/components/maps/callingMap/components/CallingMapIncomingTasksTab/store';
import { Controller, useForm } from 'react-hook-form';
import { ActionLogType, syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import {
  getIncomingCallDates,
  getIncomingCallRequestsPerDay,
  getInvaidTaskByPhoneNumberOrTaskId,
  getTaskByAdressCallMap,
  getTaskByIdCallMap,
  getTaskByNumberCallMap,
  getTaskByTaskIdUniqSearch,
  getTaskInvalidTasksByAdressCallMap,
} from '@/services/CallingMapService/CallingMapService';
import { DataAddressItem, FilterConfig } from '@/components/maps/callingMap/types/types';
import { getMonthAndYear } from '@/components/maps/callingMap/components/CallingMapIncomingTasks/utils';
import { formatPhoneNumberMask } from '@/utils/heplers';
import { SingleLine } from '@/components/inputs/SingleLineNew';
const SAVE_FILTER_KEY = 'CallingMap';

const argList = [FilterArgEnum.IN_LIST, FilterArgEnum.NOT_IN_LIST];
const argListEqual = [FilterArgEnum.EQUAL];
const argListOnlyInList = [
  FilterArgEnum.IN_LIST,
  FilterArgEnum.NOT_IN_LIST,
  FilterArgEnum.NOT_EQUAL,
];

interface LegendProps {
  handelCloseSettings: () => void;
  show: boolean;
  mapAPI: any;
  isTableSearch: boolean;
  isIncommingTaskSearch: boolean;
}
interface FormData {
  adress: string;
  id: string;
  contactPhone: string;
}
interface AddressOption {
  value: string;
  label: string;
  coordinats: number[];
}

export const CallingMapForm = ({
  onSubmit,
  onClose,
  show,
  isTableSearch,
  isIncommingTaskSearch,
}: any) => {
  const {
    getCatalogMapWithErr,
    taskTypeMap,
    phaseMap,
    montagePlaceCatalog,
    callCenterRequestCatalog,
    callStatusMap,
    installationCallType,
    tkoPeriodDefiner,
  } = useCatalog(
    (state) => ({
      getCatalogMapWithErr: state.getCatalogMapWithErr,
      taskTypeMap: state.taskType,
      phaseMap: state.phase,
      montagePlaceCatalog: state.montagePlaceType,
      callCenterRequestCatalog: state.callCenterRequestType,
      callStatusMap: state.callStatus,
      installationCallType: state.installationCallType,
      tkoPeriodDefiner: state.tkoPeriodDefiner,
    }),
    shallow
  );

  const {
    taskType,
    setMultiValue,
    setMultiValueArg,
    setDateValue,
    setDateValueArg,
    callPeriod,
    requestPeriod,
    installationCallStatus,
    montagePlaceType,
    phase,
    callCenterRequestType,
    geoPoints,
    urgentGeoPoints,
    toggleUrgentGeoPoins,
    setDefaultValue,
    mapZoom,
    taskId,
    phoneNumber,
    addressTitle,
    tkoPeriod,
    setSingleValue,
    setSingleValueArg,
    mapAPI,
    setGeoPoints,
    setGeoLess,
    setGeoLessCount,
    setCurrentPage,
    setPageSize,
    setIsLoadingTable,
    isUniqSearch,
  } = useCallingMapState(
    (state) => ({
      setGeoPoints: state.setGeoPoints,
      setMapZoom: state.setMapZoom,
      setMapCenterCoordinats: state.setMapCenterCoordinats,
      setDefaultValue: state.setDefaultValue,
      setGeoLess: state.setGeoLess,
      setGeoLessCount: state.setGeoLessCount,
      setCurrentPage: state.setCurrentPage,
      setPageSize: state.setPageSize,
      setIsLoadingTable: state.setIsLoadingTable,
      isUniqSearch: state.isUniqSearch,
      taskType: state.taskType,
      mapAPI: state.mapAPI,
      setMultiValue: state.setMultiValue,
      setMultiValueArg: state.setMultiValueArg,
      setDateValue: state.setDateValue,
      setDateValueArg: state.setDateValueArg,
      setSingleValue: state.setSingleValue,
      setSingleValueArg: state.setSingleValueArg,
      callPeriod: state.callPeriod,
      requestPeriod: state.requestPeriod,
      installationCallStatus: state.installationCallStatus,
      montagePlaceType: state.montagePlaceType,
      phase: state.phase,
      callCenterRequestType: state.callCenterRequestType,
      geoPoints: state.geoPoints,
      urgentGeoPoints: state.urgentGeoPoints,
      toggleUrgentGeoPoins: state.toggleUrgentGeoPoins,
      mapZoom: state.mapZoom,
      taskId: state.termTaskId,
      phoneNumber: state.phoneNumber,
      tkoPeriod: state.tkoPeriodDefiner,
      addressTitle: state.addressTitle,
    }),
    shallow
  );
  const { mapCount, taskWithoutCoordinatesCount } = useCallingMapTabCount(
    (state) => ({
      mapCount: state.mapCount,
      taskWithoutCoordinatesCount: state.taskWithoutCoordinatesCount,
    }),
    shallow
  );
  const isInRange =
    callPeriod.value.length > 0 &&
    callPeriod.value.length !== 2 &&
    callPeriod.argValue === FilterArgEnum.RANGE;
  const isInRangeRequestPeriod =
    requestPeriod.value.length > 0 &&
    requestPeriod.value.length !== 2 &&
    requestPeriod.argValue === FilterArgEnum.RANGE;

  const taskTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('taskType')),
    [taskTypeMap]
  );
  const callCenterRequestTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('callCenterRequestType')),
    [callCenterRequestCatalog]
  );
  const callStatusOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('callStatus')),
    [callStatusMap]
  );
  const phaseOptions = useMemo(() => taskMapToArray(getCatalogMapWithErr('phase')), [phaseMap]);

  const placeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('montagePlaceType')),
    [montagePlaceCatalog]
  );
  const installationCallTypeOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('installationCallType')),
    [installationCallType]
  );
  const tkoPeriodDefinerOptions = useMemo(
    () => taskMapToArray(getCatalogMapWithErr('tkoPeriodDefiner')),
    [tkoPeriodDefiner]
  );

  const renderTotal = (togle: boolean) => {
    if (mapCount == 'pending') {
      return (
        <>
          всего найдено заявок:
          <CircularProgress
            size={10}
            style={{ marginBottom: 3, marginLeft: 2 }}
          />
        </>
      );
    }
    if (typeof mapCount == 'number') {
      return `всего найдено заявок: ${mapCount}`;
    }

    return '';
  };

  const handleSubmitMain = () => {
    onSubmit();
    // handleSubmit(onSubmitNew)();
  };

  const convertTimeToShowInEye = (time: any) => {
    if (time.value.length) {
      return `${moment(time.value[0]).format('DD.MM.YYYY')} ~ ${moment(time.value[1]).format(
        'DD.MM.YYYY'
      )}`;
    }
    return '';
  };

  const detailedInfo = generateDetailedInfo(
    [callCenterRequestType.value, 'Тип обращения'],
    [taskId.value, 'Номер id'],
    [phoneNumber.value, 'Номер телефона'],
    [convertTimeToShowInEye(callPeriod), 'Период звонка'],
    [convertTimeToShowInEye(requestPeriod), 'Период по дате обрашения'],
    [installationCallStatus.value, 'Результат звонка'],
    [taskType.value, 'Тип заявки'],
    [[montagePlaceType.value] as unknown as SelectFilterOptType[], 'Место установки'],
    [phase.value, 'Фазность'],
    [[tkoPeriod.value] as unknown as SelectFilterOptType[], 'ТКО']
  );

  const { getFilters } = useSaveFilterButton(SAVE_FILTER_KEY);

  const onSelectSavedFilter = (filterKey: string) => {
    const savedFilters = getFilters<SelectFilterOptType[] | any>(filterKey);
    savedFilters.callCenterRequestType &&
      (() => {
        setMultiValue('callCenterRequestType')(savedFilters.callCenterRequestType.value);
        setMultiValueArg('callCenterRequestType')(savedFilters.callCenterRequestType.argValue);
      })();
    savedFilters.installationCallStatus &&
      (() => {
        setMultiValue('installationCallStatus')(savedFilters.installationCallStatus.value);
        setMultiValueArg('installationCallStatus')(savedFilters.installationCallStatus.argValue);
      })();
    savedFilters.taskType &&
      (() => {
        setMultiValue('taskType')(savedFilters.taskType.value);
        setMultiValueArg('taskType')(savedFilters.taskType.argValue);
      })();
    savedFilters.montagePlaceType &&
      (() => {
        setMultiValue('montagePlaceType')(savedFilters.montagePlaceType.value);
        setMultiValueArg('montagePlaceType')(savedFilters.montagePlaceType.argValue);
      })();
    savedFilters.tkoPeriod &&
      (() => {
        setMultiValue('tkoPeriodDefiner')(savedFilters.tkoPeriod.value);
        setMultiValueArg('tkoPeriodDefiner')(savedFilters.tkoPeriod.argValue);
      })();
    savedFilters.phase &&
      (() => {
        setMultiValue('phase')(savedFilters.phase.value);
        setMultiValueArg('phase')(savedFilters.phase.argValue);
      })();
    savedFilters.callPeriod &&
      (() => {
        setDateValueArg('callPeriod')(savedFilters.callPeriod.argValue);
        setDateValue('callPeriod')(savedFilters.callPeriod.value);
      })();
    savedFilters.requestPeriod &&
      (() => {
        setDateValueArg('requestPeriod')(savedFilters.requestPeriod.argValue);
        setDateValue('requestPeriod')(savedFilters.requestPeriod.value);
      })();
  };

  const handleEquals = (fieldArgNum: number) => {
    if (fieldArgNum === FilterArgEnum.EQUAL) return { isOnlySingleValue: true };
    return {};
  };

  const [addressOptions, setAddressOptions] = useState<AddressOption[]>([]);
  const [addressOptionCoordinats, setaddressOptionCoordinats] = useState<number[]>([]);

  const {
    selectedMonth,
    pageSizeIncomingTasks,
    currentPageIncomingTasks,
    setAvailableDates,
    setFilterIncommingTasks,
    setIncomingClaimRows,
    setIncomingClaimRowsLoading,
    setIncomingClaimCount,
  } = useIncomingClaim(
    (state) => ({
      filterIncommingTasks: state.filterIncommingTasks,
      selectedMonth: state.selectedMonth,
      selectedDate: state.selectedDate,
      pageSizeIncomingTasks: state.pageSize,
      currentPageIncomingTasks: state.currentPage,
      setFilterIncommingTasks: state.setFilterIncommingTasks,
      setAvailableDates: state.setAvailableDates,
      setIncomingClaimRows: state.setIncomingClaimRows,
      setIncomingClaimRowsLoading: state.setIncomingClaimRowsLoading,
      setIncomingClaimCount: state.setIncomingClaimCount,
    }),
    shallow
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    defaultValues: {
      adress: '',
      id: '',
      contactPhone: '',
    },
  });

  const { fetchCatch, addActionLog } = useActionLog();

  const onSubmitNew = async (formData: FormData) => {
    const { id, contactPhone } = formData;
    try {
      if (isIncommingTaskSearch) {
        setIncomingClaimCount('pending');
        setIncomingClaimRowsLoading(true);
        await handleIncommingTasksSearch(formData);
      } else if (isUniqSearch) {
        if (!id) {
          throw new Error('Укажите ID заявки для уникального поиска');
        }
        await handleUniqSearchById(id);
      } else if (id) {
        setIsLoadingTable(true);
        await handleIdSearch(id);
      } else if (contactPhone) {
        setIsLoadingTable(true);
        await handlePhoneSearch(contactPhone);
      } else {
        throw new Error('Введите корректные данные для поиска');
      }
    } catch (error) {
      fetchCatch(error);
    } finally {
      setIsLoadingTable(false);
      setAddressOptions([]);
      reset();
      setIncomingClaimRowsLoading(false);
    }
  };

  const handleIdSearch = async (id: string) => {
    if (!isTableSearch) {
      const { data } = await getTaskByIdCallMap(id);
      if (data.length) {
        setaddressOptionCoordinats([data[0]?.longitudeX, data[0]?.latitudeY]);
        setGeoPoints(data);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      }
    } else {
      const { data } = await getInvaidTaskByPhoneNumberOrTaskId({
        filterItemList: [{ attribute: 'taskId', value: id, filterArg: 0 }],
      });
      if (data?.length) {
        setGeoLess(data);
        setGeoLessCount(data?.length);
        setCurrentPage(0);
        setPageSize(5);
      } else {
        syncErrorCatch('не найдено');
      }
    }
  };

  const handlePhoneSearch = async (phone: string) => {
    const cleanedPhone = phone.replace(/\D/g, '');
    if (!isTableSearch) {
      const { data } = await getTaskByNumberCallMap(cleanedPhone);
      if (data.length) {
        setGeoPoints(data);
        setaddressOptionCoordinats([data[0]?.longitudeX, data[0]?.latitudeY]);
        addActionLog(ActionLogType.SUCCESS, 'Успешно');
      } else {
        syncErrorCatch('Заявка не найдена');
      }
    } else {
      const { data } = await getInvaidTaskByPhoneNumberOrTaskId({
        filterItemList: [{ attribute: 'phoneNumber', value: cleanedPhone, filterArg: 0 }],
      });
      if (data?.length) {
        setGeoLess(data);
        setGeoLessCount(data?.length);
        setCurrentPage(0);
        setPageSize(100);
      } else {
        syncErrorCatch('не найдено');
      }
    }
  };
  const handleUniqSearchById = async (id: string) => {
    const { data } = await getTaskByTaskIdUniqSearch({
      filterItemList: [{ attribute: 'taskId', value: id, filterArg: 0 }],
    });
    if (data?.length) {
      setGeoLess(data);
      setGeoLessCount(data?.length);
      setCurrentPage(0);
      setPageSize(5);
      addActionLog(ActionLogType.SUCCESS, 'Успешно');
    } else {
      syncErrorCatch('Заявка не найдена');
    }
  };

  const handleIncommingTasksSearch = async (formData: FormData) => {
    const { id, contactPhone, adress } = formData || {};
    const newFilter: FilterConfig = { filterItemList: [] };
    if (id) {
      newFilter.filterItemList.push({ attribute: 'taskId', value: id, filterArg: 0 });
    }
    if (contactPhone) {
      const cleanedPhone = contactPhone.replace(/\D/g, '');
      newFilter.filterItemList.push({
        attribute: 'phoneNumber',
        value: cleanedPhone,
        filterArg: 0,
      });
    }
    if (adress) {
      newFilter.filterItemList.push({ attribute: 'address', value: adress, filterArg: 0 });
    }

    setFilterIncommingTasks(newFilter);
    const { month, year } = getMonthAndYear(selectedMonth);
    const { data } = await getIncomingCallDates(month, year, newFilter);
    setAvailableDates(data);

    const { data: tableDate } = await getIncomingCallRequestsPerDay(
      pageSizeIncomingTasks * currentPageIncomingTasks,
      pageSizeIncomingTasks,
      newFilter // Передаем актуальный фильтр
    );
    setIncomingClaimCount(tableDate.length);
    setIncomingClaimRows(tableDate);
  };

  useEffect(() => {
    if (addressOptionCoordinats.length) {
      mapAPI?.setCenter(addressOptionCoordinats, 17, {
        checkZoomRange: true,
      });
    }
  }, [addressOptionCoordinats]);

  const handleAdressOptions = (term: string) => {
    getTaskByAdressCallMap(term).then(({ data }) => {
      const uniqueAddresses: { [address: string]: boolean } = {};
      const uniqueData: DataAddressItem[] = [];
      data.forEach((item) => {
        if (!uniqueAddresses[item.address] && item.latitudeY && item.longitudeX) {
          uniqueAddresses[item.address] = true;
          uniqueData.push(item);
        }
      });
      setAddressOptions(
        uniqueData.map((el) => ({
          value: el.id,
          label: el.address,
          coordinats: [el?.longitudeX ?? 0, el?.latitudeY ?? 0],
        }))
      );
    });
  };

  const getAdressDebounce = debounce(handleAdressOptions, 1000);

  return (
    <Stack
      position={'relative'}
      style={{ display: show ? 'block' : 'none' }}
    >
      <Divider />
      <Stack>
        <CloseFullscreenIcon
          onClick={onClose}
          style={{ alignSelf: 'flex-end', cursor: 'pointer' }}
        />
      </Stack>
      <Grid
        container
        xs={12}
      >
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Тип обращения'}
            argList={argList}
            options={callCenterRequestTypeOptions}
            onChange={setMultiValue('callCenterRequestType')}
            onArgChange={setMultiValueArg('callCenterRequestType')}
            {...callCenterRequestType}
            {...handleEquals(callCenterRequestType.argValue)}
            // disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DateRange
            isInRange={isInRangeRequestPeriod}
            label={'Период по дате обрашения'}
            onChange={setDateValue('requestPeriod')}
            onArgChange={setDateValueArg('requestPeriod')}
            {...requestPeriod}
            style={{ width: '100%' }}
            // isDisable={diableField}
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Результат звонка'}
            argList={argList}
            options={installationCallTypeOptions}
            onChange={setMultiValue('installationCallStatus')}
            onArgChange={setMultiValueArg('installationCallStatus')}
            {...installationCallStatus}
            {...handleEquals(installationCallStatus.argValue)}
            // disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <DateRange
            isInRange={isInRange}
            label={'Период звонка'}
            onChange={setDateValue('callPeriod')}
            onArgChange={setDateValueArg('callPeriod')}
            {...callPeriod}
            style={{ width: '100%' }}
            // isDisable={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Место установки'}
            argList={argListOnlyInList}
            options={placeOptions}
            onChange={setMultiValue('montagePlaceType')}
            onArgChange={setMultiValueArg('montagePlaceType')}
            {...montagePlaceType}
            // disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Фазность'}
            argList={argList}
            options={phaseOptions}
            onChange={setMultiValue('phase')}
            onArgChange={setMultiValueArg('phase')}
            {...phase}
            {...handleEquals(phase.argValue)}
            // disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Тип заявки'}
            argList={argList}
            options={taskTypeOptions}
            onChange={setMultiValue('taskType')}
            onArgChange={setMultiValueArg('taskType')}
            {...taskType}
            {...handleEquals(taskType.argValue)}
            // disabled={diableField}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <MultiSelect
            label={'Отображение заявок с отказными ТКО'}
            argList={argListEqual}
            options={tkoPeriodDefinerOptions}
            onChange={setMultiValue('tkoPeriodDefiner')}
            onArgChange={setMultiValueArg('tkoPeriodDefiner')}
            {...tkoPeriod}
            // disabled={diableField}
            isOnlySingleValue
          />
        </Grid>

        <Grid
          item
          xs={6}
        >
          <SingleLine
            argList={argListEqual}
            onChange={setSingleValue('termTaskId')}
            label={'Поиск ID заявки'}
            onArgChange={setSingleValueArg('termTaskId')}
            {...taskId}
          />
        </Grid>
        <Grid
          item
          xs={6}
        >
          <SingleLine
            argList={argListEqual}
            onChange={setSingleValue('phoneNumber')}
            label={'Поиск по номеру'}
            onArgChange={setSingleValueArg('phoneNumber')}
            {...phoneNumber}
          />
        </Grid>
        {(isTableSearch || isIncommingTaskSearch) && (
          <Grid
            item
            xs={12}
          >
            <MultiSelect
              label={'Адресс заявки'}
              argList={argListEqual}
              options={addressOptions}
              onChange={setMultiValue('addressTitle')}
              onArgChange={setMultiValueArg('addressTitle')}
              {...addressTitle}
              isOnlySingleValue
              onInputChange={(e, newInputValue) => {
                getAdressDebounce(newInputValue);
              }}
            />
          </Grid>
        )}
        <Grid
          item
          justifyContent={'space-between'}
          display={'flex'}
          alignItems={'center'}
          xs={12}
          mt={2}
          p={1}
        >
          <Typography>{renderTotal(toggleUrgentGeoPoins)}</Typography>
          <FilterButtons
            info={detailedInfo}
            saveFilter={{
              filterType: SAVE_FILTER_KEY,
              infoToSave: {
                callPeriod,
                requestPeriod,
                installationCallStatus,
                montagePlaceType,
                phase,
                callCenterRequestType,
                taskType,
              },
              selectCallback: onSelectSavedFilter,
            }}
            onSearchClick={handleSubmitMain}
            onCloseClick={setDefaultValue}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
