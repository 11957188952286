import { ApiResponse } from '@/types/api';
import { TagsResponse } from './dto/TagsDto';
import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';

export async function getTags(taskId: string): ApiResponse<TagsResponse[]> {
  const url = `/rest/task/${taskId}/tag`;
  return YodaRestClient.get(url);
}
export async function createTag(tagName: string): ApiResponse<TagsResponse> {
  const url = `/rest/tag`;
  return YodaRestClient.post(url, { title: tagName });
}
export async function addTagToTasks(
  taskIdList: string[],
  tagId: string
): ApiResponse<TagsResponse[]> {
  const url = `/rest/task-tag`;
  return YodaRestClient.post(url, { taskIdList, tagId });
}
export async function deleteTag(taskTagId: string): ApiResponse<TagsResponse> {
  const url = `/rest/task-tag/${taskTagId}`;
  return YodaRestClient.delete(url);
}
