import { defaultValueSingleInputs, ITasksBrowseStore, SingleInputKeys } from './store';
import { FilterArgEnum } from '@/components/inputs/ArgumentSelectNew/types';
import { SelectFilterOptType } from '@/components/inputs/MultiSelectNew/types';
import { getDataFromFilter } from '@/components/inputs/MultiSelectNew/utils';

export const arrowLabel = (type: 'first' | 'last' | 'next' | 'previous'): string => {
  switch (type) {
    case 'first':
      return 'первая';
    case 'last':
      return 'последняя';
    case 'next':
      return 'следующая';
    case 'previous':
      return 'предыдущая';
  }
};

export const taskMapToArray = (
  map: Map<string, string> | Record<string, string>
): { value: string; label: string }[] =>
  map instanceof Map
    ? [...map].map(([key, value]) => ({
        value: key,
        label: value,
      }))
    : Object.entries(map).map(([key, value]) => ({
        value: key,
        label: value,
      }));

export enum FilterVariants {
  MAIN,
  SECOND,
  ALL,
}

interface IConverter {
  o: ITasksBrowseStore;
  variant: FilterVariants;
}

export const converter = ({ o, variant }: IConverter) => {
  const mainStore = {
    address: o.address.value,
    korpus: o.korpus.value,
    addressArg: o.address.argValue,
    korpusArg: o.korpus.argValue,
    contractorIds: getDataFromFilter(o.contractorIds.value, 'value'),
    contractorIdsArg: o.contractorIds.argValue,
    settlement: o.settlement.value,
    settlementArg: o.settlement.argValue,
    street: o.street.value,
    streetArg: o.street.argValue,
    house: o.house.value,
    houseArg: o.house.argValue,
    appartment: o.appartment.value,
    appartmentArg: o.appartment.argValue,
    contractIds: getDataFromFilter(o.contractIds.value, 'value'),
    contractIdsArg: o.contractIds.argValue,
    executorIds: getDataFromFilter(o.executorIds.value, 'value'),
    executorIdsArg: o.executorIds.argValue,
    taskType: getDataFromFilter(o.taskType.value, 'value'),
    taskTypeArg: o.taskType.argValue,
    taskPriority: getDataFromFilter(o.taskPriority.value, 'value'),
    taskPriorityArg: o.taskPriority.argValue,
    taskStatus: getDataFromFilter(o.taskStatus.value, 'value'),
    taskStatusArg: o.taskStatus.argValue,
    consumer: getDataFromFilter(o.consumer.value, 'value'),
    consumerArg: o.consumer.argValue,
    schemeBYT: o.schemeBYT.value,
    schemeBYTArg: o.schemeBYT.argValue,
    tkoStatus: o.tkoStatus.value,
    tkoStatusArg: o.tkoStatus.argValue,
    actStatus: getDataFromFilter(o.actStatus.value, 'value'),
    actStatusArg: o.actStatus.argValue,
    territorialBranch: o.territorialBranch.value,
    territorialBranchArg: o.territorialBranch.argValue,
    managingOrganization: getDataFromFilter(o.managingOrganization.value, 'value'),
    managingOrganizationArg: o.managingOrganization.argValue,
    executionDateOnList: o.date.value,
    executionDateOnListArg: o.date.argValue,
    nextCallDateOnArg: FilterArgEnum.EQUAL,
    onlyWithChanges: o.onlyWithChanges,
    onlyWithTko: o.onlyWithTko,
    onlyWithCallCenter: o.onlyWithCallCenter,
    onlyWithCreationConfirmation: o.onlyWithCreationConfirmation,
    isShowingNotRelevant: o.isShowingNotRelevant,
    date: o.date.value,
    dateArg: o.date.argValue,
    plannedDateOnList: o.plannedDateOnList.value,
    plannedDateOnListArg: o.plannedDateOnList.argValue,
    electricNetworksEnterpriseArg: o.networkFilial.argValue,
    electricNetworksEnterpriseList: getDataFromFilter(o.networkFilial.value, 'value'),
    regionalElectricNetworkArg: o.electricNetworksEnterprise.argValue, // вместо networkResArea
    regionalElectricNetworkList: getDataFromFilter(o.electricNetworksEnterprise.value, 'value'),
    // networkResAreaArg: o.electricNetworksEnterprise.argValue,
    // networkResArea: o.electricNetworksEnterprise.value,
    customerId: o.customerId.value,
    customerIdArg: o.customerId.argValue,
    registrationNum: o.registrationNum.value,
    registrationNumArg: o.registrationNum.argValue,
    subscriberFio: o.subscriberFio.value,
    subscriberFioArg: o.subscriberFio.argValue,
    exPuNum: o.exPuNum.value,
    exPuNumArg: o.exPuNum.argValue,
    tpNameList: o.tpName.value,
    tpNameArg: o.tpName.argValue,
    cipher: o.cipher.value,
    cipherArg: o.cipher.argValue,
  };
  const secondStore = {
    meterChangeId: o.meterChangeId.value,
    meterChangeIdArg: o.meterChangeId.argValue,
    stampNum: o.stampNum.value,
    stampNumArg: o.stampNum.argValue,
    taskNum: o.taskNum.value,
    taskNumArg: o.taskNum.argValue,
    taskId: o.taskId.value,
    vruInputId: o.vruInputId.value,
    vruInputIdArg: o.vruInputId.argValue,
    taskIdArg: o.taskId.argValue,
    networkElementNum: o.networkElementNum.value,
    networkElementNumArg: o.networkElementNum.argValue,
    puNumOrMac: o.puNumOrMac.value,
    puNumOrMacArg: o.puNumOrMac.argValue,
    existingPuNum: o.existingPuNum.value,
    existingPuNumArg: o.existingPuNum.argValue,
    accountingNum: o.accountingNum.value,
    accountingNumArg: o.accountingNum.argValue,
  };
  let store: any;
  switch (variant) {
    case FilterVariants.MAIN:
      store = { ...mainStore };
      break;
    case FilterVariants.SECOND:
      store = { ...secondStore };
      break;
    case FilterVariants.ALL:
      store = { ...mainStore, ...secondStore };
      break;
  }

  return Object.entries(store).reduce(
    (acc, [key, value]) => {
      if (value || value === 0) {
        if (Array.isArray(value)) {
          if (
            store[`${key}Arg`] === FilterArgEnum.INSTALLED &&
            Object.keys(defaultValueSingleInputs).includes(key)
          ) {
            return {
              ...acc,
              [key]: value[0],
            };
          }
          if (value.length) {
            return {
              ...acc,
              [key]: value.map((item) => item),
            };
          } else {
            return {
              ...acc,
            };
          }
        } else {
          // if (store[`${key}Arg`] === FilterArgEnum.IN_LIST && typeof value == 'string') {
          //   return {
          //     ...acc,
          //     [key]: value.split(',').map((item) => item),
          //   };
          // } else {
          //   return {
          //     ...acc,
          //     [key]: value,
          //   };
          // }

          if (key === 'taskId') {
            const isList = store[`taskIdArg`] === FilterArgEnum.IN_LIST && typeof value == 'string';
            return {
              ...acc,
              taskIdList: isList
                ? value.includes(',')
                  ? value.split(',').map((item) => item)
                  : value.split(' ').map((item) => item)
                : [value],
            };
          }
          if (key === 'vruInputId') {
            const isList =
              store[`vruInputIdArg`] === FilterArgEnum.IN_LIST && typeof value == 'string';
            return {
              ...acc,
              vruInputIdList: isList
                ? value.includes(',')
                  ? value.split(',').map((item) => item)
                  : value.split(' ').map((item) => item)
                : [value],
            };
          }
          if (key === 'accountingNum') {
            const isList =
              store[`accountingNumArg`] === FilterArgEnum.IN_LIST && typeof value == 'string';
            return {
              ...acc,
              accountingNumList: isList ? value.split(',').map((item) => item) : [value],
            };
          }
          if (key === 'existingPuNum') {
            const isList =
              store[`existingPuNumArg`] === FilterArgEnum.IN_LIST && typeof value == 'string';
            return {
              ...acc,
              existingPuNum: isList ? value.split(',').map((item) => item) : [value],
            };
          }

          if (key === 'taskNum') {
            const isList =
              store[`taskNumArg`] === FilterArgEnum.IN_LIST && typeof value == 'string';
            return {
              ...acc,
              taskNum: isList ? value.split(',').map((item) => item) : [value],
            };
          }
          if (key === 'tpNameList') {
            const isStr = typeof value == 'string';
            return {
              ...acc,
              tpNameList: isStr
                ? value
                    .split(', ')
                    .join(',')
                    .split(',')
                    .map((item) => item)
                : [value],
            };
          }
          return {
            ...acc,
            [key]: value,
          };
        }
      }
      return acc;
    },
    variant === FilterVariants.SECOND
      ? {}
      : {
          onlyWithChanges: o.onlyWithChanges,
          onlyWithTko: o.onlyWithTko,
          onlyWithCreationConfirmation: o.onlyWithCreationConfirmation,
          onlyWithCallCenter: o.onlyWithCallCenter,
          isShowingNotRelevant: o.isShowingNotRelevant,
          ...(o.odpuStage?.value ? { odpuStage: o.odpuStage.value } : {}),
        }
  );
};

export const getActiveFilterLabels = (
  activeValues: string[],
  from: Map<string, string> | ([string, string] | SelectFilterOptType)[]
) => {
  const activeLabels: string[] = [];

  if (from instanceof Map) {
    activeValues.forEach((element) => {
      const label = from.get(element);
      if (label) activeLabels.push(label);
    });
  } else {
    for (let i = 0; i < from.length; i++) {
      const element = from[i];
      if (Array.isArray(element)) {
        if (activeValues.includes(element[0])) activeLabels.push(element[1]);
      } else {
        if (activeValues.includes(element.value)) activeLabels.push(element.label);
      }
    }
  }

  return activeLabels;
};
