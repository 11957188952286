export enum RolesEnum {
  Administrators = 'Administrators',
  // ReportUral = "ReportUral",
  // AssociationAddressToBtn = "associationAddressToBtn",
  CallCenter = 'call_center',
  CallCenterSec = 'call_center.SEC',
  CallCenterScreen = 'call_centre_screen',
  // CreateSubscriber = "create_subscriber",
  // Deny = "deny",
  Storehouse = 'storehouse',
  StorehouseChief = 'storehouse_chief', // Storehouse + доп функционал
  StorehouseContractor = 'storehouse_contractor', // Складская роль подрядчиков
  StorehouseProject = 'storehouse_project',
  Designer = 'designer',
  // EntityChangeLog = "entity_change_log",
  Executor = 'executor',
  // ExecutorWhithUpdateNetworkEq = "executor_whith_update_network_eq",
  // ImportEquipment = "import_equipment",
  // Inspector = "inspector",
  Leader = 'leader',
  // LeaderUpdatePu = "leader_update_pu",
  MesManager = 'mes_manager',
  MesManagerWithoutPhoto = 'mes_manager_without_photo',
  MesManagerWithPhoto = 'mes_manager_with_photo',
  // Owner = "owner",
  // OwnerMkd = "owner_mkd",
  OwnerPerm = 'owner_perm',
  // OwnerStolby = "owner_stolby",
  // PhotoBrowser = "photo browser",
  // PnrReader = "pnr_reader",
  // PpoValidatorTp = "ppoValidatorTp",
  ProjectManager = 'project_manager',
  // ProjectManagerUpdateSubs = "project_manager_update_subs",
  // PskOsViewer = "psk_os_viewer",
  // Reviewer = "reviewer",
  // SuperLeader = "super_leader",
  TeamManager = 'team_manager',
  TeamManagerWithUSPDMap = 'team_manager_with_USPD_map',
  ManagerPsk = 'manager_psk',
  Tp = 'tp',
  // UpdateNetworkEq = "update_network_eq",
  Voorman = 'voorman',
  // WarehouseEmployee = 'warehouse_employee',
  TechConnectionViewer = 'techConnection_viewer',
  //calling map
  ContractorOperatorRole = 'ContractorOperatorRole',
  SupplyInfo = 'supplyInfo',
  CustomerViewerMrsk = 'customerViewerMrsk',
  ManagerMesTo = 'manager_mes_to',
  uspdMapOnly = 'uspd_map',
  ComplaintManager = 'complaint_manager',
  ComplaintCustomer = 'complaint_customer',
  ComplaintOdpu = 'complaint_odpu',
  ComplaintEnergo = 'complaint_energy',
  PPOODPUVisitor = 'ppo_odpu_visitor',
  ReportForAudit = 'report_for_audit',
  MergeHouse = 'merge_house',
  PskCusotmerManager = 'psk_customer_manager',
  ConractorOperatorRoleSEC = 'ConractorOperatorRoleSEC',
}

export enum AccessEnum {
  // Не трогать, useAccess некорректно работает с AccessEnum === 0, возникают проблемы для админской роли
  FALSE,

  Admin,
  TasksBrowse,
  PUManaging,
  Map,
  Statistic,
  CallCenterIncoming,
  CallCenterResolved,
  CalLCenterResolvedBashkortostan,
  MapUspd,
  Storehouse,
  StorehouseChief,
  YUNGA,
  PUCorrections,
  Catalogs,
  CatalogsMeter,
  CastomPskClose,
  Registry,
  OnlyManagerAndLeadAndTeamManager,
  Drive,
  TeamManagerAndVoorman,
  CallingMapOperator,
  ShowPPOStageChange,
  StorehouseInfo,
  DeliveryPointScreen,
  ShowReports,
  DisabledAll,
  TeamManagerVoormanAndExecutor,
  MapUspdOnly,
  ComplaintsPage,
  ComplaintsEditAll,
  ComplaintsEditCallCenter,
  ComplaintsEditReturned,
  ComplaintsEditTransferedToResponsible,
  ComplaintsEditModeratedAfterResponsible,
  ComplaintsEditInProgress,
  ComplaintsCreateEnergyType,
  ComplaintsCreateOdpuType,
  ComplaintsEditOdpuModerated,
  ComplaintsEditOdpuReturned,
  notValidateComplaintSupAndEnergyType,
  SupplyInfoStatistic,
  CallingMap,
  TaskAssignMap,
  PpoMap,
  uniqSearchCallingMap,
  ImportPage,

}
