import { useEffect, useState } from 'react';
import { decodeJWT } from '@/utils/heplers';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { yodatk } from '@/app/auth/AuthProvider';
import { KeyCloakJWT } from '@/app/auth/types';

const useUserName = () => {
  const [userName, setUserName] = useState<null | string>(null);
  const token = useLocalStorage(yodatk);

  useEffect(() => {
    if (!token) return;
    const decodeAndSetName = () => {
      const decodedJWT: null | KeyCloakJWT = decodeJWT(token);
      const decodedUserName = decodedJWT?.name;
      if (decodedUserName) setUserName(decodedUserName);
    };

    decodeAndSetName();
  }, [token]);

  return userName;
};

export default useUserName;
