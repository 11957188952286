import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';

import { playAudioByKey } from '@/utils/heplers';
import { SupplyOsVirtualizedTableProps } from './SupplyOSVirtualizedTable';
import { SupplyPUTableProps } from '../SupplyPUComponent/SupplyPURow';
import {
  SupplyPUComponentTableRowWrapper,
  SupplyPUTableCell,
  SupplyPUTableInputCell,
  SupplyPUTableInput,
  SupplyPURowButton,
  SupplyPUTableCommentCell,
  SupplyPUCommentTextField,
} from '../SupplyPUComponent/SupplyPUComponent.styled';
import {
  focusOnCell,
  DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE,
  SupplyPUItemConfirmState,
  useAdminAccess,
  useStorehouseWorkerAccess,
  convertIntoFilter,
  isChildType,
} from '../SupplyPUComponent/utils';
import { SupplyNetworkElementScanField } from '@/dto/SupplyBillServiceDto';
import SupplyReturnBillExtraColumns from '../SupplyReturnBillExtraColumns/SupplyReturnBillExtraColumns';
import { EquipmentTypes } from '../utils';
import { useAllNetworkElementTypes } from '@/hooks/useQuery/useAllNetworkElementTypes';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import { SupplyCommentModal } from '../SupplyCommentModal/SupplyCommentModal';
import { Popper } from '@/components/fixUI/Popper';
import {
  EMPTY_RETURN_NEW_ITEM_CELLS,
  EMPTY_TWO_CELLS,
} from '../SupplyReturnBillExtraColumns/utils';
import useSupplyStore from '../SupplyStore';

interface SupplyOsTableProps extends Omit<SupplyPUTableProps, 'data'> {
  data: Omit<SupplyOsVirtualizedTableProps, 'confirmScanedNumber' | 'setOsListItems'>;
}

const SupplyOsRow = ({
  index,
  style,
  data: {
    osListItems,
    removeScanRow,
    handleSave,
    onEditStart,
    disabledOrDraftCheck,
    selectedOs,
    osDupFieldError,
    osExistsFieldError,
    returnBill,
    showMessage,
    confirmScanRow,
    viewMode,
    billWithConfirm,
    relocationGetBill,
    tabType,
    accessProject,
    loadingCheckExist,
  },
}: SupplyOsTableProps) => {
  const workerAccess = useStorehouseWorkerAccess();
  const adminAccess = useAdminAccess();

  const { networkElementTypesResponse, isLoading } = useAllNetworkElementTypes();

  const row = osListItems[index];
  const macImeiScan = selectedOs?.typeScanField === SupplyNetworkElementScanField.MAC_AND_IMEI;

  const networkElementTypes = convertIntoFilter(networkElementTypesResponse);
  const networkElementTypeItem = row.typeId
    ? networkElementTypes.find((option) => option.value === row.typeId)
    : null;

  const [networkElementType, setNetworkElementType] = useState<SelectFilterOptType | null>(
    networkElementTypeItem || null
  );
  const [macNumber, setMacNumber] = useState(() => row.mac || '');
  const [imeiNumber, setImeiNumber] = useState(() => row.imei || '');

  const returnNewItemsMode = useSupplyStore((state) => state.returnNewItemsMode);

  const [fullName, setFullName] = useState(() => {
    const fullNameOs = networkElementTypesResponse?.find(
      (pu) => pu.id === networkElementTypeItem?.value
    )?.fullName;
    return {
      text: fullNameOs || '',
      prevValue: '',
    };
  });

  const [selectedChildType, setSelectedChildType] = useState(null);

  const error =
    osDupFieldError?.get(selectedOs?.tmpId ?? -1)?.get(row.tmpId) ||
    osExistsFieldError?.get(selectedOs?.tmpId ?? -1)?.get(row.tmpId);

  const handleBlur = ({ withSound }: { withSound?: boolean }) => {
    handleSave({
      ...row,
      mac: macNumber,
      imei: imeiNumber,
    });
    if (withSound) {
      playAudioByKey('attention');
    }
  };

  useEffect(() => {
    if (networkElementType) {
      const fullNameOs = networkElementTypesResponse?.find(
        (pu) => pu.id === networkElementType?.value
      )?.fullName;

      handleSave({
        ...row,
        typeName: networkElementType?.label ?? '',
        typeId: networkElementType?.value,
        fullName: fullNameOs,
      });

      if (!fullNameOs) {
        return;
      }

      setFullName((prev) => ({ ...prev, text: fullNameOs }));
    }
  }, [networkElementType]);

  const handleFocus = () => onEditStart({ ...row });
  const handleDelete = (rowId: number) => row?.tmpId && removeScanRow(rowId);
  const handleConfirm = (rowId: number) => row?.tmpId && confirmScanRow(rowId);

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const selectNextRow = () => {
        const nextRowCell = osListItems[index + 1]?.tmpId;
        nextRowCell && focusOnCell({ cellRowId: nextRowCell });
      };
      if (macImeiScan) {
        const cellDoubleNumberStage =
          e.currentTarget.dataset[DOUBLE_NUMBER_METER_TYPE_CELL_DATA_ATTRIBUTE]!;
        if (cellDoubleNumberStage === '1') {
          const currentRowId = osListItems[index]?.tmpId;
          currentRowId && focusOnCell({ cellRowId: currentRowId, secondNumber: true });
        } else {
          selectNextRow();
        }
      } else {
        selectNextRow();
      }
    }
  };

  const disableWorkerAccess = workerAccess && returnBill && !adminAccess;
  const disableNumberInput =
    disabledOrDraftCheck || disableWorkerAccess || relocationGetBill || loadingCheckExist;

  const childTypeOptions = useMemo(
    () => networkElementTypes.filter((item) => isChildType(item.label)),
    [networkElementTypes]
  );

  const [activeModal, setActiveModal] = useState(false);

  const handleEnterComment = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleCommentSave();
  };

  const handleCommentSave = () => setActiveModal(false);

  return (
    <>
      <div style={style}>
        <SupplyPUComponentTableRowWrapper
          error={!!error}
          unSavedItem={!row.backendItem}
          className='SupplyVirtualizedTable_Row'
        >
          <SupplyPUTableCell
            withoutPadding
            narrow
          >
            {row.rowNum}
          </SupplyPUTableCell>
          {returnNewItemsMode && row.rowNum !== 1 ? (
            <>
              {EMPTY_TWO_CELLS.map((cell, index) => (
                <SupplyPUTableCell key={index} />
              ))}
            </>
          ) : (
            <>
              {returnBill ? (
                <SupplyPUTableCell>
                  <Autocomplete
                    fullWidth
                    disabled={disabledOrDraftCheck && !accessProject}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    PopperComponent={Popper}
                    options={networkElementTypes}
                    value={networkElementType}
                    onChange={(_event: any, newValue: any) => setNetworkElementType(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!networkElementType}
                        variant='standard'
                      />
                    )}
                  />
                </SupplyPUTableCell>
              ) : (
                <SupplyPUTableCell>{selectedOs?.typeName}</SupplyPUTableCell>
              )}
              {returnBill && (
                <>
                  {networkElementType && !isChildType(networkElementType.label) ? (
                    <SupplyPUTableCell>
                      <Autocomplete
                        fullWidth
                        disabled={disabledOrDraftCheck && !accessProject}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        PopperComponent={Popper}
                        options={childTypeOptions}
                        value={selectedChildType}
                        onChange={(_event: any, newValue: any) => setNetworkElementType(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={!selectedChildType}
                            variant='standard'
                          />
                        )}
                      />
                    </SupplyPUTableCell>
                  ) : (
                    <SupplyPUTableCommentCell
                      onClick={() => {
                        setFullName((prev) => ({ ...prev, prevValue: prev.text }));
                        setActiveModal(true);
                      }}
                      disabled={disabledOrDraftCheck && !accessProject}
                    >
                      <SupplyPUCommentTextField
                        variant='standard'
                        value={fullName.text}
                        error={!fullName.text.length}
                      />
                    </SupplyPUTableCommentCell>
                  )}
                </>
              )}
            </>
          )}
          {macImeiScan ? (
            <>
              <SupplyPUTableInputCell
                confirmed={!error && row.confirmed === SupplyPUItemConfirmState.bothNumbers}
              >
                <SupplyPUTableInput
                  type='text'
                  name='imei'
                  value={imeiNumber}
                  onChange={(e) => setImeiNumber(e.target.value)}
                  onFocus={handleFocus}
                  onKeyDown={handleEnter}
                  autoComplete='off'
                  data-tmpid={row.tmpId}
                  data-double-number='1'
                  disabled={disableNumberInput}
                />
              </SupplyPUTableInputCell>
              <SupplyPUTableInputCell
                onBlur={() => handleBlur({ withSound: true })}
                confirmed={!error && row.confirmed === SupplyPUItemConfirmState.bothNumbers}
              >
                <SupplyPUTableInput
                  type='text'
                  name='mac'
                  value={macNumber}
                  onChange={(e) => setMacNumber(e.target.value)}
                  onFocus={handleFocus}
                  onKeyDown={handleEnter}
                  autoComplete='off'
                  data-tmpid={row.tmpId}
                  data-double-number='2'
                  disabled={disableNumberInput}
                />
              </SupplyPUTableInputCell>
            </>
          ) : (
            <SupplyPUTableInputCell
              onBlur={() => handleBlur({ withSound: false })}
              confirmed={!error && row.confirmed === SupplyPUItemConfirmState.bothNumbers}
            >
              <SupplyPUTableInput
                type='text'
                name='imei'
                value={imeiNumber}
                onChange={(e) => setImeiNumber(e.target.value)}
                onFocus={handleFocus}
                onKeyDown={handleEnter}
                autoComplete='off'
                data-tmpid={row.tmpId}
                disabled={disableNumberInput}
              />
            </SupplyPUTableInputCell>
          )}
          {returnBill && (
            <>
              <SupplyReturnBillExtraColumns
                handleSave={handleSave}
                row={row}
                disabled={disabledOrDraftCheck && !accessProject}
                showMessage={showMessage}
                type={EquipmentTypes.OS}
                selectedEquipment={selectedOs}
              />
            </>
          )}
          {!viewMode && (
            <>
              {tabType !== 'relocation_get' && (
                <SupplyPUTableCell>
                  <Button
                    disabled={disabledOrDraftCheck}
                    onClick={() => handleDelete(row.tmpId)}
                  >
                    Удалить
                  </Button>
                </SupplyPUTableCell>
              )}

              {billWithConfirm && workerAccess && (
                <SupplyPUTableCell>
                  <SupplyPURowButton
                    disabled={disabledOrDraftCheck && !relocationGetBill}
                    onClick={() => handleConfirm(row.tmpId)}
                    errorBorder={row.confirmed !== SupplyPUItemConfirmState.bothNumbers}
                  >
                    Подтвердить
                  </SupplyPURowButton>
                </SupplyPUTableCell>
              )}
            </>
          )}
        </SupplyPUComponentTableRowWrapper>
      </div>
      <SupplyCommentModal
        isOpen={activeModal}
        onCloseHandler={handleCommentSave}
        title={'Полное наименование'}
        value={fullName.text}
        onChangeHandler={(e) => setFullName((prev) => ({ ...prev, text: e.target.value }))}
        error={!fullName.text.length}
        disabled={networkElementType !== null}
        onKeyDownHandler={handleEnterComment}
        saveHandler={handleCommentSave}
      />
    </>
  );
};

export default SupplyOsRow;
