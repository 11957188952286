// components/FileDropzone.tsx
import { Box, Typography, Stack, CircularProgress } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Accept } from 'react-dropzone';
import { DropzoneWrapper } from '../ImportMainScreen/ImportMainScreen.styled';

interface FileDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  error: string;
  children?: React.ReactNode;
  isUploading?: boolean; // Добавляем проп для индикатора загрузки
  disabled?: boolean; // Добавляем проп для блокировки
}

export const FileDropzone = ({
  onDrop,
  error,
  children,
  isUploading = false,
  disabled = false,
}: FileDropzoneProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: disabled || isUploading, // Блокируем при загрузке
  });

  return (
    <DropzoneWrapper
      {...getRootProps()}
      error={!!error}
      isUploading={isUploading}
    >
      <input {...getInputProps()} />

      {/* Индикатор загрузки */}
      {isUploading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 2,
          }}
        >
          <CircularProgress size={40} />
        </Box>
      )}

      <Stack sx={{ visibility: isUploading ? 'hidden' : 'visible' }}>
        <Typography>Перетащите файл сюда или нажмите для выбора файла,</Typography>
        {children}
      </Stack>
    </DropzoneWrapper>
  );
};
