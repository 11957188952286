import { ECallCenterResult } from '@/components/features/complaints/CallCenterModal/utils';
import axios from 'axios';

import { ApiResponse } from '@/types/api';
import {
  CatalogResponse,
  ComplaintStage,
  ComplaintEnergyClassification,
  SetNotSecStateRequestDto,
  ComplaintGetReportRequestDto,
  ComplaintActStatusesResponse,
  ComplaintActStatusReasonsResponse,
  ComplaintActClassificationsResponse,
  ComplaintActResponseNullable,
  UpdateComplaintActRequest,
} from './ComplaintServiceDto';
import { IComplaintItem } from '@/components/features/complaints/types';
import { FixedValues } from '@/components/features/complaints/store';
import { attachAuthInterceptors } from '../Interceptors/interceptors';

function getHost() {
  const HOST = import.meta.env.VITE_COMPLAINT_SERVICE_BASE_URL;
  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к COMPLAINT SERVICE API не задан');
  } else return HOST as string;
}
const HOST = getHost();

export const ComplaintClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});
const MULTIPART_HEADER = { 'Content-Type': 'multipart/form-data' };

attachAuthInterceptors(ComplaintClient);

export async function getComplaintsByFilter(filter: FixedValues): ApiResponse<any> {
  const { size, page, ...restFilter } = filter;
  const url = `/complaint?size=${size}&page=${page}`;
  return ComplaintClient.post(url, restFilter);
}
export async function createComplaintEnergy(data: any): ApiResponse<any> {
  const url = `/complaint/energy`;
  return ComplaintClient.post(url, data, { headers: MULTIPART_HEADER });
}
export async function createComplaintOdpu(data: any): ApiResponse<any> {
  const url = `/complaint/odpu`;
  return ComplaintClient.post(url, data, { headers: MULTIPART_HEADER });
}
export async function getEnergeTypeList(): ApiResponse<any> {
  const url = `/complaint/energy/type`;
  return ComplaintClient.get(url);
}

export async function getCallCenterResultType(): ApiResponse<CatalogResponse<ECallCenterResult>> {
  const url = `/complaint/energy/call-center-result-type`;
  return ComplaintClient.get(url);
}
export async function getComaplaintStageTypeList(): ApiResponse<CatalogResponse<ComplaintStage>> {
  const url = `/complaint/stage`;
  return ComplaintClient.get(url);
}
export async function getEnergeClassificationList(): ApiResponse<
  CatalogResponse<ComplaintEnergyClassification>
> {
  const url = `/complaint/energy/classification`;
  return ComplaintClient.get(url);
}
export async function getComplaintTypeList(): ApiResponse<
  CatalogResponse<ComplaintEnergyClassification>
> {
  const url = `/complaint/type`;
  return ComplaintClient.get(url);
}

export async function editComplaintOdpu(data: any, id: number): ApiResponse<any> {
  const url = `/complaint/odpu/${id}?id=${id}`;
  return ComplaintClient.put(url, data);
}
export async function editComplaintEnergy(data: any, id: number): ApiResponse<any> {
  const url = `/complaint/energy/${id}`;
  return ComplaintClient.put(url, data);
}
export async function createComment(
  data: any,
  complaintId: number | null | undefined
): ApiResponse<any> {
  const url = `/complaint/${complaintId}/comment`;
  return ComplaintClient.post(url, data);
}
export async function getAllCommentsByComplaintId(
  complaintId: number | null | undefined
): ApiResponse<any> {
  const url = `/complaint/${complaintId}/comment`;
  return ComplaintClient.get(url);
}
export async function moveToCallCenter(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/call_centered`;
  return ComplaintClient.post(url, data);
}
export async function returnComplaint(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/returned`;
  return ComplaintClient.post(url, data);
}

export async function setNotSecState(
  complaintId: number | null | undefined,
  data: SetNotSecStateRequestDto
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/not_sec`;
  return ComplaintClient.post(url, data);
}

export async function returnToModeration(
  complaintId: number | null | undefined
): ApiResponse<string> {
  const url = `/complaint/energy/${complaintId}/state/moderated`;
  return ComplaintClient.post(url, {});
}

export async function transferToResponsibleEnergy(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/transferred_to_responsible`;
  return ComplaintClient.post(url, data);
}
export async function returnComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/odpu/${complaintId}/state/returned`;
  return ComplaintClient.post(url, data);
}
export async function returnComplaintOdpuBackToModerator(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/odpu/${complaintId}/state/moderated`;
  return ComplaintClient.post(url, data);
}
export async function duplicatedComplaint(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/duplicated`;
  return ComplaintClient.post(url, data);
}
export async function duplicatedComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/odpu/${complaintId}/state/duplicated`;
  return ComplaintClient.post(url, data);
}
export async function compliteComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/odpu/${complaintId}/state/completed`;
  return ComplaintClient.post(url, data);
}
export async function compliteComplaintEnergy(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/completed`;
  return ComplaintClient.post(url, data);
}

export async function moveAfterResponsibleComplaintEnergy(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/moderated_after_responsible`;
  return ComplaintClient.post(url, data);
}
export async function inProgressComplaint(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/in_progress`;
  return ComplaintClient.post(url, data);
}
export async function inProgressComplaintOdpu(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/odpu/${complaintId}/state/in_progress`;
  return ComplaintClient.post(url, data);
}
export async function deleteFileFromComplaint(
  complaintId: number | null | undefined,
  complaintType: string | undefined,
  fileId: string
): ApiResponse<any> {
  const url = `/complaint/${complaintType}/${complaintId}/files/${fileId}`;
  return ComplaintClient.delete(url);
}
export async function updateFilesInComplaint(
  complaintId: number | null | undefined,
  data: any,
  complaintType: string
): ApiResponse<any> {
  const url = `/complaint/${complaintType}/${complaintId}/files`;
  return ComplaintClient.post(url, data, { headers: MULTIPART_HEADER });
}

export async function energyAfterCallCenter(
  complaintId: number | null | undefined,
  data: any
): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}/state/moderated_after_cc`;
  return ComplaintClient.post(url, data);
}
export async function getEnergyComplaint(complaintId: number | null | undefined): ApiResponse<any> {
  const url = `/complaint/energy/${complaintId}`;
  return ComplaintClient.get(url);
}
export async function getOdpuComplaint(complaintId: number | null | undefined): ApiResponse<any> {
  const url = `/complaint/odpu/${complaintId}`;
  return ComplaintClient.get(url);
}
export async function downloadComplaintImage(fileId: number | null | string): ApiResponse<any> {
  const url = `/complaint/energy/files/${fileId}`;
  return ComplaintClient.get(url, { responseType: 'blob' });
}
export async function getCompalintWorkTypeList(): ApiResponse<any> {
  const url = `/complaint/energy/work-type`;
  return ComplaintClient.get(url);
}

export async function getReportCommonDay(data: ComplaintGetReportRequestDto): ApiResponse<any> {
  const url = `/report/common-daily`;
  return ComplaintClient.post(url, data, {
    responseType: 'blob',
  });
}
export async function getReportWeeklyDay(): ApiResponse<any> {
  const url = `/report/meeting-weekly`;
  return ComplaintClient.post(
    url,
    {},
    {
      responseType: 'blob',
    }
  );
}

export async function complaintMissedCall(
  complaintId: number | null | undefined
): ApiResponse<number> {
  const url = `/complaint/energy/${complaintId}/missed-call`;
  return ComplaintClient.post(url);
}
export async function changeResponsibleApi(
  complaintId: number | null | undefined,
  responsibleId: string | null | undefined
): ApiResponse<IComplaintItem> {
  const url = `/complaint/energy/${complaintId}/responsible-id/${responsibleId}`;
  return ComplaintClient.put(url);
}
export async function getComaplaintSorceList(): ApiResponse<CatalogResponse<ComplaintStage>> {
  const url = `/complaint/energy/source`;
  return ComplaintClient.get(url);
}
export async function dowmloadCompalintsByFilterParams(
  params: FixedValues
): ApiResponse<CatalogResponse<ComplaintStage>> {
  const url = `/report/complaints`;
  return ComplaintClient.post(url, params, {
    responseType: 'blob',
  });
}
export async function getComplaintActStatuses(): ApiResponse<ComplaintActStatusesResponse> {
  const url = `/complaint/act/statuses`;
  return ComplaintClient.get(url);
}
export async function getComplaintActStatusReasons(): ApiResponse<ComplaintActStatusReasonsResponse> {
  const url = `/complaint/act/status-reasons`;
  return ComplaintClient.get(url);
}
export async function getComplaintActClassifications(): ApiResponse<ComplaintActClassificationsResponse> {
  const url = `/complaint/act/classifications`;
  return ComplaintClient.get(url);
}
export async function getComplaintAct(
  complaintId: number
): ApiResponse<ComplaintActResponseNullable> {
  const url = `/complaint/${complaintId}/act`;
  return ComplaintClient.get(url);
}
export async function updateComplaintAct(
  complaintId: number,
  body: UpdateComplaintActRequest
): ApiResponse<ComplaintActResponseNullable> {
  const url = `/complaint/${complaintId}/act`;
  return ComplaintClient.put(url, body);
}
export async function getAllowComplaintActStatuses(
  actStatus: string
): ApiResponse<ComplaintActStatusesResponse> {
  const url = `/complaint/act/allowable-statuses?status=${actStatus}`;
  return ComplaintClient.get(url);
}
