import { createStore } from '@/utils/createStore';

export interface SupplyActiveRowState {
  activeRowIndex: number | null;
  setActiveRowIndex: (activeRowIndex: number | null) => void;
}

const initialState = {
  activeRowIndex: null,
};

export const useSupplyActiveRow = createStore<SupplyActiveRowState>(
  (set, get) => ({
    ...initialState,

    setActiveRowIndex: (activeRowIndex) => set(() => ({ activeRowIndex })),
  }),
  'SupplyActiveRow'
);

export default useSupplyActiveRow;
