import { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FileUploadModal from '../ImportUploader/FileUploadModal';
import { ImportModalWrapper } from '../ImportMainScreen/ImportMainScreen.styled';

const AddFileButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  return (
    <ImportModalWrapper>
      <Button
        variant='text'
        onClick={handleOpenModal}
        startIcon={<AddIcon />}
      >
        импорт новых файлов
      </Button>
      <FileUploadModal
        open={modalOpen}
        onClose={handleCloseModal}
      />
    </ImportModalWrapper>
  );
};

export default AddFileButton;
