import axios from 'axios';
import {
  CatalogContractResponse,
  ChartBarDataResponse,
  DynamicStatisticResponse,
  StatisticSummaryResponse,
} from './dto/response';
import {
  ChartBarDataRequest,
  DynamicStatisticRequest,
  StatistiSummaryRequest,
} from './dto/request';
import { ApiResponse } from '@/types/api';
import { appendArrayParam } from '@/utils/heplers';
import { attachAuthInterceptors } from '@/services/Interceptors/interceptors';

function getHost() {
  const HOST = import.meta.env.VITE_BUDGET_SERVICE_BASE_URL;

  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к InvestStatisticService API не задан');
  } else return HOST as string;
}

const HOST = getHost();

export const InvestStatisticClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});
attachAuthInterceptors(InvestStatisticClient);

export const GetStatistiSummary = ({
  contractIds,
  energyCompanyId,
  countAsMoney,
}: StatistiSummaryRequest): ApiResponse<StatisticSummaryResponse[]> => {
  const params = new URLSearchParams({
    countAsMoney: String(countAsMoney),
  });

  appendArrayParam(params, 'contractIds', contractIds);
  appendArrayParam(params, 'energyCompanyId', energyCompanyId);

  const url = `/statistics/summary?${params.toString()}`;
  return InvestStatisticClient.get(url);
};

export const GetChartBarData = ({
  contractIds,
  energyCompanyId,
  equipmentType,
  groupByContract,
  countAsMoney,
}: ChartBarDataRequest): ApiResponse<ChartBarDataResponse[]> => {
  const params = new URLSearchParams({
    groupByContract: String(groupByContract),
    countAsMoney: String(countAsMoney),
    equipmentType,
  });

  appendArrayParam(params, 'contractIds', contractIds);
  appendArrayParam(params, 'energyCompanyId', energyCompanyId);

  const url = `/statistics/chart-data?${params.toString()}`;
  return InvestStatisticClient.get(url);
};

export const GetDynamicStatistic = ({
  contractIds,
  energyCompanyId,
  equipmentType,
  periodType,
  countAsMoney,
}: DynamicStatisticRequest): ApiResponse<DynamicStatisticResponse[]> => {
  const params = new URLSearchParams({
    ...(energyCompanyId && { energyCompanyId }),
    equipmentType,
    ...(periodType && { periodType: periodType.toUpperCase() }),
    countAsMoney: String(countAsMoney),
  });

  appendArrayParam(params, 'contractIds', contractIds);

  const url = `/statistics/dynamic-chart-data?${params.toString()}`;
  return InvestStatisticClient.get(url);
};

export const GetCatalogContracts = (): ApiResponse<CatalogContractResponse[]> => {
  return InvestStatisticClient.get(`/catalog/contract`);
};

export const GetCatalogEnergyCompany = (): ApiResponse<string[]> => {
  return InvestStatisticClient.get(`/catalog/energy-company`);
};
