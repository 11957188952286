import useUserName from '@/hooks/useUserName';
import { Popover, Stack, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ExitButton, IconButtonCustom, ExitUserNameText } from './ExitActionButton.styled';
import { color } from '@/styles/mixins';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { logOutUser } from '@/app/auth/AuthProvider';

const ExitActionButton = () => {
  const userName = useUserName();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack sx={{ marginLeft: '4px' }}>
      <Tooltip title='Выйти'>
        <IconButtonCustom onClick={handleClick}>
          <PersonOutlineIcon htmlColor={color('grayNav')} />
        </IconButtonCustom>
      </Tooltip>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: '12px' }}
      >
        <Stack
          p={2}
          gap={1}
        >
          {!!userName && <ExitUserNameText>{userName}</ExitUserNameText>}
          <ExitButton onClick={() => logOutUser()}>Выйти</ExitButton>
        </Stack>
      </Popover>
    </Stack>
  );
};

export default ExitActionButton;
