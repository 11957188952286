import { ApiResponse } from '@/types/api';
import { PillarMapResponse } from '@/services/PillarMapService/dto/PillarMapDtoResponse';
import { YodaRestClient } from '../YodaRestService/YodaRestService';

export async function getPillarGeoPoints(params: {
  filter?: any;
  polygon?: string;
}): ApiResponse<PillarMapResponse[]> {
  const url = `/rest/task-browser/geo/search`;
  return YodaRestClient.post(url, { ...params, limit: 500 });
}
