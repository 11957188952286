import { useQuery } from 'react-query';
import { GetCallingMapOperator } from '@/services/YodaRestService/YodaRestService';
import { KEYS } from '@/hooks/useQuery/Keys';
import { syncErrorCatch, useActionLog } from '@/hooks/ActionLogHook';
import { initWebRTC } from '@/components/maps/callingMap/utils/vats';
import { useCallingMapState } from '@/components/maps/callingMap/store';

export function useCallingMapOperator() {
  const { fetchCatch } = useActionLog();
  const setIsAuthorized = useCallingMapState((state) => state.setIsAuthorized);
  useQuery({
    queryKey: KEYS.callingMapOperator,
    staleTime: Infinity,
    retry: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: GetCallingMapOperator,
    onError: (error) => fetchCatch(error),
    onSuccess: ({ data }) => {
      const megafonCreds = data['Мегафон'];
      if (!megafonCreds) return;

      initWebRTC(
        megafonCreds?.login,
        megafonCreds?.password,
        () => setIsAuthorized(true),
        () => syncErrorCatch('Ошибка авторизации оператора'),
        () => syncErrorCatch('Ошибка подключения к вирутальной АТС')
      );
    },
  });
}
