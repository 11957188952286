import axios from 'axios';

import { yodaJwt } from '@/app/auth/AuthProvider';
import { ApiResponse } from '@/types/api';

import { ImportLinesResponse, ImportResponse, ImportTypes } from './ImportServiceDto';
import { attachAuthInterceptors } from '../Interceptors/interceptors';

function getHost() {
  const HOST = import.meta.env.VITE_IMPORT_SERVICE_BASE_URL;
  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к IMPORT SERVICE API не задан');
  } else return HOST as string;
}
export const getYodaJWTLocal = () => localStorage.getItem(yodaJwt);
const HOST = getHost();
export const SAVE_COMPLAINT_JWT_TOKEN = 'SAVE_COMPLAINT_JWT_TOKEN';

export const ImportClient = axios.create({
  baseURL: HOST,
  headers: {
    'Content-Type': 'application/json',
  },
});
attachAuthInterceptors(ImportClient);

export async function getAllImportsTypes(): ApiResponse<ImportTypes> {
  const url = `/v1/catalog/import-types`;
  return ImportClient.get(url);
}
export async function getAllTableDataMain(filter: any): ApiResponse<ImportResponse> {
  const { size, page, params } = filter;
  const url = `/v1/import/files?size=${size}&page=${page}`;
  return ImportClient.post(url, params);
}
export async function getImportLinesByImportId(filter: any): ApiResponse<ImportLinesResponse> {
  const { importId, params, size, page } = filter;
  const isNeedPagiantion = size || page;
  const search = isNeedPagiantion ? `?size${size}&page=${page}` : '';
  const url = `/v1/import/files/${importId}/lines${search}`;
  return ImportClient.post(url, params);
}
export async function deleteImportLinesByImportId(filter: any): ApiResponse<ImportLinesResponse> {
  const { importId, lineId } = filter;
  const url = `/v1/import/files/${importId}/lines/${lineId}`;
  return ImportClient.delete(url);
}

export async function updateImportLinesByImportId(filter: any): ApiResponse<ImportLinesResponse> {
  const { importId, lineId, params } = filter;
  const url = `/v1/import/files/${importId}/lines/${lineId}`;
  return ImportClient.put(url, params);
}

export async function generateFileUrl(params: {
  fileName: string;
  importType: string;
}): ApiResponse<{ url: string; fileKey: string }> {
  const url = `/v1/import/generate-file-url`;
  return ImportClient.post(url, params);
}

export async function createImportEntry(params: {
  fileName: string;
  importType: string;
  username: string;
}): ApiResponse<void> {
  const url = `/v1/import`;
  return ImportClient.post(url, params);
}
