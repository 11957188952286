import { useState } from 'react';
import { Collapse } from '@mui/material';
import { Toast } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { saveYodaToken } from '@/app/auth/AuthProvider';
import {
  LoginPageFieldsWrapper,
  LoginPageButton,
  LoginPageTitle,
  LoginPageTextField,
  LoginPageText,
} from './LoginPage.styled';
import { LOGIN_VALIDATION, PASSWORD_VALIDATION } from './utils';
import { keyCloakAuth } from '@/services/AuthService/AuthService';

type UserCredit = { login: string; pwd: string };

export const LoginPage = () => {
  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const hideAlert = () => setAlertMessage('');
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UserCredit>();
  const navigate = useNavigate();
  const location: any = useLocation();
  // смотрим, есть ли сохранённый путь в localStorage
  const from = localStorage.getItem('redirectPath') || location.state?.from?.pathname || '/';

  watch(() => hideAlert());

  const onSubmit = (user: UserCredit) => {
    setLoading(true);
    keyCloakAuth(user.login, user.pwd)
      .then(({ data }) => {
        saveYodaToken(data);
        // очищаем сохранённый путь после логина
        localStorage.removeItem('redirectPath');
        navigate(from, { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response?.status === 401)
          setAlertMessage('Технческая ошибка обратитесь в тех.поддержку код ответа 401');
        else if (error.response?.status === 400) setAlertMessage('Неверный логин или пароль');
        else setAlertMessage('Сервер не доступен попробуйте через 5 минут ');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LoginPageFieldsWrapper>
          <LoginPageTitle>YODA</LoginPageTitle>
          <LoginPageText>Авторизация</LoginPageText>
          <LoginPageTextField
            {...register('login', LOGIN_VALIDATION)}
            id='login'
            label='Логин'
            error={!!errors.login?.message}
            fullWidth
            helperText={
              <Collapse in={!!errors.login?.message}>
                {errors.login?.message}
                {'\u200B'}
              </Collapse>
            }
          />

          <LoginPageTextField
            {...register('pwd', PASSWORD_VALIDATION)}
            id='passwerd'
            label='Пароль'
            type='password'
            error={!!errors.pwd?.message}
            fullWidth
            helperText={
              <Collapse in={!!errors.pwd?.message}>
                {errors.pwd?.message}
                {'\u200B'}
              </Collapse>
            }
          />

          <Toast show={alertMessage.length > 1}>
            <Alert
              variant='outlined'
              severity='error'
              onClick={hideAlert}
            >
              {alertMessage}
            </Alert>
          </Toast>
          <LoginPageButton
            type='submit'
            variant='contained'
            fullWidth
            loading={loading}
          >
            ВОЙТИ
          </LoginPageButton>
        </LoginPageFieldsWrapper>
      </form>
    </>
  );
};
