import {
  ActDateHistoryResponse,
  Siblings,
  TaskFilterParam,
  WarrantyWorkType,
} from '@/dto/taskmap/Dto';
import {
  IsuTaskInCheckDto,
  PillarRequest,
  ReturnTaskBackDto,
  SendAllCipher,
  TaskRequest,
  TaskResponse,
} from '@/dto/taskmap/task/TasksDto';
import {
  DeliveryPointRequest,
  DeliveryPointResponse,
  ExistingEquipmentData,
  ExtraConnectionNewRequest,
  ExtraConnectionRequest,
  ExtraConnectionResponse,
  PillarResponse,
  PpoCableResponse,
  PpoInputExpendabResponse,
  SubscriberResponse,
  SubscriberUsage,
  TaskComment,
  TaskResultType,
  UpDateEquipmentData,
} from '@/dto/taskmap/task/TaskResultDto';
import { ApiResponse } from '@/types/api';
import { CONTENT_TYPE, YodaRestClient } from '../YodaRestService/YodaRestService';

export async function getisuTaskInCheck(filter: TaskFilterParam): ApiResponse<IsuTaskInCheckDto[]> {
  const url = `/rest/task/status/isu/all-in-check`;
  return YodaRestClient.post(url, filter);
}

export async function completeIsuTaskInCheck(
  isuTaskDto: IsuTaskInCheckDto
): ApiResponse<IsuTaskInCheckDto> {
  const url = `/rest/task/status/isu/complete-in-check`;
  return YodaRestClient.post(url, isuTaskDto);
}

export async function findTaskById(id: string): ApiResponse<TaskResponse> {
  const url = `/rest/task/${id}`;
  return YodaRestClient.get(url);
}

export async function searchDuplicatesById(taskId: string): ApiResponse<string[]> {
  const url = `/rest/file/${taskId}/search-duplicates`;
  return YodaRestClient.get(url);
}

export async function findAllCatalogByType(type: string): ApiResponse<Map<string, string>> {
  const url = `/rest/catalog/${type}`;
  return YodaRestClient.get(url);
}

export async function updateTask(taskId: string, taskRequest: TaskRequest) {
  const url = `/rest/task/${taskId}`;
  return YodaRestClient.put(url, taskRequest);
}

export async function checkTask(taskId: string) {
  const url = `/rest/task/${taskId}/reopen`;
  return YodaRestClient.put(url, {});
}

export async function toCloseTask(taskId: string) {
  const url = `/rest/task/${taskId}/close`;
  return YodaRestClient.post(url, null);
}

export async function toChangeTaskType(taskId: string, taskType: string, message: string) {
  const url = `/rest/task/${taskId}/${taskType}`;
  return YodaRestClient.post(url, { value: message });
}

export async function toCloseTaskIgnoreYunga(taskId: string) {
  const url = `/rest/task/${taskId}/close-ignore-yunga`;
  return YodaRestClient.post(url, null);
}

export async function getLastValidYungaAnalyzeLog(taskId: string) {
  const url = `/rest/task/${taskId}/last-valid-yunga-analyze-log`;
  return YodaRestClient.get(url);
}

export async function toReturnTask(taskId: string, taskRequest: TaskRequest) {
  const url = `/rest/task/${taskId}/return`;
  return YodaRestClient.put(url, taskRequest);
}

export async function toReturnNotRelevantTask(taskId: string, taskRequest: TaskRequest) {
  const url = `/rest/task/${taskId}/return-not-relevant`;
  return YodaRestClient.put(url, taskRequest);
}

export async function findTransStationByTaskId(taskId: string) {
  const url = `/rest/transstation/${taskId}`;
  return YodaRestClient.get(url);
}
export async function saveTransStationByTaskId(taskId: string, transData: any) {
  const url = `/rest/transstation/${taskId}`;
  return YodaRestClient.put(url, transData);
}

export async function createTaskByDeliveryPointId(
  deliveryPointId: string
): ApiResponse<DeliveryPointResponse> {
  const url = `/rest/task/delivery-point/${deliveryPointId}`;
  return YodaRestClient.post(url, {});
}

export async function findDeliveryPointById(
  deliveryPointId: string
): ApiResponse<DeliveryPointResponse> {
  const url = `/rest/delivery-point/${deliveryPointId}`;
  return YodaRestClient.get(url);
}

export async function saveDeliveryPointById(
  deliveryPoint: DeliveryPointRequest,
  deliveryPointId: string
): ApiResponse<DeliveryPointResponse> {
  const url = `/rest/delivery-point/${deliveryPointId}`;
  return YodaRestClient.put(url, deliveryPoint);
}

export async function deleteDeliveryPointById(
  deliveryPointId: string
): ApiResponse<DeliveryPointResponse> {
  const url = `/rest/delivery-point/${deliveryPointId}`;
  return YodaRestClient.delete(url);
}
export async function searchSubscriberByTerm(
  serchTerm: string,
  offset = 0,
  limit = 100
): ApiResponse<SubscriberResponse[]> {
  const url = `/rest/subscriber/find-all-by-param?lookupRow=${serchTerm}&offset=${offset}&limit=${limit}`;
  return YodaRestClient.get(url);
}

export async function replaceSubscriber(
  subscriber: SubscriberResponse,
  deliveryPointId: string
): ApiResponse<DeliveryPointResponse> {
  const url = `/rest/delivery-point/${deliveryPointId}/${subscriber.id}`;
  return YodaRestClient.patch(url, subscriber);
}
export async function isSubscriberUsed(subscriberId: string): ApiResponse<SubscriberUsage[]> {
  const url = `/rest/subscriber/${subscriberId}/usage`;
  return YodaRestClient.get(url);
}

export async function findAllResultByTaskIdAndType(
  taskId: string,
  type: TaskResultType
): ApiResponse<any> {
  const url = `/rest/task/${taskId}/${type}`;
  return YodaRestClient.get(url);
}
export async function findODPUHouseInfo(taskId: string) {
  const url = `/rest/odpu-house-info/${taskId}
`;
  return YodaRestClient.get(url);
}
export async function createODPUHouseInfoApi(taskId: string, body: any) {
  const url = `/rest/odpu-house-info`;
  return YodaRestClient.post(url, { taskId, ...body });
}
export async function updateODPUHouseInfoApi(taskId: string, body: any) {
  const url = `/rest/odpu-house-info`;
  return YodaRestClient.put(url, { taskId, ...body });
}

export async function findAllTaskCommentById(taskId: string): ApiResponse<TaskComment[]> {
  const url = `/rest/task/${taskId}/comment`;
  return YodaRestClient.get(url);
}

export async function saveTaskComment(taskId: string, message: string) {
  const url = `/rest/task/${taskId}/comment`;
  return YodaRestClient.post(url, message, { headers: CONTENT_TYPE.PLAIN });
}

export async function deleteTaskComment(taskId: string, commentId: string) {
  const url = `/rest/task/${taskId}/comment/${commentId}`;
  return YodaRestClient.delete(url);
}

export async function savePpoOdpu(taskId: string, data: any) {
  const url = `/rest/task/ppo/${taskId}`;
  return YodaRestClient.put(url, data);
}

export async function editPpoVruCableList(data: PpoCableResponse[]) {
  const url = `/rest/cable`;
  return YodaRestClient.put(url, data);
}

export async function deletePpoVruCable(cableId: string) {
  const url = `/rest/cable/${cableId}`;
  return YodaRestClient.delete(url);
}

export async function addPpoVruCable(vruInputId: string): ApiResponse<PpoCableResponse> {
  const url = `/rest/cable/ppo-odpu-input/${vruInputId}`;
  return YodaRestClient.post(url, {});
}

export async function getWarranty(
  taskId: string,
  vruInputId: string
): ApiResponse<WarrantyWorkType[]> {
  const url = `/rest/warranty-work/task/${taskId}/vru-input/${vruInputId}`;
  return YodaRestClient.get(url);
}

export async function saveLockerPpoOdpu(taskId: string, lockerId: string, data: any) {
  const url = `/rest/task/ppo/${taskId}/ppo-locker/${lockerId}`;
  return YodaRestClient.put(url, data);
}
export async function createLockerPpoOdpu(data: any) {
  const url = `/rest/ppo-odpu-locker`;
  return YodaRestClient.post(url, data);
}
export async function deleteLockerPpoOdpu(lockerId: string) {
  const url = `/rest/task/ppo/ppo-locker/${lockerId}`;
  return YodaRestClient.delete(url);
}

export async function deleteODPUVru(vruId: string) {
  const url = `/rest/odpu/vru/${vruId}`;
  return YodaRestClient.delete(url);
}
export async function deleteODPUInput(inputId: string) {
  const url = `/rest/odpu/vru-input/${inputId}`;
  return YodaRestClient.delete(url);
}

export async function deletePPOODPUVru(vruId: string) {
  const url = `/rest/task/ppo/ppo-odpu-vru/${vruId}`;
  return YodaRestClient.delete(url);
}
export async function deletePPOODPUInput(inputId: string) {
  const url = `/rest/task/ppo/ppo-odpu-input/${inputId}`;
  return YodaRestClient.delete(url);
}

export async function updateODPUInput(inputId: string, data: any) {
  const url = `/rest/odpu/vru-input/${inputId}`;
  return YodaRestClient.post(url, data);
}

export async function addTKO(data: any, taskId: string | undefined) {
  const url = `/rest/task/${taskId}/tech-inspection`;
  return YodaRestClient.post(url, data);
}

export async function updateTko(id: string, data: any) {
  const url = `/rest/tech-inspection/${id}`;
  return YodaRestClient.put(url, data);
}

export async function getCatalogTKOStatusByContractId(id: string) {
  const url = `/rest/catalog/tko-status/${id}`;
  return YodaRestClient.get(url);
}
export async function deleteTKOById(id: string) {
  const url = `/rest/tech-inspection/${id}`;
  return YodaRestClient.delete(url);
}

export async function createPillar(data: PillarRequest): ApiResponse<PillarRequest> {
  const url = `/rest/task/fz522-or-tech-connection`;
  return YodaRestClient.post(url, data);
}

export async function createConfirm(taskId: string) {
  const url = `/rest/task/${taskId}/confirm-creation`;
  return YodaRestClient.post(url, {});
}
export async function getSingleTask(taskId: string) {
  const url = `/rest/task/${taskId}`;
  return YodaRestClient.get(url);
}

export async function updatePpoState(taskId: string, data: any) {
  const url = `/rest/task/ppo/update-state/${taskId}`;
  return YodaRestClient.post(url, data);
}
export async function nextPpoState(taskId: string) {
  const url = `/rest/task/ppo/next-odpu-stage/${taskId}`;
  return YodaRestClient.put(url, {});
}
export async function getNextPpoState(taskId: string) {
  const url = `/rest/task/ppo/next-odpu-stage/${taskId}`;
  return YodaRestClient.get(url);
}
export async function returnTaskBack(data: ReturnTaskBackDto) {
  const url = `/rest/task/return`;
  return YodaRestClient.put(url, data);
}
export async function sendAllCipher(data: SendAllCipher) {
  const url = `/rest/task/cipher`;
  return YodaRestClient.post(url, data);
}
export async function findKidsAndParents(taskId?: string): ApiResponse<Siblings> {
  const url = `/rest/task/${taskId}/connection`;
  return YodaRestClient.get(url);
}

export async function getActDateHistory(taskId?: string): ApiResponse<ActDateHistoryResponse[]> {
  const url = `/rest/task/${taskId}/act-date-history`;
  return YodaRestClient.get(url);
}

export async function editVruExpendableToolsList(data: any) {
  const url = `/rest/odpu-input-expendable`;
  return YodaRestClient.put(url, data);
}
export async function addVruExpendableTool(data: {
  ppoInputId: string;
}): ApiResponse<PpoInputExpendabResponse> {
  const url = `/rest/odpu-input-expendable`;
  return YodaRestClient.post(url, data);
}

export async function addVruExternalConnection(
  ppoInputId: string,
  data: ExtraConnectionNewRequest
): ApiResponse<ExtraConnectionResponse> {
  const url = `/rest/extra-connection/ppo-input/${ppoInputId}`;
  return YodaRestClient.post(url, data);
}
export async function deleteVruExternalConnection(externalConnectionId: string): ApiResponse<any> {
  const url = `/rest/extra-connection/${externalConnectionId}`;
  return YodaRestClient.delete(url);
}
export async function editVruExternalConnection(data: ExtraConnectionRequest): ApiResponse<any> {
  const url = `/rest/extra-connection/${data.id}`;
  return YodaRestClient.put(url, data);
}

export async function deletePpoVruExpendableTool(ExpendableToolId: string) {
  const url = `/rest/odpu-input-expendable/${ExpendableToolId}`;
  return YodaRestClient.delete(url);
}

export async function pillarDeliveryPointToSmr(transStationId: string) {
  const url = `/rest/task/trans-station/${transStationId}`;
  return YodaRestClient.post(url, {});
}

export async function sendPpoToIsur(id: string): ApiResponse<PpoInputExpendabResponse> {
  const url = `/rest/transstation/${id}/send-to-isur`;
  return YodaRestClient.post(url, {});
}

export async function createTask(
  id: string,
  data: {
    contractId: string;
    regionalElectricNetworkId: string;
    transStationName: string;
    contractorId: string;
    type?: string;
    longitude?: number;
    latitude?: number;
    networkEquipmentTypeID?: string;
  }
): ApiResponse<PpoInputExpendabResponse> {
  const url = `/rest/transstation/${id}/create-task`;
  return YodaRestClient.post(url, data);
}

export async function findOdpuVruInput(searchTerm: string): ApiResponse<any> {
  const url = `/rest/odpu/vru-input/${searchTerm}/address`;
  return YodaRestClient.get(url);
}

export async function findPillarById(pillarId: string): ApiResponse<PillarResponse> {
  const url = `/rest/pillar/${pillarId}`;
  return YodaRestClient.get(url);
}

export async function savePillarById(
  pillarId: string,
  data: PillarResponse
): ApiResponse<PillarResponse> {
  const url = `/rest/pillar/${pillarId}`;
  return YodaRestClient.put(url, data);
}

export async function getTKOStausByIdTask(contractId: string): ApiResponse<PillarResponse> {
  const url = `/rest/catalog/installation-call-tko-status/${contractId}`;
  return YodaRestClient.get(url);
}

export async function getPresentEqInfo(id: string): ApiResponse<ExistingEquipmentData> {
  const url = `/rest/present-eq-info/task/${id}`;
  return YodaRestClient.get(url);
}

export async function updatePresentEqInfo(
  id: string,
  data: UpDateEquipmentData,
  existingEquipmentUrl: string
): ApiResponse<ExistingEquipmentData> {
  const url = `/rest/${existingEquipmentUrl}/${id}/present-eq-info`;
  return YodaRestClient.put(url, data);
}

export async function getPresentEqInfoVru(id: string): ApiResponse<ExistingEquipmentData> {
  const url = `/rest/present-eq-info/vru-input/${id}`;
  return YodaRestClient.get(url);
}
