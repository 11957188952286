import {
  MailNotificationsFileResponse,
  MailNotificationsFolderResponse,
} from '@/services/MailNotificationsService/dto/MailNotificationsResponseDto';
import { ApiResponse } from '@/types/api';
import { YodaRestClient } from '../YodaRestService/YodaRestService';

const MULTIPART_HEADERS = {
  'Content-Type': 'multipart/form-data',
  Accept: '*/*',
};
export async function GetMailNotificationsFolders(): ApiResponse<
  MailNotificationsFolderResponse[]
> {
  const url = `/rest/file/postal-departure`;
  return YodaRestClient.get(url);
}

export async function GetMailNotificationsFiles(
  fileId: string
): ApiResponse<MailNotificationsFileResponse[]> {
  const url = `/rest/file/postal-departure/${fileId}/file`;
  return YodaRestClient.get(url);
}

export async function GetADPUFolders(): ApiResponse<MailNotificationsFolderResponse[]> {
  const url = `/rest/file/adpu`;
  return YodaRestClient.get(url);
}

export async function GetADPUFiles(fileId: string): ApiResponse<MailNotificationsFileResponse[]> {
  const url = `/rest/file/adpu/${fileId}/file`;
  return YodaRestClient.get(url);
}

export async function UploadZipFile(file: File): ApiResponse<any> {
  const url = `/rest/file/postal-departure`;
  const formData = new FormData();
  formData.append('zip', file);
  return YodaRestClient.post(url, formData, { headers: MULTIPART_HEADERS });
}

export async function UploadZipFileById(id: string, file: File): ApiResponse<any> {
  const url = `/rest/file/postal-departure/${id}`;
  const formData = new FormData();
  formData.append('zip', file);
  return YodaRestClient.post(url, formData, { headers: MULTIPART_HEADERS });
}

export async function UploadZipFileADPU(file: File): ApiResponse<any> {
  const url = `/rest/file/adpu`;
  const formData = new FormData();
  formData.append('zip', file);
  return YodaRestClient.post(url, formData, { headers: MULTIPART_HEADERS });
}

export async function UploadZipFileADPUById(id: string, file: File): ApiResponse<any> {
  const url = `/rest/file/adpu/${id}`;
  const formData = new FormData();
  formData.append('zip', file);
  return YodaRestClient.post(url, formData, { headers: MULTIPART_HEADERS });
}
