import { useEffect, useState } from 'react';

/**
 * Хук для подписки на определенный ключ в localStorage.
 *
 * @param key - ключ по которому происходит запись/чтение данных в localStorage.
 * @returns значение по ключу key, либо null, если такого ключа не существует.
 */
export const useLocalStorage = (key: string): string | null => {
  const [value, setValue] = useState(() => localStorage.getItem(key));

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [key]);

  return value;
};
