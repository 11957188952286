import {
  EmailsResponse,
  FindAllRegistryByOdpuIdItemResponse,
  FindAllRegistryByYearItemResponse,
  FindAllRegistryEmailByIdResponse,
  FindAllRegistryTasksByParamsResponse,
  GetRegistryEnforseTableResponse,
  FindManagementCompanyByIdResponse,
} from './dto/RegistryServiceResponseDto';
import { ApiResponse } from '@/types/api';
import {
  AddRegistryItemsRequest,
  FindAllRegistryTasksByParamsRequest,
  GetRegistryEnforseTableRequest,
  SendRegistryRequest,
} from '@/services/RegistryService/dto/RegistryServiceRequestDto';
import { YodaRestClient, CONTENT_TYPE } from '../YodaRestService/YodaRestService';

export async function findAllRegistryByYear(
  year: number
): ApiResponse<FindAllRegistryByYearItemResponse[]> {
  const url = `/rest/odpu-schedule/filter?year=${year}`;
  return YodaRestClient.get(url);
}

export async function findRegistryById(id: string): ApiResponse<FindAllRegistryByYearItemResponse> {
  const url = `/rest/odpu-schedule/filter?id=${id}`;
  return YodaRestClient.get(url);
}

export async function createNewRegistry(data: {
  month: number;
  year: number;
}): ApiResponse<FindAllRegistryByYearItemResponse> {
  const url = `/rest/odpu-schedule/`;
  return YodaRestClient.post(url, data);
}

export async function findAllRegistryByOdpuId(
  id: string
): ApiResponse<FindAllRegistryByOdpuIdItemResponse[]> {
  const url = `/rest/odpu-schedule/${id}/items`;
  return YodaRestClient.get(url);
}

export async function findAllRegistryTasksByParams({
  type,
  adress,
  managementCompany,
}: FindAllRegistryTasksByParamsRequest): ApiResponse<FindAllRegistryTasksByParamsResponse[]> {
  const url = `/rest/task/schedule?&type=${type ? type : ''}&address=${
    adress ? adress : ''
  }&managementCompanyId=${managementCompany ? managementCompany : ''}`;
  return YodaRestClient.get(url);
}

export async function addRegistryItems({ id, data }: AddRegistryItemsRequest): ApiResponse<any> {
  const url = `/rest/odpu-schedule/${id}/items`;
  return YodaRestClient.post(url, data);
}

export async function sendRegistry({ id, data }: SendRegistryRequest): ApiResponse<any> {
  const url = `/rest/odpu-schedule/${id}/send`;
  return YodaRestClient.post(url, data);
}

export async function findAllRegistryEmailById(
  id: number | string
): ApiResponse<FindAllRegistryEmailByIdResponse[]> {
  const url = `/rest/odpu-schedule/${id}/emailing`;
  return YodaRestClient.get(url);
}

export async function findManagementCompanyById(
  id: number | string
): ApiResponse<FindManagementCompanyByIdResponse[]> {
  const url = `/rest/odpu-schedule/${id}/management-company`;
  return YodaRestClient.get(url);
}

export async function deleteRegistryItem(
  id: number | string,
  registryId: number | string
): ApiResponse<string> {
  const url = `/rest/odpu-schedule/${id}/item/${registryId}`;
  return YodaRestClient.delete(url);
}

export async function getEmails(): ApiResponse<EmailsResponse[]> {
  const url = `/rest/emailing-address`;
  return YodaRestClient.get(url);
}

export async function getRegistryEnforseTable({
  type,
  dateFrom,
  dateTo,
}: GetRegistryEnforseTableRequest): ApiResponse<GetRegistryEnforseTableResponse[]> {
  const url = `/rest/enforce-import/history?filterType=${type ? type : ''}&dateFrom=${
    dateFrom ? dateFrom : ''
  }&dateTo=${dateTo ? dateTo : ''}`;
  return YodaRestClient.get(url);
}

export async function get1RegistryEnforseTable(
  enforceImportId: string
): ApiResponse<GetRegistryEnforseTableResponse[]> {
  const url = `enforce-import/${enforceImportId}/load`;
  return YodaRestClient.get(url);
}

export async function getContractList(): ApiResponse<any> {
  const url = `/rest/enforce-import-contract/list`;
  return YodaRestClient.get(url);
}
export async function getContractListForAdd(): ApiResponse<any> {
  const url = `/rest/enforce-import-contract/addlist`;
  return YodaRestClient.get(url);
}
export async function addToContractList(id: string): ApiResponse<any> {
  const url = `/rest/enforce-import-contract/${id}/add`;
  return YodaRestClient.post(url, {});
}
export async function removeFromContractList(id: string): ApiResponse<any> {
  const url = `/rest/enforce-import-contract/${id}/remove`;
  return YodaRestClient.post(url, {});
}
export async function getReport(id: string, onlyTo: boolean): ApiResponse<unknown> {
  const url = `/rest/enforce-import/${id}/report${onlyTo ? '?onlyTo=true' : ''}`;
  return YodaRestClient.get(url, { headers: CONTENT_TYPE.EXL, responseType: 'blob' });
}
export async function getMissingReport(id: string): ApiResponse<any> {
  const url = `/rest/enforce-import/${id}/address/missing/report`;
  return YodaRestClient.get(url, { headers: CONTENT_TYPE.EXL, responseType: 'blob' });
}
export async function createRegistry(): ApiResponse<any> {
  const url = `/rest/enforce-import/check`;
  return YodaRestClient.post(url, {});
}
export async function changeStatus(id: string): ApiResponse<any> {
  const url = `/rest/enforce-import/${id}/load`;
  return YodaRestClient.post(url, {});
}
export async function getDateManual(date: {
  dateFrom: string;
  dateTo: string;
}): ApiResponse<string> {
  const url = `/rest/enforce-import/manual?dateFrom=${date.dateFrom}&dateTo=${date.dateTo}`;
  return YodaRestClient.post(url, null);
}
