import React, { useEffect, useState } from 'react';
import { BoxShadow } from '@/components/ui/BoxShadow';
import { MultiSelect } from '@/components/inputs/MultiSelectNew';
import { Grid } from '@mui/material';
import FilterButtons from '@/components/filter/FilterButtons/FilterButtons';
import { generateDetailedInfo } from '@/components/button/DetailedInfo/utils';
import { useImportStore } from '../store';

import { FilterConteinerMain } from './ImportBrowseFilter.styled';
import usePressEnterButton from '@/hooks/usePressEnterButton';
import { FILTERS_LABELS, IMPORT_SAVE_FILTER_KEY } from '../utils';
import { SelectFilterOptType } from '@/components/filter/MultySelectFilter';
import shallow from 'zustand/shallow';

const ImportBrowseFilter = () => {
  const { importTypes, selectedImportType, setSelectedImportType, fetchImportTypes, fetchImports } =
    useImportStore(
      (state) => ({
        importTypes: state.importTypes,
        selectedImportType: state.selectedImportType,
        setSelectedImportType: state.setSelectedImportType,
        fetchImportTypes: state.fetchImportTypes,
        fetchImports: state.fetchImports,
      }),
      shallow
    );

  const detailedInfo = [
    ...generateDetailedInfo([[selectedImportType] as unknown as string, FILTERS_LABELS.importType]),
  ];
  const onSubmitSearch = () => {
    Object.keys(selectedImportType).length && fetchImports();
  };
  const onClear = () => {
    setSelectedImportType({});
  };
  useEffect(() => {
    fetchImportTypes();
  }, []);

  const handleChange = (newValues: SelectFilterOptType) => setSelectedImportType(newValues);

  usePressEnterButton(onSubmitSearch);

  return (
    <BoxShadow>
      <FilterConteinerMain
        container
        spacing={1}
      >
        <Grid item>
          <MultiSelect
            label={FILTERS_LABELS.importType}
            options={importTypes}
            onChange={handleChange}
            value={selectedImportType}
            withoutBullAnswerReset
            onlyInListArgument
            isOnlySingleValue
            sx={{ minWidth: 200 }}
          />
        </Grid>
        <Grid item>
          <FilterButtons
            onSearchClick={onSubmitSearch}
            onCloseClick={onClear}
            info={detailedInfo}
            disable={!Object.keys(selectedImportType).length}
          />
        </Grid>
      </FilterConteinerMain>
    </BoxShadow>
  );
};

export default ImportBrowseFilter;
