import { TaskStatus } from '@/dto/taskmap/Dto';
import { TransStationResponse } from './TaskResultDto';

export type IsuTaskInCheckDto = {
  vldMsg?: string;
  taskStatus?: TaskStatus;
  id: string;
  number?: number;
  mcId?: string;
  actual?: boolean;
  newTariff?: number;
  newT1?: string;
  newT2?: string;
  newT3?: string;
  breakerTechDesicion?: string;
  newPUMac?: string;
  newPUNumber?: string;
  newPUType?: string;
  oldTariff?: number;
  exT1?: string;
  exT2?: string;
  exT3?: string;
  newPUStamp?: string;
  lastValidMsg?: string;
  changeStatus?: string;
};

export class TaskResponse {
  id!: string;
  adpuOrderId!: string | null;
  number?: number;
  contractResponse?: { contractTitle: string; id: string };
  coordinatesExecutorList!: { objectType: string; longitude: number; latitude: number }[];
  type?: string;
  addressTitle?: string;
  status?: string;
  priority?: string;
  contractorId?: string;
  executorId?: string;
  scheduledDate?: Date;
  createdOn?: Date;
  completionDate?: Date;
  createDate?: Date;
  editable!: boolean;
  errorMessage?: string;
  actDate?: Date;
  needCreateConfirmation?: boolean;
  callCenterComments?: TaskCollCenterCommentResponse[];
  taskInfo?: TaskInfoResponse | OdpuInfoResponse;
  registrationDate?: Date;
  objectType?: string | null;
}

export class TaskInfoResponse {
  consumer?: string;
  pyramidId?: string;
  wayPointComment?: string;
  wayPoint?: string;
  customerComment?: string;
  deliveryPoint?: string;
  consumerType?: string;
  accountNumber?: string;
  contractNumber?: string;
  subscriberNumber?: number;
  assignedWork?: string;
  bookNumber?: number;
  phoneNumber?: string;
  latitude?: number;
  longitude?: number;
  puType?: string;
  montagePlace?: string;
  phase?: string;
  tariff?: number;
  factoryNumberPu?: string;
  lastReadDate?: Date;
  regionalElectricNetworkId?: string;
  t0?: number;
  t1?: number;
  t2?: number;
  t3?: number;
  abonentsList?: Abonent[];
  appartment?: string | null;
  clientOfficeName?: string | null;
  schemeBYT?: string | null;
  customerID?: string | null;
  registrationNum?: string | null;
  originalFias?: string | null;
  territorialBranchName?: string | null;
  subscriberFIO?: string | null;
  tpName?: string | null;
  ttKoef?: string | null;
  subscriberObjName?: string | null;
  pointName?: string | null;
  originalAddress?: string;
  objectType?: string;
}

export class OdpuInfoResponse {
  subscriberName?: string;
  deliveryPoint?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  energyServiceCompany?: string;
  localBranch?: string;
  clientOffice?: string;
  agentExecutor?: string;
  agentEnergyServiceCompany?: string;
  transferredOn?: Date;
  agentNetworkCompany?: string;
  agentOwner?: string;
  agentOwnerPu?: string;
  abonentsList?: Abonent[];
  managementCompanyName?: string;
  legalAddressManagementCompany?: string;
  headOfManagementCompany?: string;
  telHeadOfManagementCompany?: string;
  odpuStage?: string;
  odpuManagementCompanyResponse?: OdpuManagementCompanyResponse;
  appartment?: string | null;
}

export class OdpuManagementCompanyResponse {
  id!: string;
  agentExecutor?: string;
  agentEnergyServiceCompany?: string;
  agentNetworkCompany?: string;
  agentOwner?: string;
  agentOwnerPu?: string;
  title?: string;
  legalAddress?: string;
  director?: string;
  phoneNumber?: string;
}

export class Abonent {
  id?: number;
  contactPhone?: string;
  fio?: string;
  taskInfoId?: string;
}

export class TaskRequest {
  executorId?: string;
  contractorId?: string;
  priority?: string;
  scheduledDate?: Date;
  completionDate?: Date;
  type?: string;
  taskInfoRequest?: TaskInfoRequest;
  odpuInfoRequest?: OdpuInfoRequest;
  pillars?: TransStationResponse[];
}

export class TaskInfoRequest {
  accountNumber?: string;
  subscriberNumber?: number;
  bookNumber?: number;
  phoneNumber?: string;
  latitude?: number | null;
  longitude?: number | null;
  montagePlace?: string;
  phase?: string;
  tariff?: number;
  factoryNumberPu?: string;
  t0?: number;
  t1?: number;
  t2?: number;
  t3?: number;
  abonentsList?: Abonent[];

  pillarTaskInfoRequest?: PillarTaskInfoRequest;
  registrationNum?: string;
  originalAddress?: string;
  customerId?: string;
}

export class OdpuInfoRequest {
  subscriberName?: string;
  deliveryPoint?: string;
  contactName?: string;
  contactPhoneNumber?: string;
  energyServiceCompany?: string;
  localBranch?: string;
  clientOffice?: string;
  agentExecutor?: string;
  agentEnergyServiceCompany?: string;
  transferredOn?: Date;
  agentNetworkCompany?: string;
  agentOwner?: string;
  agentOwnerPu?: string;
}

export interface PillarRequest {
  type: string;
  abonents?: Abonent[];
  taskInfoRequest: TaskInfoRequest;
}

export interface PillarTaskInfoRequest {
  techCondition7?: string;
  techCondition10?: string;
  techCondition11?: string;
}

type CommentCommon = {
  author?: string;
  createdOn?: Date;
  message?: string;
  value?: string;
  createdBy?: string;
  eventAt?: Date;
  isDeletable?: boolean;
  id?: string;
};

export type TaskCommentResponse = CommentCommon & {
  author: string;
  message: string;
  eventAt: Date;
};

export type TaskCollCenterCommentResponse = CommentCommon & {
  createdBy: string;
  createdOn: Date;
  value: string;
};

export interface ReturnTaskBackDto {
  taskIdList: string[];
  executorId: string;
  scheduledDate: number;
}
export interface SendAllCipher {
  taskIdList: string[];
  cipher: string;
}

export interface AssignContractRequestDto {
  taskIds: string[];
  contractId: string | null;
}

export enum ExistingEquipmentUrl {
  TASK = 'task',
  VRU_INPUT = 'vru-input',
}
