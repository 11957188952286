import { Autocomplete, AutocompleteProps } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@/components/fixUI/TextField';
import { BaseUI } from '@/types/ui';

interface AutocompleteValueProps
  extends Omit<
      AutocompleteProps<string, true, true, false>,
      'renderInput' | 'value' | 'onChange' | 'options'
    >,
    BaseUI {
  defaultValue?: string[];
  title?: string;
  values: Map<string, string> | Array<string>;
  fieldName: string;
  optionOnChangeValue?: (list: string[]) => void;
  required?: boolean;
}

export const AutocompleteValueMulti = ({
  required,
  values,
  className,
  defaultValue,
  fieldName,
  title,
  optionOnChangeValue,
  ...otherProps
}: AutocompleteValueProps) => {
  const { control } = useFormContext();

  const options = useMemo(() => {
    return Array.from(values instanceof Map ? values.keys() : values).sort();
  }, [values]);

  const getOptionsLabels = useCallback(
    (option: any) => (values instanceof Map ? values.get(option) || option : option),
    [values]
  );

  useEffect(() => {
    if (optionOnChangeValue && defaultValue) {
      optionOnChangeValue(defaultValue);
    }
  }, []);

  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue || []}
      render={({ field: { onChange, value } }) => {
        return (
          <Autocomplete
            multiple
            className={className}
            options={options}
            value={value}
            onChange={(event, newValue) => {
              console.log(2);
              onChange(newValue);
              if (optionOnChangeValue) {
                optionOnChangeValue(newValue);
              }
            }}
            getOptionLabel={getOptionsLabels}
            {...otherProps}
            renderInput={(params) => (
              <TextField
                {...params}
                label={title}
                variant='standard'
                required={required}
              />
            )}
          />
        );
      }}
    />
  );
};
