import {
  createImportEntry,
  deleteImportLinesByImportId,
  generateFileUrl,
  getAllImportsTypes,
  getAllTableDataMain,
  getImportLinesByImportId,
  updateImportLinesByImportId,
} from '@/services/ImportService/ImportService';
import {
  ISelectedImportItem,
  ImportFile,
  ImportTypes,
} from '@/services/ImportService/ImportServiceDto';
import { createStore } from '@/utils/createStore';
import { taskMapToArray } from '../tasksBrowse/utils';
import { fetchCatch, addSuccessActionLog } from '@/hooks/ActionLogHook';
import { GetCurrentUser } from '@/services/YodaRestService/YodaRestService';

interface ImportStore {
  mainTableData: ImportFile[];
  selectedImportLines: ISelectedImportItem[];
  selectedRow: ImportFile | null;
  importTypes: ImportTypes[];
  importTypesCatalog: ImportTypes;
  selectedImportType: ImportTypes;
  page: number;
  pageSize: number;
  isLoading: boolean;
  totalElements: number;
  selectedImportLinesPage: number;
  selectedImportLinesPageSize: number;
  selectedImportLinesTotalElements: number;
  curretUser: string | null;
  isUploading: boolean;
  setPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
  fetchImports: () => void;
  fetchImportTypes: () => void;
  fetchSelectedImportLines: () => void;
  handleRowDoubleClick: (row: ImportFile) => void;
  setSelectedImportType: (selected: ImportTypes) => void;
  setSelectedImportLines: (rows: ISelectedImportItem[]) => void;
  setSelectedImportLinesPage: (page: number) => void;
  setSelectedImportLinesPageSize: (pageSize: number) => void;
  handleDeleteRow: (id: string) => void;
  handleUpdateRow: (id: string, row: ISelectedImportItem) => void;
  uploadFile: (file: File, importType: string, username: string) => void;
  generatePresignedUrl: (
    fileName: string,
    importType: string
  ) => Promise<{ url: string; fileKey: string }>;
  getCurrentUser: () => void;
}

export const useImportStore = createStore<ImportStore>(
  (set, get) => ({
    mainTableData: [],
    importTypes: [],
    importTypesCatalog: {},
    selectedRow: null,
    page: 0,
    pageSize: 10,
    isLoading: false,
    totalElements: 0,
    selectedImportLinesPage: 0,
    selectedImportLinesPageSize: 10,
    selectedImportLinesTotalElements: 0,
    selectedImportType: {},
    selectedImportLines: [],
    curretUser: null,
    isUploading: false,
    setPage: (page) => {
      set(() => ({ page }));
      get().fetchImports();
    },
    setPageSize: (pageSize) => {
      set(() => ({ pageSize, page: 1 }));
      get().fetchImports();
    },
    setSelectedImportType: (selected) => set(() => ({ selectedImportType: selected })),
    setSelectedImportLines: (rows) => set(() => ({ selectedImportLines: rows })),

    setSelectedImportLinesPage: (page) => {
      set(() => ({ selectedImportLinesPage: page }));
      get().fetchSelectedImportLines();
    },
    setSelectedImportLinesPageSize: (pageSize) => {
      set(() => ({ selectedImportLinesPageSize: pageSize, selectedImportLinesPage: 0 }));
      get().fetchSelectedImportLines();
    },

    fetchImports: async () => {
      try {
        set({ isLoading: true });
        const { page, pageSize, selectedImportType } = get();
        const { data } = await getAllTableDataMain({
          page,
          size: pageSize,
          params: { importType: selectedImportType?.value, status: 'COMPLETE' },
        });

        set({
          mainTableData: data.content,
          totalElements: data.totalElements,
          isLoading: false,
        });
      } catch (error) {
        set({ isLoading: false });
        fetchCatch(error, 'Ошибка загрузки данных');
      }
    },

    fetchImportTypes: async () => {
      try {
        const response = await getAllImportsTypes();
        set(() => ({
          importTypes: taskMapToArray(response.data),
          importTypesCatalog: response.data,
        }));
      } catch (error) {
        fetchCatch(error, 'Ошибка при получении importTypes');
      }
    },

    handleRowDoubleClick: async (row: ImportFile) => {
      set({ selectedRow: row, isLoading: true });
      try {
        const { data } = await getImportLinesByImportId({
          importId: row.id,
          params: {},
        });
        set({
          selectedImportLines: data.content,
          isLoading: false,
          selectedImportLinesTotalElements: data.totalElements,
        });
      } catch (error) {
        fetchCatch(error, 'Ошибка получения данных для строки импорта');
      }
    },
    fetchSelectedImportLines: async () => {
      const { selectedImportLinesPage, selectedImportLinesPageSize, selectedRow } = get();
      if (selectedRow) {
        try {
          set({ isLoading: true });
          const { data } = await getImportLinesByImportId({
            importId: selectedRow.id,
            page: selectedImportLinesPage,
            size: selectedImportLinesPageSize,
            params: {},
          });

          set({
            selectedImportLines: data.content,
            isLoading: false,
            selectedImportLinesTotalElements: data.totalElements,
          });
        } catch (error) {
          set({ isLoading: false });
          fetchCatch(error, 'Ошибка получения строк импорта с пагинацией');
        }
      }
    },
    handleDeleteRow: async (id) => {
      set(() => ({ isLoading: true }));
      const { selectedRow } = get();
      try {
        await deleteImportLinesByImportId({
          importId: selectedRow?.id,
          lineId: id,
        });
        get().fetchSelectedImportLines();
      } catch (error) {
        set({ isLoading: false });
        fetchCatch(error, 'Ошибка удоления');
      }
    },
    handleUpdateRow: async (id, row) => {
      set(() => ({ isLoading: true }));
      const { selectedRow } = get();
      try {
        await updateImportLinesByImportId({
          importId: selectedRow?.id,
          lineId: id,
          params: row,
        });
        get().fetchSelectedImportLines();
      } catch (error) {
        set({ isLoading: false });
        fetchCatch(error, 'Ошибка обновления');
      }
    },

    generatePresignedUrl: async (fileName, importType) => {
      try {
        const { data } = await generateFileUrl({
          fileName,
          importType,
        });
        return data;
      } catch (error) {
        fetchCatch(error, 'Ошибка генерации URL');
        throw error;
      }
    },

    uploadFile: async (file, importType, username) => {
      try {
        set({ isUploading: true });
        // 1. Генерация URL
        const { url: presignedUrl, fileKey } = await get()
          .generatePresignedUrl(file.name, importType)
          .catch((error) => {
            throw new Error(`Ошибка генерации URL: ${error.message}`);
          });

        // 2. Загрузка файла
        const uploadResult = await fetch(presignedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type || 'application/octet-stream',
          },
        });

        if (!uploadResult.ok) {
          throw new Error('Ошибка загрузки файла в хранилище');
        }
        // 3. Создание записи
        await createImportEntry({
          fileName: fileKey,
          importType,
          username,
        });
        // 4. Логирование успеха
        addSuccessActionLog(`Файл ${file.name} успешно загружен для импорта (${importType})`);
      } catch (error) {
        fetchCatch(error, 'Процесс загрузки прерван');
        throw error;
      } finally {
        set({ isUploading: false });
      }
    },

    getCurrentUser: async () => {
      try {
        const { data } = await GetCurrentUser();
        set({ curretUser: data.fullName });
      } catch (error) {
        fetchCatch(error, 'Ошибка получения имени пользователя');
      }
    },
  }),
  'Import service'
);
