import React, { useContext, useEffect, useState } from 'react';
import { RolesEnum } from '../app/auth/roles';
import { yodatk } from '@/app/auth/AuthProvider';
import { useLocalStorage } from './useLocalStorage';
import { decodeJWT } from '@/utils/heplers';
import { KeyCloakJWT } from '@/app/auth/types';

interface IProps {
  children: React.ReactNode;
}

const RoleContext = React.createContext<{ role: RolesEnum[] | null }>({ role: null });

export const RoleProvider: React.FC<IProps> = ({ children }) => {
  const token = useLocalStorage(yodatk);
  const [role, setRole] = useState<RolesEnum[] | null>(null); // Состояние для хранения ролей пользователя

  useEffect(() => {
    if (token) {
      const decodedToken: null | KeyCloakJWT = decodeJWT(token);
      const roles = decodedToken?.resource_access?.['yoda-client']?.roles;
      if (roles) {
        setRole(roles as RolesEnum[]);
      } else {
        setRole(null);
      }
    } else {
      setRole(null);
    }
  }, [token]);

  return (
    <RoleContext.Provider
      value={{
        role, // Передаем значение роли всем дочерним компонентам
      }}
    >
      {children}
    </RoleContext.Provider>
  );
};

export const useRole = () => useContext(RoleContext);
