import {
  DataAddressItem,
  ExecuterScheduleMain,
  ExecuterType,
  FilterConfig,
  IInstallationCall,
  IgeoPointCallingMap,
  RequestHistoryType,
  TechInspectionType,
} from '@/components/maps/callingMap/types/types';
import { GeoData } from '@/components/maps/callingMap/CallingMap';
import { ApiResponse } from '@/types/api';
import {
  IncomingCallDateResponseDto,
  IncommingCallClaimResponseDto,
} from './dto/CallingMapServiceResponseDto';
import { YodaRestClient } from '../YodaRestService/YodaRestService';

export async function findAllInstallationCallMap(body: any): ApiResponse<IgeoPointCallingMap[]> {
  const {
    formatedFilter: { filterItemList, limit },
    polygon,
  } = body;
  const url = `/rest/installation-call/search`;

  return YodaRestClient.post(url, { polygons: polygon, filterItemList, limit });
}
export async function findPreClusterGeoPointsCallMap(
  body: GeoData
): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/pre-cluster`;
  return YodaRestClient.post(url, body);
}
export async function findPreClusterPolygonGeoPointsCallMap(
  body: GeoData
): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/pre-cluster/polygon`;
  return YodaRestClient.post(url, body);
}

export async function getSingleTaskfromCallMap(taskId: string): ApiResponse<IInstallationCall> {
  const url = `/rest/installation-call/${taskId}`;
  return YodaRestClient.get(url);
}
export async function getSingleTKOCallMap(
  taskId: string | null
): ApiResponse<TechInspectionType[]> {
  const url = `/rest/task/${taskId}/tech-inspection`;
  return YodaRestClient.get(url);
}
export async function getSingleRrquestHisoryCallMap(
  taskId: string | null
): ApiResponse<RequestHistoryType[]> {
  const url = `/rest/callcentre/task/${taskId}/short`;
  return YodaRestClient.get(url);
}
export async function getExecutersAvalibleCallMap(): ApiResponse<ExecuterType[]> {
  const url = `/rest/installation-call/available-executors`;
  return YodaRestClient.get(url);
}

export async function getGeoLessPoints(params: any): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/search/invalid-coordinates`;
  return YodaRestClient.post(url, {
    filterItemList: params.body.filterItemList,
    limit: params.pageSize,
    offset: params.page * params.pageSize,
  });
}
export async function getGeoLessPointsCount(body: any): ApiResponse<number> {
  const url = `/rest/installation-call/search/invalid-coordinates/count`;
  return YodaRestClient.post(url, body);
}
export async function addInstallationCallLogs(body: any): ApiResponse<any> {
  const url = `/rest/installation-call`;
  return YodaRestClient.post(url, body);
}
export async function getSingleGeoTask(taskId: string | null): ApiResponse<string> {
  const url = `/rest/installation-call/${taskId}/general-status
`;
  return YodaRestClient.get(url);
}
export async function getAllExecutersCallMap(): ApiResponse<ExecuterType[]> {
  const url = `/rest/installation-call/executors`;
  return YodaRestClient.get(url);
}
export async function sendExecutersCallMap(body: string[]): ApiResponse<any> {
  const url = `/rest/installation-call/executors`;
  return YodaRestClient.post(url, body);
}
export async function getUrgentPointsCountCallMap(): ApiResponse<number> {
  const url = `/rest/installation-call/urgent/count`;
  return YodaRestClient.get(url);
}
export async function getUrgentPointsCallMap(): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/urgent`;
  return YodaRestClient.get(url);
}
export async function getExecutorsSchedule(
  timeStart: string,
  timeEnd: string,
  executorId?: string
): ApiResponse<ExecuterScheduleMain> {
  const url = `/rest/installation-call/workload?start=${timeStart}&end=${timeEnd}${
    executorId ? `&executorId=${executorId}` : ''
  }`;
  return YodaRestClient.get(url);
}

export async function getTasksByIdsCallMap(body: string[]): ApiResponse<any> {
  const url = `/rest/installation-call/task/find-by-ids`;
  return YodaRestClient.post(url, body);
}
export async function getTaskByIdCallMap(termTaskId: string): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/task/${termTaskId}`;
  return YodaRestClient.get(url);
}
export async function getTaskByAdressCallMap(termAdress: string): ApiResponse<DataAddressItem[]> {
  const url = `/rest/address-table?address=${termAdress}`;
  return YodaRestClient.get(url);
}
export async function getTaskInvalidTasksByAdressCallMap(
  termAdress: string
): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/search/invalid-coordinates-by-address?address=${termAdress}&offset=1&limit=100`;
  return YodaRestClient.post(url, { filterItemList: [] });
}
export async function getTaskByNumberCallMap(
  termNumber: string
): ApiResponse<IgeoPointCallingMap[]> {
  const url = `/rest/installation-call/phone-number/${termNumber}`;
  return YodaRestClient.get(url);
}
export async function getTasksByAdressIdCallMap(id: string): ApiResponse<any> {
  const url = `/rest/installation-call/address/${id}`;
  return YodaRestClient.get(url);
}

export async function getIncomingCallDates(
  month: number,
  year: number,
  filter?: FilterConfig
): ApiResponse<IncomingCallDateResponseDto[]> {
  const url = `/rest/callcentre/calendar?year=${year}&month=${month}`;
  return YodaRestClient.post(url, filter);
}
export async function getIncomingCallRequestsPerDay(
  offset: number,
  limit: number,
  filter: FilterConfig,
  date?: string // Формат yyyy-MM-dd
): ApiResponse<IncommingCallClaimResponseDto[]> {
  const params = new URLSearchParams();
  if (date) {
    params.append('date', date);
  }
  params.append('offset', offset.toString());
  params.append('limit', limit.toString());

  const url = `/rest/callcentre/calendar/search?${params.toString()}`;
  return YodaRestClient.post(url, filter);
}

export async function getIncomingCallCount(filter?: FilterConfig): ApiResponse<number | null> {
  const url = `/rest/callcentre/calendar/unassigned-count`;
  return YodaRestClient.post(url, filter);
}

export async function getInvaidTaskByPhoneNumberOrTaskId(
  filter: FilterConfig
): ApiResponse<IgeoPointCallingMap[] | null> {
  const url = `/rest/installation-call/search/invalid-coordinates/task`;
  return YodaRestClient.post(url, filter);
}
export async function getInvaidTaskByTaskId(
  filter: FilterConfig
): ApiResponse<IgeoPointCallingMap[] | null> {
  const url = `/rest/installation-call/search/invalid-coordinates/task `;
  return YodaRestClient.post(url, filter);
}

export async function getTaskByTaskIdUniqSearch(
  filter: FilterConfig
): ApiResponse<IgeoPointCallingMap[] | null> {
  const url = `/rest/installation-call/invalid-coordinates`;
  return YodaRestClient.post(url, filter);
}

export async function getMapCount(filter: FilterConfig): ApiResponse<number | null> {
  const url = `/rest/installation-call/search/count`;
  return YodaRestClient.post(url, filter);
}

export async function getTaskWithoutCoordinatesCount(
  filter: FilterConfig
): ApiResponse<number | null> {
  const url = `/rest/installation-call/search/invalid-coordinates/count`;
  return YodaRestClient.post(url, filter);
}
