import { PillarResponse, ReplacementRequest } from '@/dto/taskmap/task/TaskResultDto';
import { ApiResponse } from '@/types/api';
import { YodaRestClient } from '../YodaRestService/YodaRestService';

export async function saveReplacement(
  id: string,
  replacementRequest: ReplacementRequest
): ApiResponse<any> {
  const url = `/rest/odpu/${id}/smr`;
  return YodaRestClient.put(url, replacementRequest);
}

export async function toCancelReplacement(id: string, replacementReason: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/cancel`;
  return YodaRestClient.post(url, replacementReason);
}

export async function toRepeatInstallationReplacement(id: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/repeat-installation`;
  return YodaRestClient.post(url, null);
}

export async function toSwapReplacement(currentId: string, targetId: string): ApiResponse<any> {
  const url = `/rest/replacement/${currentId}/swap/${targetId}`;
  return YodaRestClient.post(url, null);
}

export async function toInstalledIncorrect(id: string, reason: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/installed-incorrect/${reason}`;
  return YodaRestClient.post(url, null);
}
export async function removeByguarantee(id: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/removed-by-guarantee`;
  return YodaRestClient.post(url, null);
}

export async function oldPUIndicationsIncorrect(id: string): ApiResponse<any> {
  const url = `/rest/measurement/old/${id}`;
  return YodaRestClient.post(url, null);
}

export async function findYungaAnalyzeLogByReplacementId(replacementId: string): ApiResponse<any> {
  const url = `/rest/replacement/${replacementId}/analyzer`;
  return YodaRestClient.get(url);
}

export async function getLastYungaAnalyzeValidationMessage(taskId: string): ApiResponse<any> {
  const url = `/rest/task/${taskId}/last-yunga-analyze-validation-message`;
  return YodaRestClient.get(url);
}

export async function yungaAnalyzeByReplacementId(id: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/analyzer`;
  return YodaRestClient.post(url, null);
}

export async function changeStatus(id: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/actual`;
  return YodaRestClient.post(url, {});
}

export async function replacementSendActOld(id: string): ApiResponse<any> {
  const url = `/rest/replacement/${id}/act/status/next`;
  return YodaRestClient.post(url, {});
}
export async function replacementSendAct(
  vruInputId: string,
  meterChangeId: string
): ApiResponse<any> {
  const url = `/rest/vru-input/act/status/next`;
  return YodaRestClient.post(url, { vruInputId, meterChangeId });
}
export async function fidnReplacementById(id: string): ApiResponse<any> {
  const url = `/rest/replacement?id=${id}`;
  return YodaRestClient.get(url);
}
export async function toSwapReplacementByTaskId(
  replacementId: string,
  taskId: string
): ApiResponse<any> {
  const url = `/rest/replacement/${replacementId}/move/${taskId}`;
  return YodaRestClient.post(url, null);
}

export async function getSmrLockerCatalogById(contractId: string): ApiResponse<PillarResponse> {
  const url = `/rest/catalog/accepted-smr-locker/${contractId}`;
  return YodaRestClient.get(url);
}
