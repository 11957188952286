import { ApiResponse } from '@/types/api';
import { KeyCloakAuthTokenResponseDto } from './dto/AuthServiceResponseDto';
import axios from 'axios';
import { saveYodaToken, yodaRefreshTokenLocal, yodaTokenLocal } from '@/app/auth/AuthProvider';

const SERCRET = 'wVZw1LYJYhHWlkaw0Q7krAUfzIcj4TFR';
export const HOST = getHost();
function getHost() {
  const HOST = import.meta.env.VITE_AUTH_SERVER_HOST || 'https://keycloak.dev.lan.tpplab';

  if (typeof HOST == 'undefined') {
    alert('Адрес сервера для запросов к keyCloak не задан');
  } else return HOST as string;
}

export const keyCloakAuth = (
  login: string,
  psw: string
): ApiResponse<KeyCloakAuthTokenResponseDto> => {
  const url = HOST + '/realms/yoda/protocol/openid-connect/token';

  const data = new URLSearchParams({
    grant_type: 'password',
    password: psw,
    username: login,
    client_secret: SERCRET,
    client_id: 'yoda-client',
  });

  return axios.post(url, data.toString(), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
};

export function refreshKeyCloakToken(): ApiResponse<KeyCloakAuthTokenResponseDto> {
  const url = HOST + '/realms/yoda/protocol/openid-connect/token';
  const refreshToken = yodaRefreshTokenLocal();
  if (!refreshToken) {
    return Promise.reject(new Error('Refresh token is not available'));
  }

  const data = new URLSearchParams({
    grant_type: 'refresh_token',
    client_secret: SERCRET,
    client_id: 'yoda-client',
    refresh_token: refreshToken,
  });

  return axios.post(url, data.toString(), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

let REFRESH_TOKEN_PROMISE: Promise<string> | null = null;

/**
 * If token overdue invoke yodaFerfeshAouth
 * @returns accessToke or Promise.reject
 */
export async function yodaGetAccessToken(forceRefresh?: boolean): Promise<string> {
  if (!forceRefresh) {
    const token = yodaTokenLocal();
    if (token) return token;
  }

  const refreshToken = yodaRefreshTokenLocal();
  if (!refreshToken) {
    return Promise.reject(new Error('Refresh token is not available'));
  }

  if (REFRESH_TOKEN_PROMISE) {
    return REFRESH_TOKEN_PROMISE;
  }

  REFRESH_TOKEN_PROMISE = refreshKeyCloakToken()
    .then((response) => {
      saveYodaToken(response.data);

      if (response.data.access_token) {
        return response.data.access_token;
      } else {
        return Promise.reject(new Error('Access token not found in response'));
      }
    })
    .catch((error) => {
      // В случае ошибки сбрасываем REFRESH_TOKEN_PROMISE, чтобы можно было повторить запрос при следующем обращении
      REFRESH_TOKEN_PROMISE = null;
      return Promise.reject(error);
    })
    .finally(() => {
      // После завершения запроса, независимо от результата, сбрасываем промис
      REFRESH_TOKEN_PROMISE = null;
    });

  return REFRESH_TOKEN_PROMISE;
}
