import { useEffect, useState } from 'react';
import { useUspdMapStore } from './store';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { ActionType, NetworkMapType, NetworkResponse } from './UspdDto';
import SelectedPoint from './SelectedPoint';
import { geojsonToWKT } from '@terraformer/wkt';
import { GetUSPDXlsx } from '@/services/YodaRestService/YodaRestService';
import { useScreenHoldHook } from '@/hooks/ScreenHoldHook';
import { useActionLog } from '@/hooks/ActionLogHook';
import PolygonInfoBlock from './PolygonInfoBlock';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import AddIcon from '@mui/icons-material/Add';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { useKeyPress } from '@/hooks/usePressKey';
import React from 'react';
import { downLoadExcel } from '@/components/features/complaints/ComplaintReportButtons/utils';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';


interface UspdFilterPanelProps {
  onLoad: (flag: boolean) => void;
  networkHiddenList: string[];
  onChangeVisible: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addnewPoint: () => void;
  isEditMode: boolean;
  setSelectedPoint: (point: NetworkResponse | null) => void;
  setIsEditMode: (flag: boolean) => void;
  onDelete: (id: number | undefined | null) => void;
  setAction: (action: ActionType) => void;
  selectedPoint: NetworkResponse | null;
  onSave: any;
  polygonRef: any;
  mapRef: any;
}

const UspdFilterPanel = ({
  onLoad,
  networkHiddenList,
  onChangeVisible,
  selectedPoint,
  setAction,
  addnewPoint,
  isEditMode,
  setSelectedPoint,
  setIsEditMode,
  onDelete,
  onSave,
  polygonRef,
  mapRef,
}: UspdFilterPanelProps) => {
  const {
    coverageAreaValue,
    setCoverageAreaValue,
    setIsDrawing,
    isDrawing,
    setPolygonCoords,
    polygonCoords,
    setPolygonMod,
    setCoords,
    centerCoords,
  } = useUspdMapStore((state) => ({
    coverageAreaValue: state.coverageAreaValue,
    setCoverageAreaValue: state.setCoverageAreaValue,
    setIsDrawing: state.setIsDrawing,
    isDrawing: state.isDrawing,
    setPolygonCoords: state.setPolygonCoords,
    polygonCoords: state.polygonCoords,
    setPolygonMod: state.setPolygonMod,
    setCoords: state.setCoords,
    centerCoords: state.centerCoords,
  }));
  const { setIsInProgress } = useScreenHoldHook();
  const [coordsInputValue, setCoordsInputValue] = useState('');
  const [isSwapped, setIsSwapped] = useState(false);

  const { fetchCatch } = useActionLog();

  const handleStopDrawing = () => {
    if (polygonRef) {
      setPolygonMod('edit');
      polygonRef?.current?.editor?.stopDrawing();
      const polygonGeometry = polygonRef?.current.geometry.getCoordinates();
      setPolygonCoords(polygonGeometry);
    }
  };

  const handleCoverageAreaValue = (e: any) => {
    setAction('default');
    setCoverageAreaValue(+e.target.value);
  };

  useEffect(() => {
    if (selectedPoint) {
      setCoverageAreaValue(selectedPoint?.coverageRadius);
    } else {
      setCoverageAreaValue(500);
    }
  }, [selectedPoint]);

  const onCloseSelectedPoint = () => {
    setSelectedPoint(null);
    setIsEditMode(false);
    setSelectedPoint(null);
    setPolygonCoords([]);
  };

  const downloadExcelFile = () => {
    setIsInProgress(true);
    handleStopDrawing();
    polygonRef?.current?.editor?.stopDrawing();
    const polygonGeometry = polygonRef?.current.geometry.getCoordinates();
    const wkt = geojsonToWKT({
      type: 'Polygon',
      coordinates: polygonGeometry || polygonCoords,
    });
    GetUSPDXlsx({ polygon: wkt === 'POLYGON EMPTY' ? null : wkt })
      .then((res: any) => {
        downLoadExcel(res, `report-USPD`);
      })
      .catch((error) => {
        fetchCatch(error);
      })
      .finally(() => {
        setIsInProgress(false);
      });
  };

  const startDrawing = () => {
    setPolygonMod('stop');
    polygonRef?.current?.editor?.startDrawing();
  };
  const clearPolygon = () => {
    polygonRef?.current?.editor?.stopDrawing();
    setPolygonCoords([]);
    setIsDrawing(false);
  };

  const onChangeCoordsInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value;
    const coordsCenterNew = value.split(' ').map((el: string) => +el);
    setCoordsInputValue(value);
    setCoords(coordsCenterNew);
  };
  const setCenter = () => {
    if (!disableButtonCords && centerCoords?.length) {
      mapRef?.setCenter(centerCoords);
    }
  };

  useKeyPress('Enter', setCenter);

  const disAbleSetCenterCoords =
    centerCoords.every((el) => !Number.isNaN(el)) && centerCoords.length < 3;

  const disableButtonCords =
    !disAbleSetCenterCoords || !centerCoords.length || centerCoords.length === 1;

  const handleSwapCoords = () => {
    if (centerCoords.length === 2) {
      const swappedCoords = [centerCoords[1], centerCoords[0]];
      setCoords(swappedCoords);
      setCoordsInputValue(`${swappedCoords[0]} ${swappedCoords[1]}`);
      setIsSwapped(!isSwapped);
    }
  };
  return (
    <Grid
      item
      xs={3}
    >
      <Grid item>
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <Button
              onClick={() => {
                onDelete(null);
                onLoad(false);
              }}
              size='small'
            >
              Обновить
            </Button>
            <Button
              onClick={() => {
                onDelete(null);
                onLoad(false);
              }}
              size='small'
            >
              Очистить кэш
            </Button>
          </Box>
          <Box>
            <Button
              startIcon={<HighlightAltIcon />}
              disabled={isDrawing}
              onClick={() => {
                setIsDrawing(true);
                setPolygonMod('edit');
              }}
              size='small'
            >
              отчет
            </Button>
          </Box>

          <Button
            startIcon={<AddIcon />}
            size='small'
            onClick={addnewPoint}
          >
            УСПД
          </Button>
        </Stack>
      </Grid>
      <Grid
        item
        container
      >
        <Grid item>
          <FormGroup>
            <Grid>
              {Object.keys(NetworkMapType).map((t) => (
                <FormControlLabel
                  key={`checkbox-network-map-type=${t}`}
                  control={
                    <Checkbox
                      size='small'
                      checked={!networkHiddenList.includes(t)}
                      onChange={onChangeVisible}
                      name={t}
                    />
                  }
                  label={NetworkMapType[t]}
                />
              ))}
              <Stack
                mb={3}
                mt={1}
                flexDirection={'row'}
                spacing={2}
                gap={3}
              >
                <TextField
                  id='standard-basic-coords'
                  label={`координаты (${isSwapped ? 'Y X' : 'X Y'})`}
                  variant='standard'
                  size='small'
                  fullWidth
                  error={!disAbleSetCenterCoords}
                  onChange={onChangeCoordsInput}
                  helperText={
                    !disAbleSetCenterCoords && 'Формат: Широта Долгота (например: 55.7558 37.6173)'
                  }
                  value={coordsInputValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={handleSwapCoords}
                          aria-label='swap-coordinates'
                          size='small'
                        >
                          <SwapHorizIcon fontSize='small' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <IconButton
                  disabled={disableButtonCords}
                  onClick={setCenter}
                  aria-label='location'
                  size='small'
                >
                  <MyLocationIcon fontSize='small' />
                </IconButton>
              </Stack>
            </Grid>

            {selectedPoint && (
              <SelectedPoint
                onCloseSelectedPoint={onCloseSelectedPoint}
                selectedPoint={selectedPoint}
              />
            )}

            {isDrawing && (
              <PolygonInfoBlock
                handleStopDrawing={handleStopDrawing}
                downloadExcelFile={downloadExcelFile}
                isDrawing={isDrawing}
                startDrawing={startDrawing}
                clearPolygon={clearPolygon}
              />
            )}

            {isEditMode && (
              <TextField
                id='standard-basic'
                label='Зона охвата УСПД'
                variant='standard'
                type='number'
                size='small'
                fullWidth
                disabled={!isEditMode}
                value={coverageAreaValue}
                onChange={(e) => handleCoverageAreaValue(e)}
              />
            )}
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              marginTop={2}
            >
              {isEditMode && (
                <Button onClick={() => onSave(coverageAreaValue)}>
                  {isEditMode ? 'Сохранить' : 'Редактировать'}
                </Button>
              )}

              {isEditMode && <Button onClick={() => onDelete(selectedPoint?.id)}>удалить</Button>}
            </Stack>
          </FormGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(UspdFilterPanel);
