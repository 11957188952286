import { Stack } from '@mui/material';
import { DataGrid, GridRowParams, ruRU } from '@mui/x-data-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useImportStore } from '../store';
import CustomPagination from '../Pagination/Pagination';
import { useImportTableColumnsMain } from '../utils';
import { ImportFile } from '@/services/ImportService/ImportServiceDto';
import shallow from 'zustand/shallow';

export const ImportTableAll = () => {
  const navigate = useNavigate();
  const columns = useImportTableColumnsMain();
  const {
    mainTableData,
    isLoading,
    page,
    setPage,
    setPageSize,
    pageSize,
    totalElements,
    handleRowDoubleClick,
  } = useImportStore(
    (state) => ({
      mainTableData: state.mainTableData,
      isLoading: state.isLoading,
      page: state.page,
      setPage: state.setPage,
      setPageSize: state.setPageSize,
      pageSize: state.pageSize,
      totalElements: state.totalElements,
      handleRowDoubleClick: state.handleRowDoubleClick,
    }),
    shallow
  );
  const handlePageChange = (newPage: number) => setPage(newPage);

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPage(0);
  };
  const onRowDoubleClick = (e: GridRowParams<ImportFile>) => {
    handleRowDoubleClick(e.row);
    navigate(`/import/${e.row.id}`);
  };

  return (
    <Stack height={550}>
      <DataGrid
        paginationMode='server'
        rows={mainTableData}
        loading={isLoading}
        columns={columns}
        onRowDoubleClick={onRowDoubleClick}
        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
        disableColumnFilter
        disableSelectionOnClick
        components={{
          Pagination: () => (
            <CustomPagination
              page={page}
              pageSize={pageSize}
              totalElements={totalElements}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          ),
        }}
      />
    </Stack>
  );
};
