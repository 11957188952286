import React from 'react';
import { AccessEnum } from '@/app/auth/roles';
import { useAccess } from '@/hooks/useAccess';
import { useMetaTitle } from '@/hooks/useMetaTitle';

import { ImportTableSelectedById } from '@/components/features/import/ImportTableSelectedById/ImportTableSelectedById';

const ImportTableSelectedByIdPage = () => {
  const hasAccess = useAccess({
    access: [AccessEnum.ImportPage],
  });
  useMetaTitle('Тип импорт');
  return <>{hasAccess && <ImportTableSelectedById />}</>;
};
export default ImportTableSelectedByIdPage;
