import { LS_KEY_STOREHOUSE } from '@/components/storehouse/SupplyPUComponent/utils';
import { createContext } from 'react';
import { RoutesProps } from 'react-router-dom';
import { YodaTokenContextType } from './types';
import { GALLERY_KEY } from '@/components/features/NewGallery/utils';
import { KeyCloakAuthTokenResponseDto } from '@/services/AuthService/dto/AuthServiceResponseDto';

export const yodatk = 'tk';
const yodaTkExIn = 'tkExIn';
const yodaTs = 'ts';
const yodarf = 'rf';
const yodaRfExIn = 'rfExIn';
export const catalogsLookUp = 'catalogsLookUp';
export const yodaJwt = 'yodaJwt';

export const AuthContext = createContext<YodaTokenContextType | null>(null);

export const AuthProvider = ({ children }: RoutesProps) => {
  const isValidToken = (): boolean => {
    const token = yodaTokenLocal();
    const refreshToken = yodaRefreshTokenLocal();
    return !!token || !!refreshToken;
  };

  return <AuthContext.Provider value={{ isValidToken }}>{children}</AuthContext.Provider>;
};

export const logOutUser = () => {
  removeTokens();
  const currentPath = window.location.pathname + window.location.search;
  localStorage.setItem('redirectPath', currentPath);

  // Редиректим на страницу логина
  window.location.href = '/login';
};

export function removeTokens() {
  removeAuthTokens();
  removeStorehouseLs();
  removeYodaOtherLs();
}

const removeAuthTokens = () => {
  removeLsTokensArray([yodatk, yodarf, yodaJwt, yodaTkExIn, yodaTs, yodaRfExIn]);
};

const removeStorehouseLs = () => {
  removeLsTokensArray([LS_KEY_STOREHOUSE]);
};

const removeYodaOtherLs = () => {
  removeLsTokensArray([catalogsLookUp, GALLERY_KEY]);
};

const removeLsTokensArray = (tokens: string[]) => {
  tokens.forEach((token) => {
    localStorage.removeItem(token);
  });
};

const getTokenWithExpirationCheck = (tokenType: 'access' | 'refresh') => {
  const tCreated = Number(localStorage.getItem(yodaTs));
  const clearTime = (new Date().getTime() - tCreated) / 1000;
  const expiresIn = Number(localStorage.getItem(tokenType === 'access' ? yodaTkExIn : yodaRfExIn));
  if (clearTime > expiresIn) return null;

  return localStorage.getItem(tokenType === 'access' ? yodatk : yodarf) as string;
};

export function yodaTokenLocal(): string | null {
  return getTokenWithExpirationCheck('access');
}

export function yodaRefreshTokenLocal(): string | null {
  return getTokenWithExpirationCheck('refresh');
}

export function saveYodaToken(token: KeyCloakAuthTokenResponseDto) {
  localStorage.setItem(yodatk, token.access_token);
  localStorage.setItem(yodarf, token.refresh_token);

  localStorage.setItem(yodaTs, String(new Date().getTime())); // время создания токена, для корректной работы механизма рефреша
  localStorage.setItem(yodaTkExIn, String(token.expires_in - 90));
  localStorage.setItem(yodaRfExIn, String(token.refresh_expires_in - 90));
}
