import { Stack, Typography, Button } from '@mui/material';
import { DataGrid, GridCellParams, ruRU } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useImportTableColumns } from '../utils';
import { useImportStore } from '../store';
import { ISelectedImportItem, ImportStatusLine } from '@/services/ImportService/ImportServiceDto';
import CustomPagination from '../Pagination/Pagination';
import WarrningMessage from '../WarrningMessage/WarrningMessage';
import shallow from 'zustand/shallow';
import { useMobileSize } from '@/hooks/useMediaQuery/useMobileSize';
import { useNavigate } from 'react-router-dom';

export const ImportTableSelectedById = () => {
  const navigate = useNavigate();

  const {
    selectedRow,
    importTypesCatalog,
    isLoading,
    selectedImportLines,
    selectedImportLinesPage,
    setSelectedImportLinesPageSize,
    selectedImportLinesPageSize,
    setSelectedImportLinesPage,
    selectedImportLinesTotalElements,
    handleDeleteRow,
    handleUpdateRow,
  } = useImportStore(
    (state) => ({
      selectedRow: state.selectedRow,
      importTypesCatalog: state.importTypesCatalog,
      isLoading: state.isLoading,
      selectedImportLines: state.selectedImportLines,
      selectedImportLinesPage: state.selectedImportLinesPage,
      setSelectedImportLinesPageSize: state.setSelectedImportLinesPageSize,
      selectedImportLinesPageSize: state.selectedImportLinesPageSize,
      setSelectedImportLinesPage: state.setSelectedImportLinesPage,
      selectedImportLinesTotalElements: state.selectedImportLinesTotalElements,
      handleDeleteRow: state.handleDeleteRow,
      handleUpdateRow: state.handleUpdateRow,
    }),
    shallow
  );

  const [editingRowId, setEditingRowId] = useState<string | null>(null);
  const [isColValueChanged, setIsColValueChanged] = useState(false);
  const [warrnigString, setWarrnigString] = useState<string | null>(null);

  const columns = useImportTableColumns(editingRowId, handleDeleteRow, selectedImportLines);

  const handlePageChange = (newPage: number) => setSelectedImportLinesPage(newPage);

  const handlePageSizeChange = (newPageSize: number) => {
    setSelectedImportLinesPageSize(newPageSize);
    setSelectedImportLinesPage(0);
  };

  const onRowUpdate:
    | ((newRow: ISelectedImportItem, oldRow: ISelectedImportItem) => ISelectedImportItem)
    | undefined = (newRow, oldRow) => {
    Object.keys(newRow).forEach((key) => {
      if (newRow[key] !== oldRow[key]) {
        console.log(
          `Вы изменили значение "${oldRow[key]}" на "${newRow[key]}" в колонке "${key}". Хотите найти такие же значения по этой колонке? `
        );
        setWarrnigString(
          `Вы изменили значение "${oldRow[key]}" на "${newRow[key]}" в колонке "${key}". Хотите найти такие же значения по этой колонке?`
        );
        setIsColValueChanged(true);
        handleUpdateRow(newRow.id, newRow);
      }
    });

    return newRow;
  };

  const handleNo = () => {
    setWarrnigString(null);
    setIsColValueChanged(false);
  };

  const isMobile = useMobileSize();
  return (
    <Stack p={1}>
      <Stack
        m={3}
        flexDirection={'row'}
        gap={1}
      >
        <Typography variant='h5'>
          Тип импорта: {importTypesCatalog[selectedRow?.importType ?? '']}
        </Typography>
        {/* <Button
          color='inherit'
          onClick={() => navigate('/import')}
        >
          назад
        </Button> */}
      </Stack>
      {isColValueChanged && (
        <WarrningMessage
          warrnigString={warrnigString}
          handleNo={handleNo}
        />
      )}
      <Stack height={550}>
        <DataGrid
          disableColumnFilter
          loading={isLoading}
          localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
          rows={selectedImportLines}
          columns={columns}
          disableSelectionOnClick
          processRowUpdate={onRowUpdate}
          isCellEditable={(params: GridCellParams) =>
            params.row.status === ImportStatusLine.INVALID ||
            params.row.status === ImportStatusLine.CONFLICT
          }
          onCellEditStart={(params) => {
            if (
              params.row.status === ImportStatusLine.INVALID ||
              params.row.status === ImportStatusLine.CONFLICT
            ) {
              setEditingRowId(params.row.id);
            }
          }}
          onCellEditStop={() => {
            setEditingRowId(null);
          }}
          editMode='cell'
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Pagination: () => (
              <Stack
                direction={isMobile ? 'column' : 'row'}
                flex={1}
                justifyContent={'space-between'}
                p={1}
              >
                <Button
                  disabled={!isColValueChanged}
                  variant='outlined'
                  size='small'
                >
                  повторить импорт измененых строк
                </Button>
                <CustomPagination
                  page={selectedImportLinesPage}
                  pageSize={selectedImportLinesPageSize}
                  totalElements={selectedImportLinesTotalElements}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                />
              </Stack>
            ),
          }}
        />
      </Stack>
    </Stack>
  );
};
