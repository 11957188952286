import axios from 'axios';
import { UnAttachmentDto } from '@/dto/YodaRestDto';
import { BindResult } from '@/dto/taskmap/Dto';
import qs from 'qs';
import { ApiResponse } from '@/types/api';
import {
  ApiFindUnAttachmentByFilter,
  CONTENT_TYPE,
  GetFileUrlByFileId,
  YODA_HOST,
  YodaRestClient,
} from '@/services/YodaRestService/YodaRestService';
import { yodaGetAccessToken } from './AuthService/AuthService';

const TAG = 'FileService';

export function GetUnAttachmentByFilter(filter: string): ApiResponse<UnAttachmentDto[]> {
  return ApiFindUnAttachmentByFilter(filter);
}

export function GetBase64File(id: string): Promise<string> {
  return GetFileUrlByFileId(id);
}

export async function uploadFile(
  objId: string,
  typeIdList: string[],
  stageId: string,
  fileList: FileList
): ApiResponse<any> {
  const url = YODA_HOST + `/rest/file/entity/${objId}`;
  const token = await yodaGetAccessToken();

  const formData = new FormData();
  Array.from(fileList).forEach((t: any) => formData.append('fileList', t));

  return axios.post(url, formData, {
    params: {
      typeIdList: typeIdList,
      stageId,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

export async function bindVectorDiagram(
  contractId: string,
  fileList: FileList
): ApiResponse<BindResult[]> {
  const url = `/rest/file/diagram/bind?contractId=${contractId}`;
  const formData = new FormData();
  Array.from(fileList).forEach((t: any) => formData.append('fileList', t));
  return YodaRestClient.post(url, formData, { headers: CONTENT_TYPE.MULTIPART_HEADER });
}

export async function deleteFile(fileId: string): ApiResponse<any> {
  const url = `/rest/file/${fileId}`;
  return YodaRestClient.delete(url);
}

export async function deleteFileEntity(entityId: string): ApiResponse<any> {
  const url = `/rest/file/entity/${entityId}`;
  return YodaRestClient.delete(url);
}
