import { logOutUser } from '@/app/auth/AuthProvider';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { yodaGetAccessToken } from '../AuthService/AuthService';

const UNAUTHORIZED_CODE = 401;

// Универсальная функция для навешивания интерсепторов авторизации
export const attachAuthInterceptors = (axiosInstance: AxiosInstance): void => {
  // Интерсептор запроса: добавление заголовка Authorization
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      try {
        // Получаем актуальный токен
        const token = await yodaGetAccessToken();

        // Если заголовки ещё не заданы, инициализируем их
        config.headers = config.headers || {};

        // Добавляем заголовок авторизации
        config.headers.Authorization = `Bearer ${token}`;

        return config;
      } catch (error) {
        // Если получение токена не удалось, отклоняем запрос
        return Promise.reject(error);
      }
    },
    (error) => Promise.reject(error)
  );

  // Интерсептор ответа: обработка 401 ответа
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === UNAUTHORIZED_CODE) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;

          try {
            // Форс перезапрос токена
            const token = await yodaGetAccessToken(true);

            // Обновляем заголовок в оригинальном запросе
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${token}`,
            };

            // Повторяем исходный запрос с обновлёнными заголовками
            return axiosInstance(originalRequest);
          } catch (tokenError) {
            // Если обновление токена не удалось — очищаем токены
            logOutUser();
          }
        } else {
          // Если запрос уже был перезапущен — очищаем токены
          logOutUser();
        }
      }
      return Promise.reject(error);
    }
  );
};
