import { ApiResponse } from '@/types/api';
import {
  CallCenterStatisticsDto,
  SmsStatusStatisticsItem,
  StatisticMoreThanOneApealDto,
  StatisticsContractorDto,
  StatisticsResponsebleDto,
} from './dto/CustomerInteractionStatisticsServiceResponseDto';
import {
  CallCenterStatisticsFilter,
  Filter,
  FilterSmsStatisitc,
  StatisticsResponsebleFilter,
} from './dto/CustomerInteractionStatisticsServiceRequestDto';
import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';

export async function ApiFindSingleContractor(filters?: any): ApiResponse<StatisticsContractorDto> {
  const { path, ...restFilter } = filters;
  const url = `/rest/statistics/contractorTasks/${path}`;
  return YodaRestClient.post(url, restFilter);
}

export async function GetStatisticsResponsebles(
  contractIdList: StatisticsResponsebleFilter
): ApiResponse<StatisticsResponsebleDto[]> {
  const url = '/rest/statistics/call-center/contractor';
  return YodaRestClient.post(url, contractIdList);
}

export async function GetCallCenterStatisticsTableByFilter(
  filter: CallCenterStatisticsFilter
): ApiResponse<CallCenterStatisticsDto[]> {
  const url = '/rest/statistics/call-center';
  return YodaRestClient.post(url, filter);
}

export async function GetStatisticMoreThanOneApealByFilter(
  filter: Filter
): ApiResponse<StatisticMoreThanOneApealDto[]> {
  const { pageSize, currentPage, ...payload } = filter;
  const url = `/rest/statistics/task-with-calls?offset=${currentPage * pageSize}&limit=${pageSize}`;
  return YodaRestClient.post(url, payload);
}

export async function GetStatisticCallingMap(filter: any): ApiResponse<any[]> {
  const url = '/rest/statistics/installation-call-status';
  return YodaRestClient.post(url, filter);
}

export async function GetSmsStatusStatistics(
  contractIdList: FilterSmsStatisitc
): ApiResponse<SmsStatusStatisticsItem[]> {
  const url = '/rest/statistics/sms/status/contracts';

  return YodaRestClient.post(url, contractIdList);
}

export async function GetCallCenterStatisticsXlsx(filter: {
  taskIdList: string[];
  template: string;
}): ApiResponse<any> {
  const url = '/rest/report/task';
  return YodaRestClient.post(url, filter, { responseType: 'blob' });
}
export async function GetCallCenterStatisticsXlsxByFilter(
  filter: CallCenterStatisticsFilter,
  path?: string
): ApiResponse<any> {
  const url = '/rest/report/call-center' + (path ? `/${path}` : '');
  return YodaRestClient.post(url, filter, { responseType: 'blob' });
}
