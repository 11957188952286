export type ImportTypes = Record<string, string>;

export interface ImportFile {
  id: string;
  fileName: string;
  importType: string;
  loadedBy: string | null;
  loadDate: string | null;
  validLines: number;
  totalLines: number;
  status: string;
}

export interface SortInfo {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}
export interface Pageable {
  pageNumber: number;
  pageSize: number;
  sort: SortInfo;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface ImportResponse {
  content: ImportFile[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  sort: SortInfo;
  number: number;
  size: number;
  empty: boolean;
}
export interface ImportLinesResponse {
  content: ISelectedImportItem[];
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  numberOfElements: number;
  sort: SortInfo;
  number: number;
  size: number;
  empty: boolean;
}

export interface ISelectedImportItem {
  id: string;
  [key: string]: string | number | boolean;
  status: ImportStatusLine;
  validationMessage: string;
}

export enum ImportStatusLine {
  IN_PROGRESS = 'IN_PROGRESS',
  CONFLICT = 'CONFLICT',
  VALID = 'VALID',
  INVALID = 'INVALID',
}
export enum ImportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  RESTARTED = 'RESTARTED',
  FAILED = 'FAILED',
}
