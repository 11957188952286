import { YodaRestClient } from '@/services/YodaRestService/YodaRestService';
import { ApiResponse } from '@/types/api';
import {
  ManagementCompanyCreateRequestDto,
  ManagementCompanyFilterRequestDto,
  ManagementCompanyUpdateequestDto,
} from './dto/ManagementCompanyRequestDto';
import { ManagementCompanyResponseDto } from './dto/ManagementCompanyResponseDto';

export async function getOdpuManagementCompanies(
  filter: ManagementCompanyFilterRequestDto
): ApiResponse<ManagementCompanyResponseDto[]> {
  const url = `/rest/odpu-management-company/filter`;
  return YodaRestClient.post(url, filter);
}

export async function getOdpuManagementCompaniesCount(
  filter: ManagementCompanyFilterRequestDto
): ApiResponse<number> {
  const url = `/rest/odpu-management-company/filter/count`;
  return YodaRestClient.post(url, filter);
}

export async function createOdpuManagementCompany(
  company: ManagementCompanyCreateRequestDto
): ApiResponse<ManagementCompanyResponseDto> {
  const url = `/rest/odpu-management-company`;
  return YodaRestClient.post(url, company);
}

export async function updateOdpuManagementCompany(
  company: ManagementCompanyUpdateequestDto
): ApiResponse<ManagementCompanyResponseDto> {
  const url = `/rest/odpu-management-company`;
  return YodaRestClient.put(url, company);
}
