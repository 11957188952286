import React, { useEffect } from 'react';
import {
  Autocomplete,
  FormControl,
  styled,
  TextField as TextField_,
  createFilterOptions,
} from '@mui/material';

import { ArgumentSelect } from '../ArgumentSelectNew';
import { FilterArgEnum } from '../ArgumentSelectNew/types';
import { SelectFilterType } from './types';
import { Popper } from '../../fixUI/Popper';
import { SyntheticEvent } from 'react';
import { sortByAlphabetlabels } from '@/utils/heplers';

const agrumentTypes: FilterArgEnum[] = [
  FilterArgEnum.IN_LIST,
  FilterArgEnum.NOT_IN_LIST,
  FilterArgEnum.INSTALLED,
];

export const onlyInListArgumentType: FilterArgEnum[] = [FilterArgEnum.IN_LIST];

const TextField = styled(TextField_)({
  '&.MuiTextField-root .MuiInputLabel-root:not(.Mui-focused)': {
    display: 'inline-block',
    width: 'calc(100% - 18px)',
    overflow: 'auto',
    textOverflow: 'ellipsis',
  },
  '&.MuiTextField-root *::-webkit-scrollbar': {
    width: 0,
  },
  '& .MuiAutocomplete-input': {
    minWidth: 'unset!important',
  },
  '&.MuiTextField-root [data-shrink="true"] ~ .MuiInput-root::before': {
    borderBottom: '2px solid #000000',
  },
});

const isInstalledOpt = [
  { value: 'yes', label: 'Да' },
  { value: 'no', label: 'Нет' },
];

const FILTER_OPTIONS = createFilterOptions({
  matchFrom: 'any',
  limit: 150,
});

export const MultiSelect = ({
  options,
  label,
  onArgChange,
  onChange,
  value,
  argValue,
  argList = agrumentTypes,
  sx,
  onlyInListArgument,
  disabled = false,
  withoutBullAnswerReset,
  isOnlySingleValue = false,
  className,
  onInputChange = () => {},
}: SelectFilterType) => {
  const [isboolAnswer, setIsboolAnswer] = React.useState<boolean>(false);
  const argListOptions = onlyInListArgument ? onlyInListArgumentType : argList;

  const handleChange = (_event: SyntheticEvent<Element, Event>, value: unknown) => {
    if (value) {
      onChange(isboolAnswer ? [value] : value);
    } else {
      onChange([]);
    }
  };

  const stopPropagation = (e: any) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  const optionsVariant = isOnlySingleValue
    ? sortByAlphabetlabels(options, 'label')
    : isboolAnswer
    ? isInstalledOpt
    : sortByAlphabetlabels(options, 'label').sort((elem: any) => {
        return value?.find((item: any) => item?.value === elem?.value) ? -1 : 1;
      });

  const nonBooleanProps = {
    renderTags: (elements: unknown[]) => (
      <span style={{ paddingRight: 3 }}>{`выбрано ${elements?.length}`}</span>
    ),
  };

  const currentValue = isboolAnswer ? (value ? value[0] : null) : value;

  useEffect(() => {
    if (!withoutBullAnswerReset) {
      onChange([]);
    }
  }, [isboolAnswer]);

  return (
    <div
      style={{ flexShrink: 0 }}
      className={className}
    >
      <div className='parent'>
        {argValue !== undefined && (
          <ArgumentSelect
            onArgChange={onArgChange}
            argList={argListOptions}
            argValue={onlyInListArgument ? FilterArgEnum.IN_LIST : argValue}
            isBooleanHandler={setIsboolAnswer}
          />
        )}
        <FormControl
          className='child child_input'
          variant='standard'
        >
          <Autocomplete
            value={currentValue}
            filterOptions={FILTER_OPTIONS}
            sx={{ width: '100%', minWidth: '140px', ...sx }}
            includeInputInList
            onKeyDown={stopPropagation}
            onChange={handleChange}
            disabled={disabled}
            disableCloseOnSelect
            onInputChange={onInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='standard'
                label={label}
                placeholder={label}
              />
            )}
            renderOption={(props, option) => (
              <li
                {...props}
                key={option?.value || option?.label}
              >
                {option?.label ?? ''}
              </li>
            )}
            PopperComponent={Popper}
            multiple={isOnlySingleValue ? !isOnlySingleValue : !isboolAnswer}
            options={optionsVariant}
            openOnFocus
            getOptionLabel={(option) => option?.label ?? ''}
            isOptionEqualToValue={(option, value) => option.value === value?.value}
            {...(!isboolAnswer && { ...nonBooleanProps })}
          />
        </FormControl>
      </div>
    </div>
  );
};
