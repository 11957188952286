import { CableDescentResponse } from '@/services/CableDescentService/dto/CableDescentDtoResponse';
import { SimChangesResponse } from '@/services/GSMService/dto/GSMServiceResponseDto';
import { Phase } from '@/services/TransformerService/dto/TransformerResponseDto';

export enum TaskResultType {
  REPLACEMENT = 'replacement',
  TKO = 'tech-inspection',
  ODPU = 'odpu',
  HISTORY = 'history',
  PPO = 'ppo',
  EVENT = 'event',
}

export class ReplacementPage {
  currentPage!: number;
  countOnPage!: number;
  totalPages!: number;
  totalItems!: number;
  responseList!: ReplacementResponse[];
}

export interface IImage {
  id: string;
  typeList: string[] | null;
  fileName?: string;
  createdOn: Date;
  url?: string;
}

interface IDuplicateFileLink {
  taskId: string;
  taskNumber: number;
  fileId: string;
}
export interface IDuplicateFileResponseItem {
  duplicateFileLinkList: IDuplicateFileLink[];
  fileId: string;
}

type IDuplicateFileResponseList = IDuplicateFileResponseItem[];

export interface ResultImage {
  imageList: IImage[];
  duplicateFileResponseList?: IDuplicateFileResponseList;
}

export class HistoryResponse {
  eventAt!: number;
  fullName!: string;
  value!: string;
  oldValue!: string;
  attributeName!: string;
}
export class InstallResultResponse {
  replacementResponseList?: ReplacementResponseList[];
  transformerInfoResponse?: TransformerInfoResponse;
  simChanges?: SimChangesResponse[];
}

interface TransformerInfoBase {
  ktt?: string;
  prevType?: string;
}

export interface TransformerInfoRequest extends TransformerInfoBase {
  transformerRequestList?: TransformerRequest[];
  checkOn?: string;
  nextCheckOn?: string;
}

export interface TransformerInfoResponse extends TransformerInfoBase {
  transformerResponseList?: TransformerResponse[];
  checkOn?: Date;
  nextCheckOn?: Date;
}

interface ReplacementResponseBase extends Partial<ResultImage> {
  id: string;
  actStatus?: string;
  stampInstallStatus?: string;
  actTransferOn?: Date;
  actReturnOn?: Date;
  actCreateOn?: Date;
  actRejectionReason?: string;
  actAgentName?: string;
  actAgentPhone?: string;
  sendInToOn?: Date;
  actual?: boolean;
  status?: string;
  fullValidMessage?: string;
  lastValidMessage?: string;
  replacementState?: string;
  replacedOn?: Date;
  ksSmr?: Date;
  ksPnr?: Date;
  montageName?: string;
  mounted?: ReplacementMeterResponse;
  unmounted?: ReplacementMeterResponse;
  cableDescentResponse?: CableDescentResponse | null;
  isPickUpOldMeter?: boolean;
  isSignedTransferredToMesCalculation?: boolean;

  simChangeResponseList: SimChangesResponse[];

  installedPuNumber?: string;
  installedPuMac?: string;
  installedPuImei?: string;
  installedPuType?: string;
}

export interface ReplacementResponseList extends ReplacementResponseBase {
  balance?: number;
  automaticBreakerId?: string;
  smrLockerId?: string;
  factoryStampNumber?: string;
  existingMeasurementResponse?: MeasurementResponse;
  newMeasurementResponse?: MeasurementResponse;
  stampInstallResponse?: StampInstallResponse;
  factoryStamp?: string;
  imageIds?: string[];
}

export interface ReplacementResponse extends ReplacementResponseBase {
  transformerResponseList?: TransformerResponse[];
}

export interface ReplacementMeterResponse extends ReplacementMeterRequest {
  locationId?: string;
  puType?: string;
}

export interface ReplacementMeterRequest {
  type?: string;
  meterType?: string;
  number?: string;
  mac?: string;
  montagePlace?: string;
}

interface MeasurmentBase {
  tariff?: number;
  t1?: string;
  t2?: string;
  t3?: string;
}

export interface MeasurementResponse extends MeasurmentBase {
  id: string;
}

export type ReplacementMeasurementRequest = MeasurmentBase;

export class StampInstallResponse {
  id!: string;
  installNumber?: string;
  existingNumber?: string;
  stampInstallStatus?: string;
  factoryStamp?: string;
  stampNum?: string;
}

export class ReplacementRequest {
  replacementRequest?: ReplacementMainRequest;
  transformerInfoRequest?: TransformerInfoRequest;
}

export class ReplacementMainRequest {
  balance?: string;
  changeDate?: string;
  montageName?: string;
  mounted?: ReplacementMeterRequest;
  unmounted?: ReplacementMeterRequest;
  installMeasurement?: ReplacementMeasurementRequest;
  existingMeasurement?: ReplacementMeasurementRequest;
}

interface TransformerBase {
  id: string;
  type?: string;
  prevSerialNumber?: string;
  serialNumber?: string;
  stampNumber?: string;
}

export interface TransformerRequest extends TransformerBase {
  smrDate?: string;
  prevFactoryYear?: string;
}

export interface TransformerResponse extends TransformerBase, Partial<ResultImage> {
  prevFactoryYear?: number;
  phase: string;
}

export interface TechInspectionResponse extends Partial<ResultImage> {
  id: string;
  isActual: boolean;
  eventOn: Date | null;
  executorName?: string;
  comment?: string;
  dateOneCall?: Date;
  dateTwoCall?: Date;
  dateThreeCall?: Date;
  meterNum?: string;
  meterState?: string;
  puType?: string;
  stampNum?: string;
  stdoBasicTask?: string;
  t1?: number;
  t2?: number;
  t3?: number;
  tkoStatusResponse?: { id: string; description: string };
  managementCompanyName: string;
  managementCompanyRefuseAdmission: string;
}

interface TaskResponseBase {
  id: string;
  number?: number;
  installationPlace?: string;
  installPlace?: string;
}

export interface VruTaskResponse extends TaskResponseBase {
  inputResponseList?: VruInputResponse[];
  odpuScheduledDateOn?: Date;
}

export interface PpoOdpuVruResponse extends Partial<ResultImage> {
  id: string;
  tpNumber?: number;
  hasCableConnection?: boolean;
  hasAvrBeforeProtection?: boolean;
  powerScheme?: string;
  ppoState?: string;
  comment?: string;
}

export interface PpoVruTaskResponse extends TaskResponseBase {
  inputResponseList?: PpoVruInputResponse[];
  ppoOdpuVruResponse: PpoOdpuVruResponse;
}

export interface VruInputResponse extends Partial<ResultImage> {
  id: string;
  title?: string;
  note?: string;
  replacementResponse?: ReplacementResponseList[];
  lockerCount?: number;
  inputTypeId?: number;
  ksAssignedOn?: number;
  ksPnrAssignedOn?: number;
  nominalTT?: string;
  transferredInDevelopmentCenterIsuOn?: number;
  claimType?: string;
  claimNote?: string;
}

export class DeliveryPointTransformatorResponse {
  id?: string;
  typeTT?: string; // catalog
  ktt?: string;
  ttNumberA?: number;
  ttNumberB?: number;
  ttNumberC?: number;
}

export interface TaskComment {
  message: string;
  eventAt: Date;
  author: string;
  isDeletable?: boolean;
}

export interface SubscriberResponse {
  address: string;
  apartmant: string;
  buildingCategory: string;
  contractNum: string;
  description: string;
  fio: string;
  house: string;
  id: string;
  isMasterCreated: false;
  locality: string;
  note: string;
  productionDepartment: { id: string; name: string };
  puNumber: string;
  puType: string;
  region: string;
  street: string;
  tp: string;
  ttNumber: string;
  type: string;
  voltageClass: string;
  modifiedAddress: string | null;
  modifiedApartmant: string | null;
  modifiedAreaName: string | null;
  modifiedStreet: string | null;
  modifiedBuildingCategory: string | null;
  modifiedHouse: string | null;
  modifiedLocality: string | null;
  modifiedRegion: string | null;
}

export interface DeliveryPointResponse extends Partial<ResultImage> {
  exPuPlace?: string; // catalog?
  feeder?: { id: string; num: string };
  latitudeY?: number;
  lineType?: string; // catalog
  longitudeX?: number;
  newPuType?: string; // catalog
  pillar?: { id: string; number: string; latitude: number; longitude: number };
  ppoStolbiTransformator?: DeliveryPointTransformatorResponse;
  puNumber?: string;
  puType?: string; // catalog
  connectTechnology?: string; // catalog
  isReplacementInput?: boolean;
  sipLength?: string;
  smrTaskId?: string;
  id: string;
  isLockerNeeded?: boolean | null;
  isCreatedMaster?: boolean | null;
  isIkkExists?: boolean | null;
  isNeededSeparateInput?: boolean | null;
  meterChangeIdList: string[] | null;
  taskTransformerVoltageIdList: string[] | null;
  tkoIdList: string[] | null;
  subscriber: SubscriberResponse;
  isChangeNeeded: boolean | null;
  ppoComment?: string | null;
}
export interface DeliveryPointRequest {
  exPuPlace?: string; // catalog?
  feeder?: { id: string; num: string };
  latitudeY?: number;
  lineType?: string; // catalog
  longitudeX?: number;
  newPuType?: string; // catalog
  pillar?: {
    id: string;
    number: string;
    latitude: number;
    longitude: number;
    type: { id: string };
  };
  ppoStolbiTransformator?: DeliveryPointTransformatorResponse;
  puNumber?: string;
  puType?: string; // catalog
  connectTechnology?: string; // catalog
  isReplacementInput?: boolean;
  sipLength?: string;
  smrTaskId?: string;
  id: string;
  isLockerNeeded?: boolean | null;
  isCreatedMaster?: boolean | null;
  isIkkExists?: boolean | null;
  isNeededSeparateInput?: boolean | null;
  meterChangeIdList: string[] | null;
  taskTransformerVoltageIdList: string[] | null;
  tkoIdList: string[] | null;
  subscriber: SubscriberResponse;
  isChangeNeeded: boolean | null;
  ppoComment?: string | null;
}

export interface PillarResponse extends Partial<ResultImage> {
  id: string;
  typeId: string;
  number: number;
  latitude: number;
  longitude: number;
}

type ObjectKey<Obj> = keyof Obj;
export type DeliveryPointResponseKeys = ObjectKey<DeliveryPointResponse>;

export class PpoStolbiTransformatorResponse {
  id?: string;
  typeTT?: string;
  ktt?: string;
  ttNumberA?: number;
  ttNumberB?: number;
  ttNumberC?: number;
}

export interface TransStationFeederMeterResponse extends ResultImage {
  exPuPlace?: string;
  id?: string;
  number?: string;
  ppoStolbiTransformator?: PpoStolbiTransformatorResponse;
  puType?: string;
}

export interface TransStationFeederResponse extends ResultImage {
  id?: string;
  num?: string;
  tpFeederType?: string;
  tpMeter?: TransStationFeederMeterResponse;
}

export interface TransStationInputResponse extends ResultImage {
  capascity?: number;
  fcFeeder?: number;
  id?: string;
  name?: string;
  tpFeeder?: TransStationFeederResponse;
}

export interface TransStationResponse extends ResultImage {
  id: string;
  inputs?: TransStationInputResponse[];
  latitudeY: number;
  longitudeX: number;
  dispatcherName: string;
  tpFeeders?: TransStationFeederResponse[];
  tpTypeEnumId?: string;
  uspdInfo?: UspdInfoResponse;
  resId: string;
  contractId: string;
}

export interface UspdInfoResponse {
  mobileOperatorId?: string;
  signalLevelId?: string;
  installPlace?: string;
}

// export class PpoResponse {
//   vruResponseList?: PpoVruTaskResponse[];
// }
export interface PpoResponse {
  houseInfo: PpoHouseResponse;
  ppoResponse: PpoVruTaskResponse[];
}

export interface PpoHouseResponse extends ResultImage {
  id: string;
  flatCount?: number;
  floorCount?: number;
  entranceCount?: number;
  elevatorCount?: number;
  hasElectricStove?: boolean;
}

export interface PpoVruInputResponse extends ResultImage {
  id: string;
  absenceExPu: boolean;
  locationNewMeterId?: string;
  installPlaceTTId?: string | null;
  ppoTransformerResponseList?: PpoTransformerResponseList[];
  extraConnectionResponseList?: ExtraConnectionResponse[];
  meterResponse?: MeterResponse;
  ppoCableResponseList?: PpoCableResponse[];
  revisionOn?: number | null;
  revisionName?: null;
  inputNumber?: string;
  protectionRateId?: string;
  measurementCableLength?: number;
  ppoTechDecisionId?: string;
  nominalTT?: string;
  inputTypeId?: null;
  voltageAId?: null;
  voltageBId?: null;
  voltageCId?: null;
  protectionTypeId?: null;
  lockerResponse?: LockerResponse;
  ppoInputExpendableList: PpoInputExpendabResponse[];
}

export interface ExtraConnection {
  power?: number;
  transferPossible?: boolean;
  transferAgreed?: boolean;
  connectionPlace?: string;
  ppoCableResponseList?: PpoCableResponse[];
}

export interface ExtraConnectionResponse extends ExtraConnection {
  id: string;
  ppoCableResponseList?: PpoCableResponse[];
}
export interface ExtraConnectionRequest extends ExtraConnection {
  id: string;
  ppoCableRequestList?: PpoCableResponse[] | PpoCableNewResponse[];
}
export interface ExtraConnectionNewRequest extends ExtraConnection {
  ppoCableRequestList?: PpoCableResponse[] | PpoCableNewResponse[];
}

export class LockerResponse {
  id!: string;
  puCount?: number;
}
export interface PpoCableNewResponse {
  id?: string;
  sectionId?: number;
  type?: string | null;
  brand?: string | null;
  linesNumber?: string | null;
  cableCompositionId?: number;
}

export interface PpoCableResponse extends PpoCableNewResponse {
  id: string;
}

export interface PpoInputExpendabResponse {
  id: string;
  expendableId?: string;
  count?: number | null;
  ppoInputId?: string | null;
}

export interface PpoTransformerResponseList extends ResultImage {
  id: string;
  affiliationStamp?: string;
  number?: string;
  stampNumber?: number;
  phaseName?: string;
  transformerInfo?: PpoTransformerResponse;
}
export class PpoTransformerResponse {
  id!: string;
  existsLocationId?: string;
  newLocationId?: string;
  ttType?: string;
  manufactureYear?: number;
  currentRate?: any;
  kttEnum?: string;
  ppoCurrentRateRange?: string;
}

// export class MeterResponseList extends ReplacementMeterResponse {
//   manufactureYear?: number;
//   verificationYear?: number;
// }
export interface MeterResponse extends ResultImage {
  id: string;
  type?: string;
  affiliationStamp?: string;
  number?: string;
  stampNumber?: string;
  locationId?: string;
  manufactureYear?: number;
  verificationYear?: number;
}

export interface ChronologyResponse {
  date?: Date;
  time?: string;
  title?: string;
  comment?: string;
}

export interface PresentPu {
  aiNumber?: string;
  balance?: string;
  editionYear?: number;
  kkiStamp?: string;
  measurement?: MeasurmentBase;
  nextVerificationDate?: Date;
  number?: string;
  stampNumber?: string;
  type?: string;
  verificationDate?: Date;
}
export interface PresentTransformer {
  accuracy?: string;
  ktt?: string | { n1?: string; n2?: string };
  checkDate?: Date;
  nextCheckDate?: Date;
  phaseList: Phase[];
  type?: string;
}
export interface PresentTransformerVoltage {
  accuracy?: string;
  ktn?: string | { n1?: string; n2?: string };
  type?: string;
  phaseList: Phase[];
  nextCheckDate?: Date;
  checkDate?: Date;
}

export interface PresentEquipments {
  presentPu?: PresentPu | null;
  presentTransformer?: PresentTransformer | null;
  presentTransformerVoltage?: PresentTransformerVoltage | null;
}

export interface ExistingEquipmentData {
  id: string;
  imageList: IImage[];
  presentEquipments?: PresentEquipments;
  taskId?: string;
  vruInputId?: string;
}
export interface UpDateEquipmentData {
  presentEquipments?: Partial<PresentEquipments>;
}
export interface SubscriberUsage {
  pillarNum: string;
  resName: string;
  transStationName: string;
}
