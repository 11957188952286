import { addSuccessActionLog, fetchCatch, syncErrorCatch } from '@/hooks/ActionLogHook';
import errorAudio from '../assets/audio/error.mp3';
import infoAudio from '../assets/audio/info.mp3';
import attentionAudio from '../assets/audio/attention.mp3';
import { catalogsLookUp } from '@/app/auth/AuthProvider';
import moment from 'moment';

export const sortByAlthabit = (data: any = [], addToEnd: string) => {
  const total = data.find((item: any) => item.contractorName === addToEnd);
  const sortedData = [...data]
    .sort((a, b) => a.contractorName.localeCompare(b.contractorName))
    .filter((item) => item.contractorName !== addToEnd);
  if (total) {
    sortedData.push(total);
    return sortedData;
  }
  return sortedData;
};

export const downLoadExcel = (callback: any, data: any, errorHandler: any, handleLoad: any) => {
  if (data) {
    handleLoad(true);
    callback(data)
      .then((response: any) => {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx'; // Set the filename

        // Trigger the download
        a.click();

        // Clean up by revoking the Blob URL
        window.URL.revokeObjectURL(url);
        handleLoad(false);
      })
      .catch((error: any) => {
        handleLoad(false);
        errorHandler('Ошибка сохранения данных', error);
      });
  }
};

type AudioKeys = 'errorAudio' | 'info' | 'attention';

const playAudio = (audioKey: string) => {
  new Audio(audioKey).play();
};

export const playAudioByKey = (audioKey: AudioKeys) => {
  switch (audioKey) {
    case 'errorAudio':
      return playAudio(errorAudio);

    case 'info':
      return playAudio(infoAudio);

    case 'attention':
      return playAudio(attentionAudio);

    default:
      break;
  }
};

export const copyToClipboard = (
  dataToCopy?: string,
  successText?: string,
  emptyDataText?: string
) => {
  if (dataToCopy) {
    navigator.clipboard
      .writeText(dataToCopy)
      .then(() => addSuccessActionLog(successText ?? 'Скопировано'))
      .catch((err) => console.log('Ошибка при копировании', err));
  } else {
    syncErrorCatch(emptyDataText ?? 'Ошибка копирования');
  }
};

export const sortByAlphabetlabels = (arr: any, sortBy: string) => {
  return [...arr].sort((a, b) => a[sortBy]?.localeCompare(b[sortBy]));
};

export const openUrlInNewTab = (url: string) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const responseToMap = (response: any) => {
  const map: Map<string, string> = new Map();
  if (response) {
    Object.keys(response).forEach((t) => {
      map.set(t, response[t]);
    });
  }
  return map;
};

export const responseArrToMap = (response: { id: string; title: string }[]) => {
  const map: Map<string, string> = new Map();
  response.forEach((t: any) => {
    map.set(t.id, t.title);
  });
  return map;
};

type CatalogItem = {
  data: Map<string, string> | { id: string; title: string }[];
  expiration: number;
};

export function setItemWithExpiration(
  catalogName: string,
  value: Map<string, string> | { id: string; title: string }[],
  expirationMinutes: number
): void {
  const now = new Date();
  const lsCatalog = JSON.parse(localStorage.getItem(catalogsLookUp) || '{}') as Record<
    string,
    CatalogItem
  >;

  lsCatalog[catalogName] = {
    data: value,
    expiration: now.getTime() + expirationMinutes * 60 * 1000,
  };

  localStorage.setItem(catalogsLookUp, JSON.stringify(lsCatalog));
}

export function getItemWithExpiration(key: string, catalogName: string): CatalogItem | null {
  const item = JSON.parse(localStorage.getItem(key) || '{}') as Record<string, CatalogItem>;
  if (!item[catalogName]) {
    return null;
  }
  const now = new Date();
  if (now.getTime() > item[catalogName].expiration) {
    delete item[catalogName];
    localStorage.setItem(key, JSON.stringify(item));
    return null;
  }
  return item[catalogName];
}

export const toFormateDate = (date?: Date | null) => {
  return (date && moment(date).format('DD.MM.yyyy')) || '';
};

export const mapBoundsToGeoBounds = (mapBounds: any) => {
  return (
    mapBounds && {
      minX: mapBounds[0][0],
      minY: mapBounds[0][1],
      maxX: mapBounds[1][0],
      maxY: mapBounds[1][1],
    }
  );
};

export const formatPhoneNumberMask = (value: string) => {
  if (value) {
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length > 0) {
      const formattedValue: string =
        '+7 ' +
        numericValue.slice(1, 4) +
        ' ' +
        numericValue.slice(4, 7) +
        ' ' +
        numericValue.slice(7, 9) +
        ' ' +
        numericValue.slice(9, 11);

      return formattedValue.trim();
    }
  }

  return '';
};

export const appendArrayParam = (
  params: URLSearchParams,
  key: string,
  values: string[] | undefined
) => {
  values?.forEach((value) => params.append(key, value));
};

export const decodeJWT = (jwt?: string | null) => {
  if (!jwt) return;
  const base64Url = jwt.split('.')[1];
  if (!base64Url) return;
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const REQUEST_ABORT_ERROR_NAME = 'CanceledError';
