import { Box, Button } from '@mui/material';

interface DropzoneActionsProps {
  onCancel: () => void;
  onSave: () => void;
  saveDisabled: boolean;
}

export const DropzoneActions = ({ onCancel, onSave, saveDisabled }: DropzoneActionsProps) => (
  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center', gap: 10 }}>
    <Button
      variant='contained'
      onClick={onSave}
      disabled={saveDisabled}
    >
      сохранить
    </Button>
    <Button
      onClick={onCancel}
      sx={{ mr: 1 }}
    >
      отмена
    </Button>
  </Box>
);
